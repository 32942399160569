import React, { useState } from 'react'
import { Flex, TextField } from '@happyfoxinc/web-components'
import styles from './SurveyConfiguration.module.css'
import ChevronRight from 'Src/assetsv3/icons/white-chevron-right.svg'
import ListIcon from 'Src/assetsv3/logos/surveys-logo.svg'


const SurveyConfiguration = ({ surveyData, setSurveyData, isLoading }) => {
  const [activeTab, setActiveTab] = useState('message')

  const handleInputChange = (field, value) => {
    setSurveyData(prev => ({
      ...prev,
      [field]: value,
      editable: {
        ...prev.editable
      }
    }));
  };

  if (isLoading) {
    return <div className={styles.loadingState}>Loading survey data...</div>;
  }

  const renderActionTabContent = () => (
    <div className={styles.tabContent}>
      <div className={styles.formGroup}>
        <label>Survey Name *</label>
        <TextField.Root
          size='1'
          radius='small'
          placeholder='Enter survey name'
          value={surveyData.surveyName}
          onChange={(e) => handleInputChange('surveyName', e.target.value)}
          disabled={surveyData.editable?.surveyName === false}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Description *</label>
        <textarea
          value={surveyData.description}
          onChange={(e) => handleInputChange('description', e.target.value)}
          placeholder="Enter survey description"
          className={styles.textarea}
          disabled={surveyData.editable?.description === false}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Thank You Message *</label>
        <textarea
          value={surveyData.thankYouMessage}
          onChange={(e) => handleInputChange('thankYouMessage', e.target.value)}
          placeholder="Enter thank you message"
          className={styles.textarea}
          disabled={surveyData.editable?.thankYouMessage === false}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Survey Notification Message *</label>
        <textarea
          value={surveyData.notificationMessage}
          onChange={(e) => handleInputChange('notificationMessage', e.target.value)}
          placeholder="Enter notification message"
          className={styles.textarea}
          disabled={surveyData.editable?.notificationMessage === false}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <h2 className={styles.title}>Survey Configuration</h2>
        <div className={styles.buttonGroup}>
          <button 
            className={`${styles.configButton} ${activeTab === 'message' ? styles.activeButton : styles.inactiveButton}`}
            onClick={() => setActiveTab('message')}
          >
            <div className={styles.buttonContent}>
              <div className={styles.buttonText}>
                <h3>Survey Settings and Notifications</h3>
                <p>Customize survey details, manage notifications, and craft a personalized thank-you message.</p>
              </div>
              {(activeTab === 'message') && (
                <div className={styles.selectedArrowContainer}>
                  <ChevronRight />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
      
      <div className={styles.rightSection}>
        <Flex>
          <ListIcon className={styles.propertiesIcon}/>
          <h3 className={styles.propertiesTitle}>
            {activeTab === 'message' ? 'Details & Messaging' : ''}
          </h3>
        </Flex>
        {renderActionTabContent()}
      </div>
    </div>
  )
}

export default SurveyConfiguration
