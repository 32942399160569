import { Box } from '@happyfoxinc/web-components'
import { useLocation } from 'react-router-dom'

import WorkspaceIcon from 'Icons/workspace-inverted.svg'

import ReactSelect from 'Src/componentsv3/ReactSelect'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'
import { useWorkspaceVisibility } from 'Src/utilsV3/hooks/useWorkspaceVisibility'

const useWorkspaceStyle = () => {
  const location = useLocation()

  // Add routes that need the light background style
  const lightBackgroundPaths = ['/v3/answers']

  return lightBackgroundPaths.some((path) => location.pathname.startsWith(path))
}

const WorkspaceSelect = () => {
  const { workspaces, currentWorkspaceId, setCurrentWorkspaceId } = useWorkspace()
  const isVisible = useWorkspaceVisibility()
  const needsLightStyle = useWorkspaceStyle()

  if (!isVisible) return null

  const handleWorkspaceChange = (selectedOption) => {
    if (selectedOption) {
      setCurrentWorkspaceId(selectedOption.value)
    }
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: needsLightStyle ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.3)',
      backdropFilter: 'blur(6px)',
      border: needsLightStyle ? '1px solid rgb(218, 218, 218)' : 'none',
      height: '30px',
      minHeight: '30px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: needsLightStyle ? 'rgba(255, 255, 255, 0.6)' : 'rgba(255, 255, 255, 0.4)'
      }
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0 8px 0 0'
    }),
    singleValue: (base) => ({
      ...base,
      marginLeft: '22px',
      maxWidth: 'calc(100% - 22px)'
    })
  }

  const options = workspaces.map((workspace) => ({
    value: workspace.id,
    label: workspace.name
  }))

  const currentValue = options.find((option) => option.value === currentWorkspaceId)

  return (
    <Box width='150px'>
      <ReactSelect
        styles={customStyles}
        isSearchable={false}
        icon={WorkspaceIcon}
        options={options}
        value={currentValue}
        onChange={handleWorkspaceChange}
      />
    </Box>
  )
}

export default WorkspaceSelect
