import baseApi, { TAGS } from './baseApi'

export const companyPolicyModuleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPolicies: builder.query({
      query: ({ limit, offset } = {}) => ({
        url: 'v1/company-policies',
        params: { limit, offset }
      }),
      providesTags: [TAGS.COMPANY_POLICIES]
    }),

    addPolicyType: builder.mutation({
      query: (data) => ({
        url: 'v1/company-policies',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.COMPANY_POLICIES]
    }),

    savePolicies: builder.mutation({
      query: (data) => ({
        url: 'v1/company-policies/bulk_update',
        method: 'POST',
        body: {
          policies: data
        }
      }),
      invalidatesTags: [TAGS.COMPANY_POLICIES]
    }),

    updatePolicy: builder.mutation({
      query: ({ policyId, ...data }) => ({
        url: `v1/company-policies/${policyId}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.COMPANY_POLICIES]
    }),

    deletePolicy: builder.mutation({
      query: (policyId) => ({
        url: `v1/company-policies/${policyId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.COMPANY_POLICIES]
    }),

    uploadFiles: builder.mutation({
      query: (files) => {
        return {
          url: 'v1/company-policies/files',
          method: 'POST',
          body: files
        }
      },
      invalidatesTags: [TAGS.COMPANY_POLICIES]
    })
  })
})

export const {
  useGetPoliciesQuery,
  useAddPolicyTypeMutation,
  useSavePoliciesMutation,
  useUpdatePolicyMutation,
  useDeletePolicyMutation,
  useUploadFilesMutation
} = companyPolicyModuleApi
