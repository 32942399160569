import React, { Fragment, useState, useEffect } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import cx from 'classnames'

import styles from './ConfigModule.module.css'
import { QUESTION_TYPES } from './constants'
import { QuestionBlock, SortableQuestionBlock } from './components/QuestionBlock'
import SurveyConfiguration from './SurveyConfiguration'

import StartFlagIcon from '../../../../assetsv3/icons/start-flag.svg'
import PencilIcon from '../../../../assets/icons/pencil.svg'
import { useGetQuestionsQuery } from 'Src/servicesV3/surveyApi';

const ConfigModule = ({ 
  currentScreen, 
  surveyData, 
  setSurveyData, 
  surveyId, 
  handleTransformedQuestions, 
  isEdit,
  isLoading: isSurveyLoading,
}) => {
  const [questions, setQuestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const { data: questionData, isLoading: isQuestionsLoading } = useGetQuestionsQuery(surveyId, { 
    skip: !surveyId 
  });

  useEffect(() => {
    if (questionData?.questions && isEdit) {
      const transformedQuestions = questionData.questions.map(q => ({
        id: q.id,
        text: q.text,
        type: q.question_type,
        options: q.choices || [],
        order: q.order,
        isRequired: q.is_required ?? true,
        originalData: { ...q },
        isModified: false,
        isDeleted: false
      }));
      setQuestions(transformedQuestions);
    }
  }, [questionData, isEdit]);

  useEffect(() => {
    if (questions.length > 0) {
      const transformedQuestions = {
        newQuestions: questions
          .filter(q => !q.originalData)
          .map(q => ({
            text: q.text,
            question_type: q.type,
            choices: q.type !== 'open_ended' ? q.options?.filter(opt => opt?.trim() !== '') : [],
            order: q.order,
            is_required: q.isRequired
          })),
        modifiedQuestions: questions
          .filter(q => q.originalData && (q.isModified || q.order !== q.originalData.order) && !q.isDeleted)
          .map(q => ({
            id: q.id,
            text: q.text,
            question_type: q.type,
            choices: q.options,
            order: q.order,
            is_required: q.isRequired
          })),
        deletedQuestions: questions
          .filter(q => q.originalData && q.isDeleted)
          .map(q => q.id)
      };
      handleTransformedQuestions(transformedQuestions);
    }
  }, [questions]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = questions.findIndex(q => q.id === active.id);
      const newIndex = questions.findIndex(q => q.id === over.id);

      const newQuestionsArray = [...questions];
      const [movedItem] = newQuestionsArray.splice(oldIndex, 1);
      newQuestionsArray.splice(newIndex, 0, movedItem);

      const reorderedQuestions = newQuestionsArray.map((q, index) => ({
        ...q,
        order: index + 1,
        isModified: q.originalData ? q.isModified || (index + 1) !== q.originalData.order : false
      }));

      setQuestions(reorderedQuestions);
    }
    setActiveId(null);
  };

  const handleAddQuestion = (questionType) => {
    const newQuestion = {
      id: `new-${Date.now()}`, // unique ID
      text: '',
      type: questionType,
      options: questionType !== 'open_ended' ? ['', '', ''] : undefined,
      order: questions.length + 1,
      isRequired: true,
      isModified: false,
      isDeleted: false
    };

    setQuestions(prev => [...prev, newQuestion]);
    setShowDropdown(false);
  };

  const handleQuestionChange = (index, updatedQuestion) => {
    setQuestions(prev => {
      const newQuestionsArray = [...prev];
      const originalQuestion = newQuestionsArray[index];
      const hasChanged = originalQuestion.originalData && (
        originalQuestion.originalData.text !== updatedQuestion.text ||
        originalQuestion.originalData.type !== updatedQuestion.type ||
        JSON.stringify(originalQuestion.originalData.options) !== JSON.stringify(updatedQuestion.options)
      );

      newQuestionsArray[index] = {
        ...updatedQuestion,
        isModified: hasChanged,
        isDeleted: originalQuestion.isDeleted
      };

      return newQuestionsArray;
    });
  };

  const removeQuestion = (indexToRemove) => {
    setQuestions(prev => {
      const questionToRemove = prev[indexToRemove];
      if (questionToRemove.originalData) {
        return prev.map((q, index) => 
          index === indexToRemove 
            ? { ...q, isDeleted: true }
            : q
        );
      } else {
        const updatedQuestions = prev.filter((_, index) => index !== indexToRemove);
        return updatedQuestions.map((q, idx) => ({
          ...q,
          order: idx + 1
        }));
      }
    });
  };

  const renderDropdown = () => (
    <div className={styles.stepDropdown}>
      {QUESTION_TYPES.map(type => (
        <button
          key={type.key}
          className={styles.dropdownItem}
          onClick={() => handleAddQuestion(type.key)}
        >
          <div className={styles.dropdownItemContent}>
            <span className={styles.dropdownItemText}>{type.label}</span>
          </div>
          <span className={styles.dropdownItemArrow}>›</span>
        </button>
      ))}
    </div>
  );

  return (
    <div className={styles.configurationSection}>
      {currentScreen === 0 ? (
        <SurveyConfiguration 
          surveyData={surveyData}
          setSurveyData={setSurveyData}
          isLoading={isSurveyLoading}
        />
      ) : (
        <div className={styles.questionsContainer}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>{surveyData.surveyName}</h2>
            <PencilIcon className={styles.pencilIcon} />
          </div>
          <div className={styles.workflowContainer}>
            <div className={styles.startIconWrapper}>
              <StartFlagIcon />
            </div>
            {isQuestionsLoading ? (
              <div>Loading questions...</div>
            ) : (
              <>
                {questions.length > 0 && <div className={cx(styles.stepLine, styles.stepLineTop)} />}
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={questions.filter(q => !q.isDeleted).map(q => q.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {questions
                      .filter(q => !q.isDeleted)
                      .map((question, index) => (
                        <Fragment key={question.id}>
                          <SortableQuestionBlock
                            id={question.id}
                            questionNumber={index + 1}
                            question={question}
                            onChange={(updated) => handleQuestionChange(index, updated)}
                            onRemove={() => removeQuestion(questions.findIndex(q => q.id === question.id))}
                            isDragging={activeId === question.id}
                          />
                          {index < questions.filter(q => !q.isDeleted).length - 1 && (
                            <div className={cx(styles.stepLine, styles.stepLineBetween)} />
                          )}
                        </Fragment>
                      ))}
                  </SortableContext>
                  <DragOverlay>
                    {activeId ? (
                      <QuestionBlock
                        question={questions.find(q => q.id === activeId)}
                        questionNumber={questions.findIndex(q => q.id === activeId) + 1}
                        className={styles.dragOverlay}
                      />
                    ) : null}
                  </DragOverlay>
                </DndContext>
                <div className={styles.addStepContainer}>
                  <button 
                    className={styles.addStepWrapper} 
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <span className={styles.addStepButton}>+</span>
                    Add Question
                  </button>
                  {showDropdown && renderDropdown()}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigModule;
