import { baseApi, TAGS } from './baseApi'

export const softwareAccessModuleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getModuleMetaInfo: builder.query({
      query: (moduleName) => `v1/modules/${moduleName}/meta-info`,
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.PROVISION_STEPS]
    }),

    getSoftwareAccess: builder.query({
      query: () => 'v1/software-access',
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.APP_PROVIDER]
    }),

    getSoftwares: builder.query({
      query: (provider) => `v1/apps/${provider}/softwares`,
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.SOFTWARES]
    }),

    getProvisionSteps: builder.query({
      query: () => 'v1/software-access/provision-steps',
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.PROVISION_STEPS]
    }),

    getSendInstructionStep: builder.query({
      query: () => 'v1/software-access/provision-steps/send_instruction',
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.STEP_DETAILS]
    }),

    getRequestApprovalStep: builder.query({
      query: () => 'v1/software-access/provision-steps/request_approval',
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.STEP_DETAILS]
    }),

    getCreateTicketStep: builder.query({
      query: () => 'v1/software-access/provision-steps/create_ticket',
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.STEP_DETAILS]
    }),

    createSoftwareAccess: builder.mutation({
      query: (data) => ({
        url: 'v1/software-access',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [
        TAGS.SOFTWARE_ACCESS_MODULE.APP_PROVIDER,
        TAGS.SOFTWARE_ACCESS_MODULE.SOFTWARES,
        TAGS.SOFTWARE_ACCESS_MODULE.ACCESS_CONTROL
      ]
    })
  })
})

export const {
  useGetModuleMetaInfoQuery,
  useGetSoftwareAccessQuery,
  useGetSoftwaresQuery,
  useGetProvisionStepsQuery,
  useGetSendInstructionStepQuery,
  useGetRequestApprovalStepQuery,
  useGetCreateTicketStepQuery,
  useCreateSoftwareAccessMutation
} = softwareAccessModuleApi
