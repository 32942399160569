
import { baseApi, TAGS } from './baseApi'

// Campaigns APIs

export const campaignApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query({
      query: () => 'v1/campaigns',
      providesTags: [TAGS.CAMPAIGNS.LIST]
    }),

    getCampaign: builder.query({
      query: (id) => `v1/campaigns/${id}`,
      providesTags: (result, error, id) => [{ type: TAGS.CAMPAIGNS.DETAILS, id }]
    }),

    createCampaign: builder.mutation({
      query: (data) => ({
        url: 'v1/campaigns',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.CAMPAIGNS.LIST]
    }),

    updateCampaign: builder.mutation({
      query: ({ id, data }) => ({
        url: `v1/campaigns/${id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [
        TAGS.CAMPAIGNS.LIST,
        { type: TAGS.CAMPAIGNS.DETAILS, id }
      ]
    }),

    deleteCampaign: builder.mutation({
      query: (id) => ({
        url: `v1/campaigns/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.CAMPAIGNS.LIST]
    })
  })
})

export const {
  useGetCampaignsQuery,
  useGetCampaignQuery,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  useDeleteCampaignMutation
} = campaignApi

// Survey Reports APIs

export const reportsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSurveyReports: builder.query({
      query: ({survey_id, ...params}) => ({
        url: `v1/surveys/${survey_id}/responses`,
        method: 'GET',
        params
      })
    }),

    exportSurveyReports: builder.mutation({
      query: ({survey_id, ...params}) => ({
        url: `v1/surveys/${survey_id}/responses/export`,
        method: 'GET',
        params
      })
    }),

    exportQuestionResponses: builder.mutation({
      query: ({survey_id, question_id, ...params}) => ({
        url: `v1/surveys/${survey_id}/questions/${question_id}/export`,
        method: 'GET',
        params
      })
    })
  })
})

export const {
  useGetSurveyReportsQuery,
  useExportSurveyReportsMutation,
  useExportQuestionResponsesMutation
} = reportsApi