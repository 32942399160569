
import { Button, Table, TableOptions, TableOption } from '@happyfoxinc/web-components'
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg'
import styles from './SurveyManagementModule.module.css'

const COLUMN_HEADERS = [
  { key: 'title', label: 'Title' },
  { key: 'description', label: 'Description' },
  { key: 'created_at', label: 'Created' },
  { key: 'updated_at', label: 'Last Update' }
];

const SurveysContent = ({ 
  surveys,
  isSurveysLoading,
  handleStartSetup,
  handleRowClick,
  handleDeleteSurvey,
  activeMenu,
  setActiveMenu
}) => {
  const toggleMenu = (surveyId, event) => {
    event.stopPropagation();
    setActiveMenu(activeMenu === surveyId ? null : surveyId);
  };

  const renderCell = (survey, header) => {
    if (header) {
      return header.key === 'created_at' || header.key === 'updated_at'
        ? new Date(survey[header.key]).toLocaleDateString()
        : survey[header.key];
    }
    return (
      <div className={styles.menuContainer}>
        <button 
          className={styles.menuButton} 
          onClick={(e) => toggleMenu(survey.id, e)}
        >
          ⋮
        </button>
        {activeMenu === survey.id && (
          <div className={styles.menuDropdown}>
            <button 
              className={styles.menuItem}
              onClick={(e) => handleDeleteSurvey(e, survey.id)}
            >
              Remove
            </button>
          </div>
        )}
      </div>
    );
  };

  if (isSurveysLoading) {
    return <div className={styles.emptyState}>Loading...</div>
  }

  if (!surveys?.surveys?.length) {
    return (
      <div className={styles.ConfigurationContainer}>
        <h2 className={styles.NoConfigTitle}>No Surveys created yet</h2>
        <p className={styles.NoConfigDescription}>
          To get the survey module up and running, set up your survey questions and customize response options.
        </p>
        <Button className={styles.ButtonContainter} onClick={handleStartSetup}>
          Create Survey
          <span className={styles.ArrowContainer}>
            <ArrowRightIcon height='1em' width='1em' />
          </span>
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <h2 className={styles.tableTitle}>Survey List</h2>
        <button className={styles.addButton} onClick={handleStartSetup}>
          Create Survey
          <span>+</span>
        </button>
      </div>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            {COLUMN_HEADERS.map(header => (
              <Table.ColumnHeaderCell key={header.key}>
                {header.label}
              </Table.ColumnHeaderCell>
            ))}
            <Table.ColumnHeaderCell isOption />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {surveys.surveys.map((survey) => (
            <Table.Row 
              key={survey.id}
              clickable
            >
              {COLUMN_HEADERS.map(header => (
                <Table.Cell key={`${survey.id}-${header.key}`} onClick={() => handleRowClick(survey)}>
                  {renderCell(survey, header)}
                </Table.Cell>
              ))}
              <TableOptions>
                <TableOption onClick={(e) => handleDeleteSurvey(e, survey.id)}>Remove</TableOption>
              </TableOptions>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  )
}

export default SurveysContent