// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vFo6YVoqV3JMve2Ug1ht{margin-bottom:20px;width:75%}.BRyAD86ycNizJi6Uh1sQ{font-size:24px;color:#111827;font-weight:400}.WSZgBBWeE8YKqNcu8lQD{color:rgba(21,23,26,.82);font-size:13px;line-height:20px;margin:15px 0}.iET0wbmSblrAOSwMMDQw{border-radius:0px 0px 8px 8px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/UserGroups/UserGroups.module.css"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,SAAA,CAGF,sBACE,cAAA,CACA,aAAA,CACA,eAAA,CAGF,sBACE,wBAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CAGF,sBACE,6BAAA","sourcesContent":[".header {\n  margin-bottom: 20px;\n  width: 75%;\n}\n\n.heading {\n  font-size: 24px;\n  color: #111827;\n  font-weight: 400;\n}\n\n.subheading {\n  color: rgba(21, 23, 26, 0.82);\n  font-size: 13px;\n  line-height: 20px;\n  margin: 15px 0;\n}\n\n.modalBody {\n  border-radius: 0px 0px 8px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "vFo6YVoqV3JMve2Ug1ht",
	"heading": "BRyAD86ycNizJi6Uh1sQ",
	"subheading": "WSZgBBWeE8YKqNcu8lQD",
	"modalBody": "iET0wbmSblrAOSwMMDQw"
};
export default ___CSS_LOADER_EXPORT___;
