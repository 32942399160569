import { useEffect, useState } from 'react';
import ReactSelect from 'Src/componentsv3/ReactSelect';

import styles from './ReportsModule.module.css';
import QuestionReport from './QuestionReport';
import SendIcon from '../../../../assetsv3/icons/send.svg';
import PercentIcon from '../../../../assetsv3/icons/percent.svg';
import BackIcon from '../../../../assetsv3/icons/corner-up-left.svg';
import ExportIcon from '../../../../assetsv3/icons/export.svg';
import TrashIcon from '../../../../assetsv3/icons/trash.svg';

import { useGetSurveysQuery } from 'Src/servicesV3/surveyApi';
import { useGetCampaignsQuery } from 'Src/servicesV3/campaignApi';
import { 
  useGetSurveyReportsQuery,
  useExportSurveyReportsMutation,
  useExportQuestionResponsesMutation 
} from 'Src/servicesV3/campaignApi';

const ReportsModule = () => {
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [fromDate, selectFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const { data: surveys } = useGetSurveysQuery();
  const { data: campaigns } = useGetCampaignsQuery();
  const { data: surveyReport, isLoading } = useGetSurveyReportsQuery(
    {
      survey_id: selectedSurvey?.value,
      campaign: selectedCampaign?.value,
      from: fromDate,
      to: toDate
    },
    { skip: !selectedSurvey}
  );
  const [exportSurveyReports] = useExportSurveyReportsMutation();
  const [exportQuestionResponses] = useExportQuestionResponsesMutation();

  useEffect(() => {
    if (surveys?.surveys?.length > 0 && !selectedSurvey) {
      const firstSurvey = surveys.surveys[0];
      setSelectedSurvey({ value: firstSurvey.id, label: firstSurvey.title });
    }
  }, [surveys]);

  const getFilteredCampaigns = () => {
    if (!campaigns?.campaigns || !selectedSurvey?.value) return [];
    
    return campaigns.campaigns
      .filter(campaign => campaign.survey_id === selectedSurvey.value)
      .map(campaign => ({
        label: campaign.name,
        value: campaign.id
      }));
  };

  const getMetrics = () => {
    if (!surveyReport) return { sent: 0, responses: 0, rate: '0%' };
    
    return {
      sent: surveyReport.total_requests,
      responses: surveyReport.total_responses,
      rate: `${Math.round((surveyReport.total_responses / surveyReport.total_requests || 0) * 100)}%`
    };
  };

  const handleExport = async () => {
    try {
      await exportSurveyReports({
        survey_id: selectedSurvey?.value,
        campaign: selectedCampaign?.value,
        from: fromDate,
        to: toDate,
      }).unwrap();
    } catch (err) {
      console.error('Export error:', err);
    }
  };

  const handleQuestionDownload = async (questionId) => {
    try {
      await exportQuestionResponses({
        survey_id: selectedSurvey?.value,
        question_id: questionId,
        campaign: selectedCampaign?.value,
        from: fromDate,
        to: toDate
      }).unwrap();
    } catch (err) {
      console.error('Question export error:', err);
    }
  };

  const formatDateToISO = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString).toISOString();
  };

  const handleFromDateChange = (e) => {
    const isoDate = formatDateToISO(e.target.value);
    selectFromDate(isoDate);
  };

  const handleToDateChange = (e) => {
    const isoDate = formatDateToISO(e.target.value);
    setToDate(isoDate);
  };

  const formatDateForInput = (isoString) => {
    if (!isoString) return '';
    return isoString.slice(0, 16);
  };

  const clearFilters = () => {
    setSelectedCampaign(null);
    selectFromDate(null);
    setToDate(null);
  };

  return (
    <>
    <div className={styles.clearFiltersContainer}>
        <button className={styles.clearButton} onClick={clearFilters}>
          Clear Filters
          <TrashIcon />
        </button>
      </div>
    <div className={styles.container}>
      <div className={styles.filtersSection}>
        <span className={styles.filterLabel}>Filter by</span>
        <div className={styles.filterControls}>
          <ReactSelect
            value={selectedSurvey}
            onChange={setSelectedSurvey}
            options={surveys?.surveys?.map(survey => ({
              label: survey.title,
              value: survey.id
            })) || []}
            className={styles.filterSelect}
            placeholder="Select Survey"
          />
          <ReactSelect
            className={styles.filterSelect}
            value={selectedCampaign}
            onChange={setSelectedCampaign}
            options={getFilteredCampaigns()}
            placeholder="Select Campaign"
            isDisabled={!selectedSurvey}
          />
          <input
            type="datetime-local"
            className={styles.dateTimePicker}
            value={formatDateForInput(fromDate)}
            onChange={handleFromDateChange}
            placeholder="From Date"
          />
          <input
            type="datetime-local"
            className={styles.dateTimePicker}
            value={formatDateForInput(toDate)}
            onChange={handleToDateChange}
            placeholder="To Date"
          />
          <button className={styles.exportButton} onClick={handleExport}>
            Export Reports
            <ExportIcon />
          </button>
        </div>
      </div>
      <div className={styles.reportsContainer}>
      {selectedSurvey && !isLoading && surveyReport && (
        <>
          <h2 className={styles.surveyTitle}>
            {surveyReport.survey}
          </h2>

          <div className={styles.metricsContainer}>
            <div className={styles.metricBox}>
              <div className={styles.metricLeft}>
                <SendIcon />
                <span className={styles.metricLabel}>Invites Sent</span>
              </div>
              <span className={styles.metricValue}>{getMetrics().sent}</span>
            </div>
            <div className={styles.metricBox}>
              <div className={styles.metricLeft}>
                <BackIcon />
                <span className={styles.metricLabel}>Responses</span>
              </div>
              <span className={styles.metricValue}>{getMetrics().responses}</span>
            </div>
            <div className={styles.metricBox}>
              <div className={styles.metricLeft}>
                <PercentIcon />
                <span className={styles.metricLabel}>Response Rate</span>
              </div>
              <span className={styles.metricValue}>{getMetrics().rate}</span>
            </div>
          </div>
          {(surveyReport.total_responses != 0) && (
            <div className={styles.questionsContainer}>
              {surveyReport.questions.map((question, index) => (
                <QuestionReport 
                  key={index}
                  questionNumber={index + 1}
                  question={question}
                  onDownload={() => handleQuestionDownload(question.id)}
                />
              ))}
            </div>
          )}
        </>
      )}

      {isLoading && (
        <div className={styles.loadingState}>Loading...</div>
      )}
      </div>
    </div>
    </>
  );
};

export default ReportsModule;