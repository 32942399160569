import { Box, Button, Flex, Modal, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { Fragment, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTable } from 'react-table'

import styles from './UserGroups.module.css'

import PlusIcon from 'Src/assetsv3/icons/plus.svg'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useDeleteUserGroupMutation, useGetUserGroupsQuery } from 'Src/servicesV3/userGroupsApi'
import parseErrorMessage from 'Src/utils/error-message-parser'

const DeleteModal = (props) => {
  const { id, data, ...restProps } = props
  const [deleteUserGroup, deleteUserGroupApiResults] = useDeleteUserGroupMutation()

  const handleDelete = useCallback(() => {
    const promise = deleteUserGroup(id).unwrap()
    toast.promise(promise, {
      loading: 'Deleting user group',
      success: `Deleted user group '${data.name}'. Successfully...`,
      error: parseErrorMessage(`Error while deleting user group '${data.name}'. Try again.`)
    })
    props.onClose()
  }, [id, data, deleteUserGroup, props])

  return (
    <Modal size='small' {...restProps}>
      <Flex direction='column' gap='12px' align='center'>
        Are you sure to delete this user group?
        <Flex gap='15px'>
          <Button variant='solid' disabled={deleteUserGroupApiResults.isLoading} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='outline' disabled={deleteUserGroupApiResults.isLoading} onClick={props.onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

const UserGroupsList = () => {
  const { data: userGroupsApiResponse = {}, isLoading: isUserGroupApiLoading } = useGetUserGroupsQuery()
  const { results: userGroups = [] } = userGroupsApiResponse

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedUserGroup, setSelectedUserGroup] = useState(null)

  const handleDeleteClick = (userGroup) => {
    setSelectedUserGroup(userGroup)
    setIsDeleteModalOpen(true)
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Total Users',
        accessor: 'users_count'
      }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: userGroups
  })

  if (isUserGroupApiLoading) {
    return <TableLoader rows={5} columns={2} showOptions />
  }

  return (
    <Fragment>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...restRowProps } = row.getRowProps()
            const userGroup = row.original
            return (
              <Table.Row key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps()
                  return (
                    <Table.Cell key={key} {...restCellProps}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  )
                })}
                <TableOptions>
                  <TableOption>Edit</TableOption>
                  <TableOption onClick={() => handleDeleteClick(userGroup)}>Delete</TableOption>
                </TableOptions>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false)
          setSelectedUserGroup(null)
        }}
        title='Delete Users'
        showFooter={false}
        bodyClassName={styles.modalBody}
        id={selectedUserGroup?.id}
        data={selectedUserGroup}
      />
    </Fragment>
  )
}

const UserGroups = () => {
  return (
    <Box p='24px'>
      <div className={styles.header}>
        <Flex align='center' gap='12px'>
          <h1 className={styles.heading}>Users</h1>
          <Button variant='solid' size='small' radius='full' className={styles.addButton}>
            <PlusIcon />
          </Button>
        </Flex>
        <p className={styles.subheading}>Users can be grouped together to restrict answers suggested to the users</p>
      </div>
      <UserGroupsList />
    </Box>
  )
}

export default UserGroups
