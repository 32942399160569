import { Box, Button, Flex, Grid, TextField } from '@happyfoxinc/web-components'
import { Fragment, useCallback, useState } from 'react'

import styles from './AccessControl.module.css'

import ChevronRight from 'Src/assetsv3/icons/chevron-full-right.svg'
import CloseIcon from 'Src/assetsv3/icons/close.svg'
import EditIcon from 'Src/assetsv3/icons/edit.svg'
import InfoIcon from 'Src/assetsv3/icons/info.svg'
import TicketIcon from 'Src/assetsv3/icons/ticket.svg'
import ReactSelect from 'Src/componentsv3/ReactSelect'

import {
  getFieldOptionsFromAccessControl,
  getFieldValuesFromAccessControl
} from '../../SoftwareAccessModule/ConfigModule/ConfigModuleUtils'

const SavedCondition = ({ condition, onEdit, conditionNumber, accessControlData }) => {
  const fieldOptions = getFieldOptionsFromAccessControl(accessControlData)

  const allFields = Object.entries(condition).flatMap(([fieldId, idArray]) => {
    const field = accessControlData.find((f) => f.id === parseInt(fieldId))
    return idArray.map((id) => ({
      fieldId: parseInt(fieldId),
      value: {
        id: parseInt(id),
        name: field?.values.find((v) => v.id === parseInt(id))?.name || `Value ${id}`
      }
    }))
  })

  const visibleFields = allFields.slice(0, 2)
  const remainingCount = allFields.length - 2

  return (
    <div className={styles.savedConditionContainer}>
      <div className={styles.savedConditionHeader}>
        <h3 className={styles.savedConditionTitle}>Access Condition {conditionNumber}</h3>
        <button className={styles.editButton} onClick={() => onEdit(conditionNumber - 1)}>
          <EditIcon />
        </button>
      </div>
      <div className={styles.savedConditionContent}>
        {visibleFields.map((field, index) => {
          const fieldOption = fieldOptions.find((f) => f.id === field.fieldId)
          return (
            <div key={`${field.fieldId}-${field.value.id}-${index}`} className={styles.fieldContainer}>
              <div className={styles.fieldValue}>
                <span className={styles.fieldIcon}>{fieldOption?.icon}</span>
                <span className={styles.fieldLabel}>{fieldOption?.name || ''}:</span>
                <span className={styles.fieldValueText}>{field.value.name}</span>
              </div>
              {index === visibleFields.length - 1 && remainingCount > 0 && (
                <div className={styles.remainingCount}>+{remainingCount}</div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const FieldValuePair = ({
  availableFieldOptions = [],
  selectedField,
  selectedValue,
  onFieldChange,
  onValueChange,
  onDelete,
  isOnly,
  availableValues = []
}) => {
  const fieldSelectOptions = availableFieldOptions.map((field) => ({
    value: field.id.toString(),
    label: field.name,
    icon: field.icon
  }))

  const valueSelectOptions = availableValues.map((value) => ({
    value: value.id.toString(),
    label: value.name
  }))

  const selectedFieldOption = availableFieldOptions.find((f) => f.id.toString() === selectedField?.toString())

  return (
    <div className={styles.dropdownContainer}>
      <Box width='240px'>
        <ReactSelect
          value={
            selectedField
              ? {
                  value: selectedField.toString(),
                  label: selectedFieldOption?.name,
                  icon: selectedFieldOption?.icon
                }
              : null
          }
          options={fieldSelectOptions}
          onChange={(option) => onFieldChange(option.value)}
          placeholder='Select Field'
        />
      </Box>
      <Box width='240px'>
        <ReactSelect
          value={selectedValue ? { value: selectedValue.id.toString(), label: selectedValue.name } : null}
          options={valueSelectOptions}
          onChange={(option) => {
            const selectedValueObj = availableValues.find((v) => v.id.toString() === option.value)
            onValueChange(selectedValueObj)
          }}
          isDisabled={!selectedField}
          placeholder='Please select'
        />
      </Box>

      {!isOnly && (
        <button className={styles.deleteFieldButton} onClick={onDelete}>
          <svg xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' width='12' height='11'>
            <path
              fill='none'
              stroke='black'
              strokeWidth='1'
              strokeLinecap='round'
              strokeLinejoin='bevel'
              transform='matrix(0.707107 0.707107 -0.707107 0.707107 7.73165 2.68822)'
              d='M0.25471932 0.25471932L0.25471932 6.8774219'
              fillRule='evenodd'
            />
            <path
              fill='none'
              stroke='black'
              strokeWidth='1'
              strokeLinecap='round'
              strokeLinejoin='bevel'
              transform='matrix(0.707107 0.707107 -0.707107 0.707107 3.22881 2.86833)'
              d='M0 0.25471932L6.6227026 0.25471932'
              fillRule='evenodd'
            />
          </svg>
        </button>
      )}
    </div>
  )
}

const ConditionForm = ({ onSave, onCancel, conditionNumber = 1, initialData = null, accessControlData }) => {
  const getInitialFieldPairs = () => {
    if (initialData) {
      return Object.entries(initialData).flatMap(([fieldId, idArray]) => {
        const field = accessControlData.find((f) => f.id === parseInt(fieldId))
        return idArray.map((id) => ({
          field: parseInt(fieldId),
          value: {
            id,
            name: field?.values.find((v) => v.id === id)?.name || `Value ${id}`
          }
        }))
      })
    }
    return [{ field: '', value: '' }]
  }

  const [fieldPairs, setFieldPairs] = useState(getInitialFieldPairs)

  const getSelectedValuesForField = (field, currentIndex) => {
    return fieldPairs.filter((pair, idx) => idx !== currentIndex && pair.field === field).map((pair) => pair.value)
  }

  const getAvailableValuesForField = (field, currentIndex) => {
    if (!field) return []
    const allValues = getFieldValuesFromAccessControl(field, accessControlData)
    const selectedValues = getSelectedValuesForField(field, currentIndex)
    return allValues.filter((value) => !selectedValues.some((selectedValue) => selectedValue.id === value.id))
  }

  const handleFieldChange = (index, field) => {
    const newPairs = [...fieldPairs]
    newPairs[index] = { field, value: '' }
    setFieldPairs(newPairs)
  }

  const handleValueChange = (index, value) => {
    const newPairs = [...fieldPairs]
    newPairs[index] = { ...newPairs[index], value }
    setFieldPairs(newPairs)
  }

  const handleDeletePair = (index) => {
    const newPairs = fieldPairs.filter((_, i) => i !== index)
    setFieldPairs(newPairs)
  }

  const handleAddPair = () => {
    setFieldPairs([...fieldPairs, { field: '', value: '' }])
  }

  const handleSave = () => {
    const validPairs = fieldPairs.filter((pair) => pair.field && pair.value)

    const transformedCondition = validPairs.reduce((acc, pair) => {
      const fieldId = pair.field.toString()
      if (!acc[fieldId]) {
        acc[fieldId] = []
      }
      acc[fieldId].push(pair.value.id)
      return acc
    }, {})

    if (Object.keys(transformedCondition).length > 0) {
      onSave(transformedCondition, conditionNumber - 1)
    }
  }

  const getAllFields = () => getFieldOptionsFromAccessControl(accessControlData)

  return (
    <div className={styles.conditionContainer}>
      <div className={styles.conditionHeader}>
        <h3 className={styles.conditionTitle}>Access Condition {conditionNumber}</h3>
        <div className={styles.headerButtons}>
          <Button size='small' radius='medium' onClick={handleSave} className={styles.saveButton}>
            Save
          </Button>
          <button className={styles.closeButton} onClick={onCancel}>
            <CloseIcon />
          </button>
        </div>
      </div>

      <div className={styles.dropdownRow}>
        <div className={styles.dropdownLabel}>Field</div>
        <div className={styles.dropdownLabel}>Value</div>
      </div>

      {fieldPairs.map((pair, index) => (
        <FieldValuePair
          key={index}
          availableFieldOptions={getAllFields()}
          selectedField={pair.field}
          selectedValue={pair.value}
          onFieldChange={(field) => handleFieldChange(index, field)}
          onValueChange={(value) => handleValueChange(index, value)}
          onDelete={() => handleDeletePair(index)}
          isOnly={fieldPairs.length === 1}
          availableValues={getAvailableValuesForField(pair.field, index)}
        />
      ))}

      <Button variant='outline' className={styles.addFieldButton} onClick={handleAddPair}>
        + Add
      </Button>
    </div>
  )
}

const TicketCreation = ({ ticketCreationData, onTicketConfigChange }) => {
  const [isEnabled, setIsEnabled] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [category, setCategory] = useState(null)
  const [closedStatus, setClosedStatus] = useState(null)
  const [ticketSubject, setTicketSubject] = useState('')

  const categoryOptions =
    ticketCreationData?.fields
      ?.find((field) => field.name === 'categories')
      ?.value.map((cat) => ({
        value: cat.id.toString(),
        label: cat.name
      })) || []

  const closedStatusOptions =
    ticketCreationData?.fields
      ?.find((field) => field.name === 'default_solved_status')
      ?.value.map((status) => ({
        value: status.id.toString(),
        label: status.name
      })) || []

  const handleTicketConfigChange = (updates = {}) => {
    const newConfig = {
      allow_ticket_create: isEnabled,
      categories: category ? [category.value] : [],
      default_solved_status: closedStatus ? [closedStatus.value] : [],
      ticket_subject: ticketSubject,
      ...updates
    }
    onTicketConfigChange(newConfig)
  }

  const handleToggleEnable = (e) => {
    const newEnabled = e.target.checked
    setIsEnabled(newEnabled)

    if (!newEnabled) {
      setIsExpanded(false)
    } else {
      setIsExpanded(true)
    }

    handleTicketConfigChange({ allow_ticket_create: newEnabled })
  }

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory)
    handleTicketConfigChange({ categories: newCategory ? [newCategory.value] : [] })
  }

  const handleStatusChange = (newStatus) => {
    setClosedStatus(newStatus)
    handleTicketConfigChange({ default_solved_status: newStatus ? [newStatus.value] : [] })
  }

  const handleSubjectChange = (e) => {
    const newSubject = e.target.value
    setTicketSubject(newSubject)
    handleTicketConfigChange({ ticket_subject: newSubject })
  }

  const handleHeaderClick = () => {
    if (isEnabled) {
      setIsExpanded(!isExpanded)
    }
  }

  return (
    <div className={styles.ticketCreationContainer}>
      <Flex align='center' justify='space-between' p='8px 12px' onClick={handleHeaderClick}>
        <div className={styles.ticketHeaderLeft}>
          <ChevronRight className={`${styles.chevron} ${isExpanded ? styles.expanded : ''}`} />
          <TicketIcon />
          <p className={styles.ticketHeading}>Create Ticket</p>
          <p className={styles.ticketSubHeading}>for this software request</p>
          <InfoIcon />
        </div>
        <div className={styles.ticketHeaderRight}>
          <label className={styles.switch}>
            <input type='checkbox' checked={isEnabled} onChange={handleToggleEnable} />
            <span className={styles.slider} />
          </label>
        </div>
      </Flex>

      <div className={`${styles.ticketContent} ${isExpanded ? styles.expanded : ''}`}>
        <Grid columns='1fr 1fr' gap='16px'>
          <Flex direction='column' gap='6px'>
            <label className={styles.formLabel}>Select Category</label>
            <Box width='100%'>
              <ReactSelect
                isModalOpen
                value={category}
                options={categoryOptions}
                onChange={handleCategoryChange}
                placeholder='Please select category'
                className={styles.reactSelect}
                // inDialog
              />
            </Box>
          </Flex>
          <Flex direction='column' gap='6px'>
            <label className={styles.formLabel}>Ticket Status</label>
            <Box width='100%'>
              <ReactSelect
                value={closedStatus}
                options={closedStatusOptions}
                onChange={handleStatusChange}
                placeholder='Please select'
                className={styles.reactSelect}
                // inDialog
              />
            </Box>
          </Flex>
          <Flex direction='column' gap='6px'>
            <label className={styles.formLabel}>Ticket Subject</label>
            <TextField.Root type='text' value={ticketSubject} onChange={handleSubjectChange} />
          </Flex>
        </Grid>
      </div>
    </div>
  )
}

const AccessControl = ({
  accessControlData,
  showTicketCreation = false,
  app,
  onAccessConfigChange,
  onTicketConfigChange,
  ticketCreationData,
  appName = 'App'
}) => {
  const [isFullAccess, setIsFullAccess] = useState(app.accessConfig?.isFullAccess || false)
  const [conditions, setConditions] = useState(app.accessConfig?.conditions || [])
  const [editingIndex, setEditingIndex] = useState(null)
  const [isAddingNew, setIsAddingNew] = useState(false)

  const availableFields = getFieldOptionsFromAccessControl(accessControlData)

  const handleSaveCondition = (condition, index) => {
    const newConditions = [...conditions]

    if (Object.keys(condition).length > 0) {
      if (typeof index === 'number') {
        newConditions[index] = condition
      } else {
        newConditions.push(condition)
      }
      setConditions(newConditions)
      setEditingIndex(null)
      setIsAddingNew(false)

      onAccessConfigChange({
        ...app.accessConfig,
        isFullAccess,
        conditions: newConditions,
        configured: isFullAccess || newConditions.length > 0
      })
    }
  }

  const handleFullAccessChange = (checked) => {
    setIsFullAccess(checked)
    onAccessConfigChange({
      ...app.accessConfig,
      isFullAccess: checked,
      conditions,
      configured: checked || conditions.length > 0
    })
  }

  const handleTicketConfigUpdate = useCallback(
    (ticketConfig) => {
      onTicketConfigChange(ticketConfig)
    },
    [onTicketConfigChange]
  )

  const handleEdit = (index) => {
    setEditingIndex(index)
    setIsAddingNew(false)
  }

  const handleAddNew = () => {
    setIsAddingNew(true)
    setEditingIndex(null)
  }

  const handleCancel = () => {
    setEditingIndex(null)
    setIsAddingNew(false)
  }

  return (
    <div className={styles.accessControlContainer}>
      <div className={styles.conditionsWrapper}>
        <div className={styles.fullAccessContainer}>
          <h3 className={styles.fullAccessTitle}>Full Access - </h3>
          <p className={styles.fullAccessDescription}>{`No Access Control Required for this ${appName}`}</p>
          <div className={styles.fullAccessToggle}>
            <label className={styles.switch}>
              <input
                type='checkbox'
                checked={isFullAccess}
                onChange={(e) => handleFullAccessChange(e.target.checked)}
              />
              <span className={styles.slider} />
            </label>
          </div>
        </div>
        {!isFullAccess && (
          <Fragment>
            {conditions.map((condition, index) =>
              editingIndex === index ? (
                <ConditionForm
                  key={index}
                  conditionNumber={index + 1}
                  initialData={condition}
                  accessControlData={accessControlData}
                  onSave={handleSaveCondition}
                  onCancel={handleCancel}
                />
              ) : (
                <SavedCondition
                  key={index}
                  condition={condition}
                  conditionNumber={index + 1}
                  accessControlData={accessControlData}
                  onEdit={handleEdit}
                />
              )
            )}

            {isAddingNew ? (
              <ConditionForm
                conditionNumber={conditions.length + 1}
                accessControlData={accessControlData}
                onSave={handleSaveCondition}
                onCancel={handleCancel}
              />
            ) : (
              availableFields.length > 0 && (
                <Button variant='outline' radius='full' className={styles.addConditionButton} onClick={handleAddNew}>
                  + Add Condition
                </Button>
              )
            )}
          </Fragment>
        )}
      </div>
      {showTicketCreation && (
        <TicketCreation ticketCreationData={ticketCreationData} onTicketConfigChange={handleTicketConfigUpdate} />
      )}
    </div>
  )
}

export default AccessControl
