import React, { useState, useEffect, useRef } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import cx from 'classnames'
import styles from '../ConfigModule.module.css'
import { DragHandle } from './UtilityComponents'
import { ChoiceContent, TextContent } from './QuestionContent'
import { getQuestionTypeLabel, QUESTION_TYPES } from '../constants'
import KebabMenu from 'Src/assetsv3/icons/kebab-menu.svg'
import RemoveIcon from 'Src/assetsv3/icons/remove.svg'
import Check from 'Src/assets/icons/check.svg'
import Edit from 'Src/assetsv3/icons/edit.svg'
import ArrowRightIcon from 'Src/assets/icons/arrow-right.svg'

export const QuestionBlock = ({ questionNumber, question, onChange, onRemove, dragHandleProps }) => {
  const [showOptions, setShowOptions] = useState(false)
  const [showTypeOptions, setShowTypeOptions] = useState(false)
  const optionsRef = useRef(null)
  const typeOptionsRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false)
      }
      if (typeOptionsRef.current && !typeOptionsRef.current.contains(event.target)) {
        setShowTypeOptions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleRequired = () => {
    onChange({
      ...question,
      isRequired: !question.isRequired
    });
  };

  const handleTypeChange = (newType) => {
    onChange({
      ...question,
      type: newType,
      options: newType !== 'open_ended' ? question.options || ['', '', ''] : undefined,
      isModified: true
    })
    setShowTypeOptions(false)
    setShowOptions(false)
  }

  return (
    <div className={styles.questionBlock}>
      <div className={styles.stepNumber}>{questionNumber}</div>
      <div className={styles.questionHeader}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DragHandle dragHandleProps={dragHandleProps} />
          <h3 className={styles.questionTitle}>
            Question {questionNumber} {question.type !== 'open_ended' && `- ${getQuestionTypeLabel(question.type)}`}
            <span className={styles.requiredStatus}>
              {!question.isRequired && ' (Optional)'}
            </span>
          </h3>
        </div>
        <div className={styles.questionHeaderRight} ref={optionsRef}>
          <button className={styles.optionsButton} onClick={() => setShowOptions(!showOptions)}>
            <KebabMenu />
          </button>
          {showOptions && (
            <div className={styles.optionsDropdown} onClick={(e) => e.stopPropagation()}>
              <button 
                className={cx(styles.optionsItem, styles.typeOption)} 
                onMouseEnter={() => setShowTypeOptions(true)}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Edit  style={{ width: '12px', height: '12px' }}/>
                  <span>Change Question Type</span>
                </div>
                <ArrowRightIcon style={{ width: '12px', height: '12px' }} />
                {showTypeOptions && (
                  <div 
                    className={styles.typeOptionsDropdown} 
                    ref={typeOptionsRef}
                  >
                    {QUESTION_TYPES.map(type => (
                      <button
                        key={type.key}
                        className={cx(styles.typeOptionItem, {
                          [styles.activeType]: question.type === type.key
                        })}
                        onClick={() => handleTypeChange(type.key)}
                      >
                        {type.label}
                      </button>
                    ))}
                  </div>
                )}
              </button>
              <button className={styles.optionsItem} onClick={toggleRequired}>
                <Check  style={{ width: '12px', height: '12px' }}/>
                {question.isRequired ? 'Mark as Optional' : 'Mark as Required'}
              </button>
              <button className={styles.optionsItem} onClick={() => {
                onRemove()
                setShowOptions(false)
              }}>
                <RemoveIcon  style={{ width: '12px', height: '12px' }}/>
                Remove
              </button>
            </div>
          )}
        </div>
      </div>
      {question.type === 'open_ended' ? (
        <TextContent question={question} onChange={onChange} />
      ) : (
        <ChoiceContent question={question} onChange={onChange} />
      )}
    </div>
  )
}

export const SortableQuestionBlock = ({ id, isDragging, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id });

  const dragPlaceholderStyle = {
    '--translate-x': transform ? `${transform.x}px` : '0',
    '--translate-y': transform ? `${transform.y}px` : '0',
    transition
  };

  return (
    <div ref={setNodeRef} {...attributes} style={dragPlaceholderStyle}>
      {isDragging ? (
        <div className={styles.dragPlaceholder} />
      ) : (
        <QuestionBlock
          {...props}
          dragHandleProps={{...listeners}}
          className={cx(styles.questionBlock, {
            [styles.questionBlockDragging]: isDragging
          })}
        />
      )}
    </div>
  );
};