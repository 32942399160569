import { createTheme } from '@happyfoxinc/react-ui'

export const assistAI = createTheme('AssistAI', {
  colors: {
    default: 'var(--default-text)',
    muted: 'var(--muted)',

    // similar color config is available for secondary, success and danger
    accentBase: 'var(--primary-base)',
    accentBgSubtle: 'var(--primary-bg-subtle)',
    accentBg: 'var(--primary-bg)',
    accentBgHover: 'var(--primary-bg-hover)',
    accentBgActive: 'var(--primary-bg-active)',
    accentLine: 'var(--primary-line)',
    accentBorder: 'var(--primary-border)',
    accentBorderHover: 'var(--primary-border-hover)',
    accentSolid: 'var(--primary-solid)',
    accentSolidHover: 'var(--primary-solid-hover)',
    // accentText: 'var(--primary-text)',
    // accentTextContrast: 'var(--primary-text-contrast)',

    successSolid: 'var(--green)',
    dangerSolid: 'var(--red)',
    borderLight: 'var(--border-color-light)',

    tableHead: 'var(--table-head-bg-color)',
    tableBody: 'var(--table-body-bg-color)',
    checkboxCheckedBg: 'var(--green)'
  },

  space: {
    inputPaddingY: 'var(--input-padding-y)',
    inputPaddingX: 'var(--input-padding-x)',
    buttonPaddingY: 'var(--button-padding-y)',
    buttonPaddingX: 'var(--button-padding-x)',
    buttonPaddingSmY: 'var(--button-padding-sm-y)',
    buttonPaddingSmX: 'var(--button-padding-sm-x)'
  },

  fontSizes: {
    hero: 'var(--text-hero)',
    heading1: 'var(--text-heading1)',
    heading2: 'var(--text-heading2)',
    sm: 'var(--text-sm)',
    xs: 'var(--text-xs)'
  },

  radii: {
    rounded: 'var(--border-radius)'
  },

  fontWeights: {
    heading: '$normal'
  }
})

const assistAIv3 = createTheme('AssistAIv3', {
  fonts: {
    '@font-face': [
      {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '300',
        fontDisplay: 'swap',
        src: 'url("./assetsv3/fonts/Inter-Light.woff2") format("woff2")'
      },
      {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontDisplay: 'swap',
        src: 'url("./assetsv3/fonts/Inter-Regular.woff2") format("woff2")'
      },
      {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontDisplay: 'swap',
        src: 'url("./assetsv3/fonts/Inter-Medium.woff2") format("woff2")'
      },
      {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '600',
        fontDisplay: 'swap',
        src: 'url("./assetsv3/fonts/Inter-SemiBold.woff2") format("woff2")'
      }
    ],
    inter: 'Inter, Helvetica, sans-serif',
    body: '$inter'
  }
})

export { assistAI as default, assistAIv3 }
