// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JmXoe6VaiEgmb37FQnx4{padding:5px 25px;border-radius:25px;border:1px solid rgba(133,133,133,.3);cursor:pointer}.JmXoe6VaiEgmb37FQnx4:hover{background-color:#f3f4f6}.JmXoe6VaiEgmb37FQnx4.dpBS4wjrxUO5Fyz424Qk{background-color:#000;color:#fff;border:none;opacity:1}.JmXoe6VaiEgmb37FQnx4.wSpRUPkkAAsHMiSS9PSF{width:120px;height:40px;border-radius:9999px;background-color:#f3f4f6}", "",{"version":3,"sources":["webpack://./../src/componentsv3/Tab/Tab.module.css"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,kBAAA,CACA,qCAAA,CACA,cAAA,CAGF,4BACE,wBAAA,CAGF,2CACE,qBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CAGF,2CACE,WAAA,CACA,WAAA,CACA,oBAAA,CACA,wBAAA","sourcesContent":[".tabHeading {\n  padding: 5px 25px;\n  border-radius: 25px;\n  border: 1px solid rgba(133, 133, 133, 0.3);\n  cursor: pointer;\n}\n\n.tabHeading:hover {\n  background-color: #F3F4F6;\n}\n\n.tabHeading.active {\n  background-color: #000000;\n  color: #ffffff;\n  border: none;\n  opacity: 1;\n}\n\n.tabHeading.skeleton {\n  width: 120px;\n  height: 40px;\n  border-radius: 9999px;\n  background-color: #F3F4F6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabHeading": "JmXoe6VaiEgmb37FQnx4",
	"active": "dpBS4wjrxUO5Fyz424Qk",
	"skeleton": "wSpRUPkkAAsHMiSS9PSF"
};
export default ___CSS_LOADER_EXPORT___;
