import { Box, Button, Flex, Modal, TextField } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'

import styles from './Channels.module.css'

import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { AUTOMATIC, COMMON_TEXT, MODE_OPTIONS, SYNC_TO_CHANNEL_OPTIONS } from 'Src/constants/channels'
import { TICKETING_INTEGRATION } from 'Src/constants/ticketingIntegration'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useEditSlackChannelMutation } from 'Src/servicesV3/channelsApi'
import parseErrorMessage from 'Src/utils/error-message-parser'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { getEditChannelPayload, getModeFormValue } from './channels-helper'
import { HelpDeskFields, ServiceDeskFields } from './TicketingSpecificFields'

const getValidationSchema = (isHfHelpdeskTicketingConnected) => {
  const allowedCategoriesOrTeamsKey = isHfHelpdeskTicketingConnected ? 'allowedCategories' : 'allowedTeams'
  const defaultCategoryOrTeamForAutoModeKey = isHfHelpdeskTicketingConnected
    ? 'defaultCategoryForAutoMode'
    : 'defaultTeamForAutoMode'
  const defaultCategoryOrTeamForManualModeKey = isHfHelpdeskTicketingConnected
    ? 'defaultCategoryForManualMode'
    : 'defaultTeamForManualMode'

  const { ALLOWED_CATEGORIES_ERROR, ALLOWED_TEAMS_ERROR, DEFAULT_CATEGORY_ERROR, DEFAULT_TEAM_ERROR } = COMMON_TEXT

  const allowedCategoriesOrTeamsError = isHfHelpdeskTicketingConnected ? ALLOWED_CATEGORIES_ERROR : ALLOWED_TEAMS_ERROR
  const defaultCategoryOrTeamError = isHfHelpdeskTicketingConnected ? DEFAULT_CATEGORY_ERROR : DEFAULT_TEAM_ERROR

  return yup
    .object()
    .shape({
      syncToChannelThread: yup.boolean().required('Sync to Channel is required'),
      mode: yup
        .string()
        .nullable()
        .when('syncToChannelThread', {
          is: true,
          then: (schema) => schema.required('Field is required'),
          otherwise: (schema) => schema.notRequired()
        }),
      workspace_id: yup.string().when('$showWorkspaceDropdown', {
        is: true,
        then: (schema) => schema.required('Workspace is required'),
        otherwise: (schema) => schema.notRequired()
      }),
      [allowedCategoriesOrTeamsKey]: yup
        .array()
        .notRequired()
        .when(['syncToChannelThread', 'mode'], {
          is: (syncToChannelThread, mode) => syncToChannelThread && mode && mode !== AUTOMATIC,
          then: (schema) => schema.min(1, allowedCategoriesOrTeamsError).required(allowedCategoriesOrTeamsError)
        }),
      [defaultCategoryOrTeamForAutoModeKey]: yup
        .object()
        .notRequired()
        .when(['syncToChannelThread', 'mode'], {
          is: (syncToChannelThread, mode) => syncToChannelThread && mode === AUTOMATIC,
          then: (schema) => schema.required(defaultCategoryOrTeamError)
        }),
      [defaultCategoryOrTeamForManualModeKey]: yup.object().notRequired()
    })
    .required()
}

const EditChannel = ({ isOpen, onClose, channelData }) => {
  const { data: account } = useGetAccountQuery()
  const { workspaces } = useWorkspace()
  const [editSlackChannel, editSlackChannelResult] = useEditSlackChannelMutation()

  const showWorkspaceDropdown = account?.is_workspaces_enabled
  const isHfHelpdeskTicketingConnected = account.connected_ticketing_integration === TICKETING_INTEGRATION.HF_HELP_DESK
  const isHfServicedeskTicketingConnected =
    account.connected_ticketing_integration === TICKETING_INTEGRATION.HF_SERVICE_DESK

  const {
    channel_id,
    name,
    sync_to_channel_thread,
    convert_messages,
    workspace_id,
    // for HD
    default_category,
    allowed_categories,
    // for SD
    default_team,
    allowed_teams
  } = channelData

  const isAutoMode = sync_to_channel_thread && convert_messages === AUTOMATIC
  const isManualMode = sync_to_channel_thread && convert_messages !== AUTOMATIC

  const formMethods = useForm({
    defaultValues: {
      syncToChannelThread: sync_to_channel_thread,
      mode: getModeFormValue(channelData),
      workspace_id,

      ...(isHfHelpdeskTicketingConnected && {
        defaultCategoryForAutoMode: isAutoMode ? default_category : null,
        defaultCategoryForManualMode: isManualMode ? default_category : null,
        allowedCategories: allowed_categories
      }),

      ...(isHfServicedeskTicketingConnected && {
        defaultTeamForAutoMode: isAutoMode ? default_team : null,
        defaultTeamForManualMode: isManualMode ? default_team : null,
        allowedTeams: allowed_teams
      })
    },
    resolver: yupResolver(getValidationSchema(isHfHelpdeskTicketingConnected))
  })

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
    watch
  } = formMethods

  const showModeSelection = watch('syncToChannelThread')

  const handleFormSubmit = (value) => {
    const payload = getEditChannelPayload(value, {
      isHfHelpdeskTicketingConnected,
      isHfServicedeskTicketingConnected
    })

    if (payload.workspace_id === null) {
      const defaultWorkspace = workspaces.find((workspace) => workspace.settings.default === true)

      payload.workspace_id = defaultWorkspace.id
    }

    const promise = editSlackChannel({ channelId: channel_id, ...payload }).unwrap()

    toast.promise(promise, {
      loading: 'Updating the channel configuration',
      success: 'Channel configuration updated successfully',
      error: parseErrorMessage('Unable to update the channel configuration. Try again...')
    })

    promise.then(onClose).catch(() => {})
  }

  const shouldDisableSave = !isDirty || editSlackChannelResult.isLoading

  const formatOptionLabel = ({ label, subLabel }, { context }) => {
    if (context === 'menu') {
      return (
        <Fragment>
          <p>{label}</p>
          <p className={styles.optionSubMenu}>{subLabel}</p>
        </Fragment>
      )
    }

    return label
  }

  return (
    <Modal
      size='large'
      open={isOpen}
      onOpenChange={onClose}
      onClose={onClose}
      title='Edit Channel'
      showFooter={false}
      bodyClassName={styles.modalBody}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormField>
            <FormField.Field label='Channel Name' error={errors?.syncToChannelThread?.message}>
              <Box width='330px'>
                <TextField.Root defaultValue={name} size='2' radius='small' placeholder='Channel Name' disabled />
              </Box>
            </FormField.Field>
          </FormField>

          {showWorkspaceDropdown && (
            <FormField>
              <FormField.Field label='Workspace' error={errors?.workspace_id?.message}>
                <Controller
                  name='workspace_id'
                  control={control}
                  render={({ field }) => (
                    <Box width='330px'>
                      <ReactSelect
                        {...field}
                        value={workspaces.find((opt) => opt.id === field.value)}
                        onChange={(opt) => field.onChange(opt.id)}
                        isClearable={false}
                        options={workspaces}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                      />
                    </Box>
                  )}
                />
              </FormField.Field>
            </FormField>
          )}

          <FormField>
            <FormField.Field
              label='Should Assist AI post ticket info & updates publicly in the channel ?'
              error={errors?.syncToChannelThread?.message}
            >
              <Controller
                control={control}
                name='syncToChannelThread'
                render={({ field }) => (
                  <Box width='430px'>
                    <ReactSelect
                      {...field}
                      isSearchable={false}
                      options={SYNC_TO_CHANNEL_OPTIONS}
                      value={SYNC_TO_CHANNEL_OPTIONS.find(({ value }) => field.value === value)}
                      onChange={({ value }) => field.onChange(value)}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      formatOptionLabel={formatOptionLabel}
                    />
                  </Box>
                )}
              />
            </FormField.Field>
          </FormField>

          {showModeSelection && (
            <FormField>
              <FormField.Field label='How do you want Assist AI to create tickets ?' error={errors?.mode?.message}>
                <Controller
                  name='mode'
                  control={control}
                  render={({ field }) => (
                    <Box width='630px'>
                      <ReactSelect
                        {...field}
                        isSearchable={false}
                        options={MODE_OPTIONS}
                        value={MODE_OPTIONS.find(({ value }) => field.value === value)}
                        onChange={({ value }) => field.onChange(value)}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        formatOptionLabel={formatOptionLabel}
                      />
                    </Box>
                  )}
                />
              </FormField.Field>
            </FormField>
          )}

          {isHfHelpdeskTicketingConnected && <HelpDeskFields />}
          {isHfServicedeskTicketingConnected && <ServiceDeskFields />}

          <Flex gap='24px'>
            <Button type='submit' disabled={shouldDisableSave} variant='solid'>
              Save
            </Button>
            <Button onClick={onClose} variant='outline'>
              Cancel
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default EditChannel
