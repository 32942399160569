import baseApi, { TAGS } from './baseApi'

export const channelsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSlackChannels: builder.query({
      query: (params) => ({
        url: 'v1/slack/channels',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.SLACK_CHANNELS]
    }),

    editSlackChannel: builder.mutation({
      query: ({ channelId, ...data }) => ({
        url: `v1/slack/channels/${channelId}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.SLACK_CHANNELS]
    })
  })
})

export const { useGetSlackChannelsQuery, useEditSlackChannelMutation } = channelsApi
