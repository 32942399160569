import { useEffect, useRef } from 'react'

import styles from './Topbar.module.css'

import WorkspaceSelect from 'Src/componentsv3/WorkspaceSelect'
import { useGetProfileQuery } from 'Src/servicesV3/authApi'

import SearchIcon from '../../assetsv3/icons/search.svg'
import SupportHelpIcon from '../../assetsv3/icons/support-copy.svg'
import UserIcon from '../../assetsv3/icons/user.svg'

const Topbar = ({ isSearchActive, setIsSearchActive }) => {
  const { data: user } = useGetProfileQuery()
  const searchRef = useRef(null)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        setIsSearchActive(true)
      }
      if (event.key === 'Escape') {
        setIsSearchActive(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [setIsSearchActive])

  useEffect(() => {
    if (isSearchActive && searchRef.current) {
      searchRef.current.focus()
    }
  }, [isSearchActive])

  return (
    <div className={styles.topbarContainer}>
      <div className={styles.topbar}>
        <h1 className={styles.logo}>Assist AI</h1>

        <div className={styles.searchContainer}>
          <div className={styles.searchWrapper}>
            <SearchIcon className={styles.searchIcon} />
            <input ref={searchRef} type='text' className={styles.searchInput} placeholder='Search' />
            <div className={styles.searchShortcut}>
              <span className={styles.shortcutKey}>CMD ⌘</span>
              <span>K</span>
            </div>
          </div>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.iconButton}>
            <SupportHelpIcon />
          </div>

          <WorkspaceSelect />

          {/* <button className={styles.avatarButton}> */}
          {user?.avatar_url ? <img src={user?.avatar_url} alt='User avatar' className={styles.avatar} /> : <UserIcon />}
          {/* </button> */}
        </div>
      </div>
    </div>
  )
}

export default Topbar
