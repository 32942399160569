import { Outlet, Route, Routes } from 'react-router-dom'

import UserGroups from './UserGroups'

const UserRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route path='' index element={<UserGroups />} />
      </Route>
    </Routes>
  )
}

export default UserRouter
