import React, { createContext, useContext, useEffect, useState } from 'react'

const WorkspaceContext = createContext()

export const WorkspaceProvider = ({ workspaces, children }) => {
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(null)

  useEffect(() => {
    if (!workspaces.length) {
      setCurrentWorkspaceId(null)
      return
    }

    const savedWorkspaceId = localStorage.getItem('workspaceId')
    let newWorkspaceId = null

    if (savedWorkspaceId && workspaces.some((workspace) => workspace.id === savedWorkspaceId)) {
      newWorkspaceId = savedWorkspaceId
    } else {
      const defaultWorkspace = workspaces.find((workspace) => workspace.settings?.default)
      newWorkspaceId = defaultWorkspace?.id || workspaces[0]?.id
    }

    setCurrentWorkspaceId(newWorkspaceId)
    if (newWorkspaceId) {
      localStorage.setItem('workspaceId', newWorkspaceId)
    }
  }, [workspaces])

  const setWorkspace = (newWorkspaceId) => {
    if (!newWorkspaceId || !workspaces.some((workspace) => workspace.id === newWorkspaceId)) {
      return
    }
    setCurrentWorkspaceId(newWorkspaceId)
    localStorage.setItem('workspaceId', newWorkspaceId)
  }

  const value = {
    workspaces,
    currentWorkspaceId,
    setCurrentWorkspaceId: setWorkspace
  }

  return <WorkspaceContext.Provider value={value}>{children}</WorkspaceContext.Provider>
}

export const useWorkspace = () => {
  const context = useContext(WorkspaceContext)
  if (!context) {
    throw new Error('useWorkspace must be used within a WorkspaceProvider')
  }
  return context
}
