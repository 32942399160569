const { useState, createContext, useContext } = require('react')

const SidebarToggleContext = createContext()

const SidebarToggleProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const toggleSidebar = () => setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen)

  return (
    <SidebarToggleContext.Provider value={{ isSidebarOpen, toggleSidebar }}>{children}</SidebarToggleContext.Provider>
  )
}

const useSidebarToggle = () => {
  const context = useContext(SidebarToggleContext)

  if (context === undefined) {
    throw new Error('useSidebarToggle must be used within a SidebarToggleProvider')
  }

  return context
}

// export sidebar toggle context function
export { SidebarToggleProvider, useSidebarToggle }
