import { Flex, Modal, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import dayjs from 'dayjs'
import { Fragment, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTable } from 'react-table'

import styles from './CompanyPolicyModule.module.css'

import WarningIcon from 'Src/assetsv3/icons/warning.svg'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useDeletePolicyMutation, useGetPoliciesQuery } from 'Src/servicesV3/companyPolicyModuleApi'

const CompanyPolicyList = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedPolicyId, setSelectedPolicyId] = useState(null)

  const { data = {}, isLoading } = useGetPoliciesQuery()
  const { meta: paginationDetails = {}, data: policiesList = [] } = data || {}
  const [deletePolicy] = useDeletePolicyMutation()

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Configured',
        accessor: 'settings.configured',
        Cell: ({ cell: { value } }) => {
          return (
            <Flex as='span' align='center' gap='6px'>
              <span>{value ? 'Configured' : 'Not Configured'}</span>
              {!value && <WarningIcon className={styles.warningIcon} />}
            </Flex>
          )
        }
      },
      {
        Header: 'Access Type',
        accessor: 'settings.access_type',
        Cell: ({ cell: { value } }) => {
          return value || '-'
        }
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format('MMM D, YYYY h:mm A')
        }
      }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: policiesList
  })

  const handleOpenDeleteModal = (policyId) => {
    setSelectedPolicyId(policyId)
    setIsDeleteModalOpen(true)
  }

  const handleDeletePolicy = useCallback(() => {
    const promise = deletePolicy(selectedPolicyId).unwrap()
    promise.then(() => {
      setIsDeleteModalOpen(false)
      setSelectedPolicyId(null)
    })

    toast.promise(promise, {
      loading: 'Deleting policy',
      success: 'Policy deleted successfully',
      error: 'Unable to delete policy. Try again...'
    })
  }, [deletePolicy, selectedPolicyId])

  if (isLoading) {
    return <TableLoader rows={5} columns={4} showOptions />
  }

  return (
    <Fragment>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...restRowProps } = row.getRowProps()
            return (
              <Table.Row key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps()
                  return (
                    <Table.Cell key={key} {...restCellProps}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  )
                })}
                <TableOptions>
                  <TableOption onClick={() => handleOpenDeleteModal(row.original.id)}>Delete</TableOption>
                </TableOptions>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
      <Modal
        title='Delete Policy'
        size='small'
        confirmText='Delete'
        onCancel={() => {
          setIsDeleteModalOpen(false)
          setSelectedPolicyId(null)
        }}
        onConfirm={handleDeletePolicy}
        open={isDeleteModalOpen}
      >
        <div className={styles.modalContent}>
          <p>Are you sure you want to delete this policy?</p>
        </div>
      </Modal>
    </Fragment>
  )
}

export default CompanyPolicyList
