import { Box } from '@happyfoxinc/web-components'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'

import styles from './Modules.module.css'

import ChevronRight from 'Src/assetsv3/icons/chevron-right.svg'
import CheckCircle from 'Src/assetsv3/icons/green-tick.svg'
// import IamIcon from 'Src/assetsv3/logos/iam-logo.svg'
import CompanyPolicyIcon from 'Src/assetsv3/logos/policy-logo.svg'
import SurveysIcon from 'Src/assetsv3/logos/surveys-logo.svg'
import SoftwareAccessIcon from 'Src/assetsv3/logos/software-access-logo.svg'
import { useGetPoliciesQuery } from 'Src/servicesV3/companyPolicyModuleApi'
import { useGetSoftwareAccessQuery } from 'Src/servicesV3/softwareAccessModuleApi'

import CompanyPolicyModule from './CompanyPolicyModule'
import SoftwareAccessModule from './SoftwareAccessModule'
import SurveyManagementModule from './SurveyManagementModule'

import { useGetSurveysQuery } from 'Src/servicesV3/surveyApi';

const ModuleCard = ({ icon: Icon, title, description, isConfigured = false, onClick }) => {
  return (
    <div className={styles.card} onClick={onClick} role='button' tabIndex={0}>
      <div className={styles.cardHeader}>
        <Box p='0 0 12px 0'>
          <Icon className={styles.icon} />
        </Box>
      </div>

      <div className={styles.cardContent}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>

      <div className={isConfigured ? styles.cardFooter : styles.cardFooterNoConfig}>
        {isConfigured && (
          <div className={styles.configuredBadge}>
            <span>Configured</span>
            <CheckCircle />
          </div>
        )}
        <div className={styles.arrowContainer}>
          <ChevronRight />
        </div>
      </div>
    </div>
  )
}

const ModulesSection = () => {
  const navigate = useNavigate()
  const { data: softwareAccessApiResponse = {} } = useGetSoftwareAccessQuery()
  const { data = {} } = useGetPoliciesQuery()

  const { apps: softwareAccessList = [] } = softwareAccessApiResponse
  const { data: policiesList = [] } = data || {}

  const { data: surveys, isLoading: isSurveysLoading } = useGetSurveysQuery();

  const modules = [
    {
      icon: SoftwareAccessIcon,
      title: 'Software Access Request Management',
      description:
        'Manage and streamline employee requests for software access, ensuring quick approvals and proper authorization.',
      isConfigured: softwareAccessList.length > 0,
      path: 'software-access'
    },
    // {
    //   icon: IamIcon,
    //   title: 'IAM Request Management',
    //   description:
    //     'Handle identity and access management (IAM) requests efficiently, including user roles, permissions, and access controls.',
    //   isConfigured: false,
    //   path: 'iam-request'
    // },
    {
      icon: CompanyPolicyIcon,
      title: 'Company Policy Requests',
      description:
        'Centralize and track requests related to company policies, making it easy to manage policy inquiries, updates, or approvals.',
      isConfigured: policiesList.length > 0,
      path: 'company-policy'
    },
    {
      icon: SurveysIcon,
      title: 'Survey Management',
      description:
        'Easily create and manage surveys to gather user feedback, enabling quick insights and informed decision-making.',
      isConfigured: surveys?.surveys?.length,
      path: 'survey-management'
    }
  ]

  const handleModuleClick = (path) => {
    if (path) {
      navigate(path)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1 className={styles.heading}>Modules</h1>
          <p className={styles.subheading}>
            Choose from our predefined modules to quickly get started based on your specific use case. Once selected,
            you can configure and customize each module to suit your requirements, making it easy to set up and adapt
            the tool to your workflow.
          </p>
        </div>

        <div className={styles.grid}>
          {modules.map((module, index) => (
            <ModuleCard
              key={index}
              icon={module.icon}
              title={module.title}
              description={module.description}
              isConfigured={module.isConfigured}
              path={module.path}
              onClick={() => handleModuleClick(module.path)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const Modules = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route path='' element={<ModulesSection />} />
        <Route path='software-access' element={<SoftwareAccessModule />} />
        <Route path='iam-request' element={<div>IAM Request Module</div>} />
        <Route path='company-policy' element={<CompanyPolicyModule />} />
        <Route path='survey-management' element={<SurveyManagementModule/>} />
      </Route>
    </Routes>
  )
}

export default Modules
