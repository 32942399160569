import { Box, Button, Modal, TextField } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'

import styles from './Admins.module.css'

import FormField from 'Src/componentsv3/FormField'
import { useInviteAdminMutation } from 'Src/servicesV3/adminsApi'
import parseErrorMessage from 'Src/utils/error-message-parser'

const inviteAdminValidationSchema = yup
  .object()
  .shape({
    email: yup.string().email('Invalid Email').required('Email is required')
  })
  .required()

const InviteAdmin = ({ open, onClose }) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(inviteAdminValidationSchema)
  })

  const [inviteAdmin] = useInviteAdminMutation()

  const handleInviteFormSubmit = async (data) => {
    try {
      const payload = {
        email: data.email
      }

      const promise = inviteAdmin(payload).unwrap()

      toast.promise(promise, {
        loading: 'Inviting admin',
        success: `Invited admin '${data.email}'. Successfully...`,
        error: parseErrorMessage(`Error while inviting admin '${data.email}'. Try again.`)
      })
      await promise
      onClose()
    } catch (error) {
      onClose()
    }
  }

  const handleModalClose = () => {
    reset()
    onClose()
  }

  return (
    <Modal
      open={open}
      onOpenChange={handleModalClose}
      title='Invite User'
      bodyClassName={styles.modalBody}
      showFooter={false}
    >
      <form onSubmit={handleSubmit(handleInviteFormSubmit)}>
        <FormField>
          <FormField.Field error={errors?.email?.message} label='Email'>
            <Box width='330px'>
              <TextField.Root {...register('email')} size='2' radius='small' placeholder='Enter email' />
            </Box>
          </FormField.Field>
        </FormField>
        <Button variant='solid' type='submit' disabled={isSubmitting}>
          Submit
        </Button>
      </form>
    </Modal>
  )
}

export default InviteAdmin
