import { Box, Flex } from '@happyfoxinc/web-components'

import styles from './ConfigModule.module.css'

const SkeletonLoader = () => {
  return (
    <div>
      <Flex direction='column' gap='20px' width='85%' className={styles.sectionHeadContainer}>
        <div className={styles.sectionTitle}>
          <div className={styles.skeletonTitle} />
        </div>
        <div className={styles.sectionDescription}>
          <div className={styles.skeletonText} />
        </div>
      </Flex>

      {/* App Selection Heading */}
      <div className={styles.appSelectionHeadingContainer}>
        <div className={styles.appSelectionHeading}>
          <div className={styles.skeletonTitle} />
        </div>
        <div className={styles.appSelectionDescription}>
          <div className={styles.skeletonText} />
        </div>
      </div>

      {/* Category Container */}
      <div className={styles.categoryContainer}>
        <div className={styles.categoryHeader}>
          <div className={styles.titleContainer}>
            <div className={styles.skeletonIcon} />
            <div className={styles.categoryName}>
              <div className={styles.skeletonText} />
            </div>
          </div>
          <div className={styles.appsCount}>
            <div className={styles.skeletonText} />
          </div>
        </div>

        <Flex direction='column'>
          {Array.from({ length: 7 }).map((_, index) => (
            <div key={index} className={styles.appCard}>
              <div className={styles.appInfo}>
                <div className={styles.skeletonIcon} />
                <div className={styles.appName}>
                  <div className={styles.skeletonText} />
                </div>
                <div className={styles.appDescription}>
                  <div className={styles.skeletonText} />
                </div>
              </div>
              <div className={styles.appInfo}>
                <div className={styles.skeletonText} />
              </div>
            </div>
          ))}
        </Flex>
      </div>
    </div>
  )
}

const ConfigPanelSkeleton = () => {
  return (
    <div className={styles.configurationPanel}>
      <div className={styles.configTitleContainer}>
        <div className={styles.skeletonConfigIcon} />
        <div className={styles.configTitle}>
          <div className={styles.skeletonText} />
        </div>
      </div>

      {/* Form Fields */}
      <div className={styles.skeletonForm}>
        {[1, 2, 3].map((index) => (
          <div key={index} className={styles.skeletonFormItem}>
            <div className={styles.skeletonFormLabel} />
            <div className={styles.skeletonFormField} />
          </div>
        ))}
      </div>
    </div>
  )
}

const ConfigLoadingScreen = () => {
  return (
    <Flex gap='24px' height='calc(100vh - 200px)' overflow='hidden'>
      <Box width='50%' overflowY='auto' p='3rem 3rem 3rem 4rem'>
        <SkeletonLoader />
      </Box>
      <div className={styles.configurationPanel}>
        <ConfigPanelSkeleton />
      </div>
    </Flex>
  )
}

export default ConfigLoadingScreen
