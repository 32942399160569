import React from 'react'

import styles from './TableLoader.module.css'

const TableLoader = ({ rows = 5, columns = 4, showOptions = true }) => {
  const getRandomWidth = () => {
    const widths = ['60%', '70%', '80%', '90%']
    return widths[Math.floor(Math.random() * widths.length)]
  }

  return (
    <div className={styles.tableLoader}>
      {/* Header */}
      <div className={styles.tableLoaderHeader}>
        {Array(columns)
          .fill(null)
          .map((_, index) => (
            <div key={`header-${index}`} className={`${styles.tableLoaderCell} ${styles.header}`}>
              <div className={styles.skeletonLoading} style={{ width: '70%' }} />
            </div>
          ))}
        {showOptions && <div className={`${styles.tableLoaderCell} ${styles.header} ${styles.options}`} />}
      </div>

      {/* Body */}
      <div className={styles.tableLoaderBody}>
        {Array(rows)
          .fill(null)
          .map((_, rowIndex) => (
            <div key={`row-${rowIndex}`} className={styles.tableLoaderRow}>
              {Array(columns)
                .fill(null)
                .map((_, colIndex) => (
                  <div key={`cell-${rowIndex}-${colIndex}`} className={styles.tableLoaderCell}>
                    <div className={styles.skeletonLoading} style={{ width: getRandomWidth() }} />
                  </div>
                ))}
              {showOptions && (
                <div className={`${styles.tableLoaderCell} ${styles.options}`}>
                  <div className={styles.skeletonLoading} style={{ width: '24px' }} />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default TableLoader
