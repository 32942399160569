
import { Button, Table, TableOptions, TableOption } from '@happyfoxinc/web-components'
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg'
import styles from './SurveyManagementModule.module.css'

const CAMPAIGN_COLUMNS = [
  { key: 'name', label: 'Title' },
  { key: 'workspace_name', label: 'Workspace' },
  { key: 'survey_title', label: 'Survey' },
  { key: 'status', label: 'Status' },
  { key: 'distribution_schedule_type', label: 'Distribution Type' },
  { key: 'updated_at', label: 'Updated' }
];

const CampaignsContent = ({ 
  campaigns, 
  isCampaignsLoading, 
  handleCampaignClick, 
  handleDeleteCampaign, 
  handleCreateCampaign 
}) => {
  const renderCampaignCell = (campaign, header) => {
    switch (header.key) {
      case 'updated_at':
        return new Date(campaign[header.key]).toLocaleDateString();
      case 'survey':
        return campaign.survey?.title || '-';
      case 'workspace':
        return campaign.workspace?.name || '-';
      case 'distribution_type':
        return campaign[header.key]?.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase());
      case 'status':
        return campaign[header.key]?.toUpperCase();
      default:
        return campaign[header.key];
    }
  };

  if (isCampaignsLoading) {
    return <div className={styles.emptyState}>Loading...</div>
  }

  if (!campaigns?.campaigns?.length) {
    return (
      <div className={styles.ConfigurationContainer}>
        <h2 className={styles.NoConfigTitle}>No campaigns created yet</h2>
        <p className={styles.NoConfigDescription}>
          Create your first campaign to start collecting feedback from your users.
        </p>
        <Button className={styles.ButtonContainter} onClick={handleCreateCampaign}>
          Create Campaign
          <span className={styles.ArrowContainer}>
            <ArrowRightIcon height='1em' width='1em' />
          </span>
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <h2 className={styles.tableTitle}>Campaign List</h2>
        <button className={styles.addButton} onClick={handleCreateCampaign}>
          Create Campaign
          <span>+</span>
        </button>
      </div>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            {CAMPAIGN_COLUMNS.map(header => (
              <Table.ColumnHeaderCell key={header.key}>
                {header.label}
              </Table.ColumnHeaderCell>
            ))}
            <Table.ColumnHeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaigns.campaigns.map((campaign) => (
            <Table.Row 
              key={campaign.id} 
              clickable
            >
              {CAMPAIGN_COLUMNS.map(header => (
                <Table.Cell key={`${campaign.id}-${header.key}`} onClick={() => handleCampaignClick(campaign)}>
                  {renderCampaignCell(campaign, header)}
                </Table.Cell>
              ))}
              <TableOptions>
                <TableOption onClick={(e) => handleDeleteCampaign(e, campaign.id)}>Remove</TableOption>
              </TableOptions>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  );
};

export default CampaignsContent;