import cx from 'classnames'

import styles from './Tab.module.css'

const Tab = ({ label, isActive, onClick, className, isLoading, ...props }) => {
  if (isLoading) {
    return <div className={cx(styles.tabHeading, styles.skeleton)} role='tab' aria-selected={false} tabIndex={-1} />
  }

  return (
    <div
      className={cx(styles.tabHeading, { [styles.active]: isActive }, className)}
      onClick={onClick}
      role='tab'
      aria-selected={isActive}
      tabIndex={0}
      {...props}
    >
      {label}
    </div>
  )
}

export default Tab
