// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._Fkx47e3FnTJWfFsFQqN{font-size:24px;color:#1e2022;font-weight:400}.qRei3KRPGjmoAtbk27gi{padding:7px}.JGjUcwZ6gj4SOa3dWBTB{margin-top:20px}.XthNbFRsHvxRAreEI4Xh{background-color:#fff;border-radius:6px;margin-top:40px}.aWV1lLOWkyEfNfryRQbQ{border-radius:0px 0px 8px 8px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Answers/Answers.module.css"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,aAAA,CACA,eAAA,CAGF,sBACE,WAAA,CAGF,sBACE,eAAA,CAGF,sBACE,qBAAA,CACA,iBAAA,CACA,eAAA,CAGF,sBACE,6BAAA","sourcesContent":[".heading {\n  font-size: 24px;\n  color: #1E2022;\n  font-weight: 400;\n}\n\n.addButton {\n  padding: 7px;\n}\n\n.listContainer {\n  margin-top: 20px;\n}\n\n.formContainer {\n  background-color: #FFFFFF;\n  border-radius: 6px;\n  margin-top: 40px;\n}\n\n.modalBody {\n  border-radius: 0px 0px 8px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "_Fkx47e3FnTJWfFsFQqN",
	"addButton": "qRei3KRPGjmoAtbk27gi",
	"listContainer": "JGjUcwZ6gj4SOa3dWBTB",
	"formContainer": "XthNbFRsHvxRAreEI4Xh",
	"modalBody": "aWV1lLOWkyEfNfryRQbQ"
};
export default ___CSS_LOADER_EXPORT___;
