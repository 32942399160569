import { baseApi, TAGS } from './baseApi'

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCsrfToken: builder.query({
      query: () => 'v1/csrf-token'
    }),

    getProfile: builder.query({
      query: () => 'v1/profile',
      providesTags: [TAGS.PROFILE]
    }),

    getAccount: builder.query({
      query: () => 'v1/account',
      providesTags: [TAGS.ACCOUNT]
    })
  })
})

export const { useGetCsrfTokenQuery, useGetProfileQuery, useGetAccountQuery } = authApi
