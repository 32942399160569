
import React, { useState, Fragment } from 'react'
import cx from 'classnames'
import styles from '../ConfigModule.module.css'
import { CustomClose } from './UtilityComponents'

const ChoiceOption = ({ value, onRemove, onValueChange }) => (
  <div className={styles.optionRow}>
    <input
      type="text"
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
      className={styles.optionInput}
      placeholder="Add Choice"
    />
    <CustomClose onClick={onRemove} />
  </div>
)

export const ChoiceContent = ({ question, onChange }) => {
  const addOption = () => {
    const updatedOptions = [...question.options, '']
    onChange({ ...question, options: updatedOptions })
  }

  const removeOption = (index) => {
    const updatedOptions = question.options.filter((_, i) => i !== index)
    onChange({ ...question, options: updatedOptions })
  }

  const updateOption = (index, value) => {
    const updatedOptions = [...question.options]
    updatedOptions[index] = value
    onChange({ ...question, options: updatedOptions })
  }

  return (
    <Fragment>
      <input
        type="text"
        className={styles.questionInput}
        value={question.text || ''}
        placeholder={question.type === 'multi_choice' ? 'Add multiple choice question' : 'Add single choice question'}
        onChange={(e) => onChange({ ...question, text: e.target.value })}
      />
      <div className={styles.optionsContainer}>
        {question.options.map((option, index) => (
          <ChoiceOption
            key={index}
            value={option}
            onRemove={() => removeOption(index)}
            onValueChange={(value) => updateOption(index, value)}
          />
        ))}
        <button className={styles.addOptionButton} onClick={addOption}>+</button>
      </div>
    </Fragment>
  )
}

export const TextContent = ({ question, onChange }) => {
  const [isEditing, setIsEditing] = useState(false)
  
  return (
    <Fragment>
      <input
        type="text"
        className={styles.questionInput}
        value={question.text}
        placeholder='Add Question'
        onChange={(e) => onChange({ ...question, text: e.target.value })}
      />
      <input
        type="text"
        className={cx(styles.placeholderInput, { [styles.placeholderInputActive]: isEditing })}
        value={question.placeholder || ''}
        placeholder='Click to add placeholder text'
        onClick={() => setIsEditing(true)}
        onBlur={() => setIsEditing(false)}
        onChange={(e) => onChange({ ...question, placeholder: e.target.value })}
        readOnly={!isEditing}
      />
    </Fragment>
  )
}