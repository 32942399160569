import { Box, Button, Flex, Modal } from '@happyfoxinc/web-components'
import { Fragment, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import styles from './CompanyPolicyModule.module.css'

import ChevronRightIcon from 'Src/assetsv3/icons/chevron-right-white.svg'
import BackButton from 'Src/componentsv3/BackButton'
import Tab from 'Src/componentsv3/Tab'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useGetPoliciesQuery, useSavePoliciesMutation } from 'Src/servicesV3/companyPolicyModuleApi'

import CompanyPolicyList from './CompanyPolicyList'
import ConfigModule from './ConfigModule'

const CompanyPolicyModule = () => {
  const [isSetupStarted, setIsSetupStarted] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(0)
  const [policyData, setPolicyData] = useState([])

  const { data = {}, isLoading } = useGetPoliciesQuery({ page: 1 })
  const { data: policiesList = [] } = data || {}
  const [savePolicies] = useSavePoliciesMutation()

  const [view, setView] = useState(policiesList.length > 0 ? 'overview' : 'configuration')

  const handleConfigUpdate = (config) => {
    setPolicyData(config)
  }
  const handleStartSetup = () => {
    setIsSetupStarted((prev) => !prev)
    setCurrentScreen(0)
  }

  const transformPolicyData = (policies) => {
    return policies.map((policy) => ({
      policy_id: policy.policy_id,
      policy_name: policy.policy_name,
      file_id: policy.file_id,
      file_name: policy.file_name,
      source: policy.source,
      access_control: {
        isFullAccess: policy.accessConfig.isFullAccess,
        conditions: policy.accessConfig.conditions
      },
      ticket_creation: policy.ticket_creation
    }))
  }

  const handleNextScreen = async () => {
    if (currentScreen === 0) {
      if (policiesList.length === 0) {
        toast.error('Please create at least one policy type before proceeding')
        return
      }
    }

    if (currentScreen === 1) {
      if (policyData.length === 0) {
        toast.error('Please add at least one policy before proceeding')
        return
      }

      const policiesWithoutType = policyData.filter((policy) => !policy.policy_id || !policy.policy_name)

      if (policiesWithoutType.length > 0) {
        const missingTypeFiles = policiesWithoutType.map((p) => p.file_name).join(', ')
        toast.error(
          <div style={{ maxWidth: '500px' }}>
            <strong>Missing Policy Types</strong>
            <br />
            Please assign policy types for: {missingTypeFiles}
          </div>
        )
        return
      }
    }

    if (currentScreen === 2) {
      const unconfiguredPolicies = policyData.filter((policy) => !policy.accessConfig?.configured)

      if (unconfiguredPolicies.length === policyData.length) {
        toast.error('Please configure access control for at least one policy before proceeding')
        return
      }

      if (unconfiguredPolicies.length > 0) {
        const missingAccessPolicies = unconfiguredPolicies.map((p) => p.file_name).join(', ')

        toast(
          (t) => (
            <div style={{ maxWidth: '500px' }}>
              <div style={{ marginBottom: '10px' }}>
                The following policies will be skipped as they don't have access control configured:
                <br />
                {missingAccessPolicies}
              </div>
              <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <Button
                  variant='outline'
                  size='small'
                  onClick={() => {
                    toast.dismiss(t.id)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='solid'
                  size='small'
                  onClick={async () => {
                    toast.dismiss(t.id)
                    const configuredPolicies = policyData.filter((policy) => policy.accessConfig?.configured)
                    try {
                      const transformedData = transformPolicyData(configuredPolicies)
                      await savePolicies(transformedData).unwrap()
                      setIsSetupStarted(false)
                      setCurrentScreen(0)
                    } catch (error) {
                      toast.error('Failed to create policies. Please try again.')
                    }
                  }}
                >
                  Create Anyway
                </Button>
              </div>
            </div>
          ),
          {
            duration: 10000
          }
        )
        return
      }

      try {
        const transformedData = transformPolicyData(policyData)
        await savePolicies(transformedData).unwrap()
        setIsSetupStarted(false)
        setCurrentScreen(0)
      } catch (error) {
        toast.error('Failed to create policies. Please try again.')
      }
      return
    }

    if (currentScreen < 2) {
      setCurrentScreen((prev) => prev + 1)
    }
  }

  const handleCancelButton = () => {
    if (currentScreen > 0) {
      setCurrentScreen((prev) => prev - 1)
    } else {
      setIsSetupStarted(false)
    }
  }

  useEffect(() => {
    setView(policiesList.length > 0 ? 'overview' : 'configuration')
  }, [policiesList])

  return (
    <Fragment>
      <Modal
        size='full'
        open={isSetupStarted}
        onOpenChange={handleStartSetup}
        showProgressBar
        currentStep={currentScreen + 1}
        totalSteps={3}
        confirmText={currentScreen < 2 ? 'Proceed' : 'Finish'}
        cancelText={currentScreen > 0 ? 'Back' : 'Cancel'}
        onCancel={handleCancelButton}
        onConfirm={handleNextScreen}
        title='Configure Company Policy Requests'
      >
        <ConfigModule currentScreen={currentScreen} onConfigUpdate={handleConfigUpdate} />
      </Modal>

      <Box p='24px'>
        <div className={styles.header}>
          <Flex align='center' gap='4px' className={styles.headingContainer}>
            <BackButton />
            <h1 className={styles.heading}>Company Policy Requests</h1>
          </Flex>
          <Box width='65%'>
            <p className={styles.subheading}>
              Centralize and track requests related to company policies, making it easy to manage policy inquiries,
              updates, or approvals.
            </p>
          </Box>
        </div>

        <Flex className={styles.tabContainer} align='center' gap='20px'>
          {policiesList.length > 0 ? (
            <Tab label='Overview' isActive={view === 'overview'} onClick={() => setView('overview')} />
          ) : (
            <Tab label='Configuration' isActive={view === 'configuration'} onClick={() => setView('configuration')} />
          )}
          <Tab label='Reports' isActive={view === 'reports'} onClick={() => setView('reports')} />
        </Flex>

        {isLoading ? (
          <TableLoader rows={10} columns={4} showOptions />
        ) : policiesList.length > 0 ? (
          <Fragment>
            <Flex align='center' justify='space-between'>
              <h2 className={styles.policiesListTitle}>Policies</h2>
              <Button variant='solid' onClick={handleStartSetup}>
                Create Policy
              </Button>
            </Flex>
            <CompanyPolicyList />
          </Fragment>
        ) : (
          <Flex
            direction='column'
            width='100%'
            height='280px'
            align='center'
            justify='center'
            gap='25px'
            p='40px'
            className={styles.ConfigurationContainer}
          >
            <h2 className={styles.NoConfigTitle}>You haven’t configure this yet</h2>
            <Box width='450px'>
              <p className={styles.NoConfigDescription}>
                To get the module up and running, set up the necessary applications and tailor predefined responses.
              </p>
            </Box>
            <Button onClick={handleStartSetup}>
              Start Setup
              <ChevronRightIcon />
            </Button>
          </Flex>
        )}
      </Box>
    </Fragment>
  )
}

export default CompanyPolicyModule
