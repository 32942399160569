import cx from 'classnames'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'

import styles from './ReactSelect.module.css'

const NoRender = () => null

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' width='11' height='11'>
        <path
          fill='none'
          stroke='black'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
          transform='matrix(-0.707107 0.707107 -0.707107 -0.707107 9.19421 4.95115)'
          d='M0 0L5.9747477 0.025252525L6 6'
          fillRule='evenodd'
        />
      </svg>
    </components.DropdownIndicator>
  )
}

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <svg width='13' height='12' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill='none'
          stroke='currentColor'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
          transform='matrix(0.707107 0.707107 -0.707107 0.707107 3.53552 2.82841)'
          d='M0 0.5L7 0.5'
        />
        <path
          fill='none'
          stroke='currentColor'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
          transform='matrix(0.707107 0.707107 -0.707107 0.707107 7.77816 2.82841)'
          d='M0.5 0L0.5 7'
        />
      </svg>
    </components.MultiValueRemove>
  )
}

const Control = ({ children, icon: Icon, showSeparator, ...props }) => {
  return (
    <components.Control {...props}>
      {Icon && (
        <div className={styles.iconContainer}>
          {typeof Icon === 'string' ? <img src={Icon} alt='' className={styles.iconImg} /> : <Icon size={16} />}
          {showSeparator && <div className={styles.separator} />}
        </div>
      )}
      {children}
    </components.Control>
  )
}

const ReactSelect = forwardRef((props, ref) => {
  const {
    className,
    creatable = false,
    minCharsBeforeLoadingOptions = 0,
    loadOptions: inputLoadOptions,
    inDialog = false,
    isDisabled,
    isMulti = false,
    closeMenuOnSelect = false,
    icon,
    showSeparator = false,
    modalContainerClassName = 'document.body',
    menuPortalStyles = {},
    ...rest
  } = props

  const [menuPortalTarget, setMenuPortalTarget] = useState(null)

  const components = useMemo(() => {
    const selectComponents = {
      ...rest.components,
      IndicatorSeparator: null,
      DropdownIndicator,
      MultiValueRemove,
      Control: (controlProps) => <Control {...controlProps} icon={icon} showSeparator={showSeparator} />
    }

    Object.entries(selectComponents).forEach(([key, value]) => {
      if (!value) {
        selectComponents[key] = NoRender
      }
    })

    return selectComponents
  }, [rest.components, icon, showSeparator])

  const loadOptions = useMemo(
    () => (inputValue, callback) => {
      if (inputValue.length < minCharsBeforeLoadingOptions || typeof loadOptions !== 'function') {
        return callback(null)
      }

      return inputLoadOptions(inputValue, callback)
    },
    [minCharsBeforeLoadingOptions, inputLoadOptions]
  )

  const canCloseMenuOnSelect = useMemo(() => closeMenuOnSelect || !isMulti, [closeMenuOnSelect, isMulti])

  useEffect(() => {
    if (inDialog) {
      const modalContainer = document.querySelector(`.${modalContainerClassName}`)
      if (modalContainer) {
        setMenuPortalTarget(modalContainer)
      }
    }
  }, [inDialog, modalContainerClassName])

  const commonProps = {
    ...rest,
    closeMenuOnSelect: canCloseMenuOnSelect,
    isMulti,
    className: cx(styles.Select, className),
    classNamePrefix: 'ReactSelectExtended',
    ref,
    components,
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#F6F6F6',
        primary25: '#F6F6F6'
      }
    }),
    ...(inDialog && menuPortalTarget ? { menuPortalTarget, menuPosition: 'fixed' } : {}),
    styles: {
      ...rest.styles,
      control: (provided) => ({
        ...provided,
        boxShadow: 'none',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        border: '1px solid rgba(199, 199, 199, 1)',
        minHeight: '25px',
        ...(rest.styles?.control ? rest.styles.control(provided) : {})
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontFamily: 'Inter'
      }),
      inputContainer: (provided) => ({
        ...provided,
        padding: '0',
        margin: '0',
        fontSize: '13px',
        fontFamily: 'Inter'
      }),
      input: (provided) => ({
        ...provided,
        margin: '0',
        padding: '0',
        fontSize: '13px',
        fontFamily: 'Inter'
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#15171A',
        opacity: '0.4',
        fontSize: '13px',
        fontFamily: 'Inter'
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#FFFFFF',
        border: '1px solid rgba(199, 199, 199, 1)',
        borderRadius: '4px',
        ...(rest.styles?.multiValue ? rest.styles.multiValue(provided) : {})
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0 4px'
      }),
      menu: (provided) => ({
        ...provided,
        marginTop: '4px',
        borderRadius: '6px',
        border: '1px solid #e6e6e6',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.06)',
        padding: '4px'
      }),
      menuPortal: (provided) => ({
        ...provided,
        ...(inDialog ? { zIndex: 999, ...menuPortalStyles } : {})
      }),
      option: (provided) => ({
        ...provided,
        padding: '6px',
        fontSize: '13px',
        fontFamily: 'Inter',
        color: '#000000',
        borderRadius: '6px'
      })
    }
  }

  if (inputLoadOptions) {
    return <AsyncSelect {...commonProps} loadOptions={loadOptions} />
  }

  if (creatable) {
    return <CreatableSelect {...commonProps} />
  }

  return <Select {...commonProps} />
})

ReactSelect.displayName = 'Select'

export default ReactSelect
