import baseApi, { TAGS } from './baseApi'

export const servicedeskAppApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getServicedeskApp: builder.query({
      query: (params) => ({
        url: 'v1/apps/happyfox-service-desk',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.SERVICDESK_APP]
    }),

    authorizeServicedeskApp: builder.mutation({
      query: (data) => ({
        url: 'v1/apps/happyfox-service-desk/authorize',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.SERVICDESK_APP]
    }),

    updateServicedeskApp: builder.mutation({
      query: (data) => ({
        url: 'v1/apps/happyfox-service-desk',
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.SERVICDESK_APP]
    }),

    uninstallServicedeskApp: builder.mutation({
      query: (params) => ({
        url: 'v1/apps/happyfox-service-desk',
        method: 'DELETE',
        params
      }),
      invalidatesTags: [TAGS.SERVICDESK_APP]
    }),

    enableServicedeskSync: builder.mutation({
      query: (data) => ({
        url: 'v1/apps/happyfox-service-desk/sync/enable',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.SERVICDESK_APP]
    }),

    getServicedeskMetadata: builder.query({
      query: (params) => ({
        url: 'v1/apps/happyfox-service-desk/metadata',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.SERVICDESK_APP]
    })
  })
})

export const {
  useGetServicedeskAppQuery,
  useAuthorizeServicedeskAppMutation,
  useUpdateServicedeskAppMutation,
  useUninstallServicedeskAppMutation,
  useEnableServicedeskSyncMutation,
  useGetServicedeskMetadataQuery
} = servicedeskAppApi
