import { Box } from '@happyfoxinc/web-components'

import styles from './FormField.module.css'

import Field from './Field'

const FormField = ({ children, className = '' }) => {
  return <Box className={`${styles.formField} ${className}`}>{children}</Box>
}

FormField.Field = Field

export default FormField
