// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uy4S2A20H5ch8pmEhrOj{margin-bottom:20px}.y32S0s7SientPNn08Ofd{margin-bottom:20px}.y32S0s7SientPNn08Ofd:last-child{margin-bottom:0}.u_5XQry6sAiLWSOhYvGb{font-size:13px;color:#15171a;margin-bottom:8px}.c3AvoEspyMhEfYHwKeS0{color:rgba(255,90,90,.968627451);font-size:12px;margin-top:4px;display:block}", "",{"version":3,"sources":["webpack://./../src/componentsv3/FormField/FormField.module.css"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAGF,iCACE,eAAA,CAGF,sBACE,cAAA,CACA,aAAA,CACA,iBAAA,CAGF,sBACE,gCAAA,CACA,cAAA,CACA,cAAA,CACA,aAAA","sourcesContent":[".formField {\n  margin-bottom: 20px;\n}\n\n.field {\n  margin-bottom: 20px;\n}\n\n.field:last-child {\n  margin-bottom: 0;\n}\n\n.label {\n  font-size: 13px;\n  color: #15171A;\n  margin-bottom: 8px;\n}\n\n.error {\n  color: #ff5a5af7;\n  font-size: 12px;\n  margin-top: 4px;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formField": "Uy4S2A20H5ch8pmEhrOj",
	"field": "y32S0s7SientPNn08Ofd",
	"label": "u_5XQry6sAiLWSOhYvGb",
	"error": "c3AvoEspyMhEfYHwKeS0"
};
export default ___CSS_LOADER_EXPORT___;
