import baseApi, { TAGS } from './baseApi'

export const workspaceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkspaces: builder.query({
      query: (params) => ({
        url: 'v1/workspaces',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.WORKSPACES]
    }),

    createWorkspace: builder.mutation({
      query: (data) => ({
        url: 'v1/workspaces',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.WORKSPACES]
    }),

    updateWorkspace: builder.mutation({
      query: ({ workspaceId, data }) => ({
        url: `v1/workspaces/${workspaceId}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.WORKSPACES]
    }),

    deleteWorkspace: builder.mutation({
      query: (workspaceId) => ({
        url: `v1/workspaces/${workspaceId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.WORKSPACES]
    })
  })
})

export const {
  useGetWorkspacesQuery,
  useCreateWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useDeleteWorkspaceMutation
} = workspaceApi
