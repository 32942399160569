import { Box, Flex } from '@happyfoxinc/web-components'

import styles from './ConfigModule.module.css'

const LeftPaneSkeleton = () => {
  return (
    <div>
      {/* Section Head */}
      <Flex direction='column' gap='20px' className={styles.sectionHeadContainer}>
        <div className={`${styles.skeletonTitle} ${styles.skeletonShimmer}`} />
        <div className={`${styles.skeletonDescription} ${styles.skeletonShimmer}`} />
      </Flex>

      {/* Skeleton Cards */}
      <div className={styles.skeletonCard}>
        <Flex align='center' gap='10px'>
          <div className={`${styles.skeletonIcon} ${styles.skeletonShimmer}`} />
          <div>
            <div className={`${styles.skeletonBlock} ${styles.skeletonShimmer}`} style={{ width: '200px' }} />
            <div
              className={`${styles.skeletonBlock} ${styles.skeletonShimmer}`}
              style={{ width: '300px', marginTop: '8px', opacity: 0.6 }}
            />
          </div>
        </Flex>
      </div>

      <div className={styles.skeletonCard}>
        <Flex align='center' gap='10px'>
          <div className={`${styles.skeletonIcon} ${styles.skeletonShimmer}`} />
          <div>
            <div className={`${styles.skeletonBlock} ${styles.skeletonShimmer}`} style={{ width: '180px' }} />
            <div
              className={`${styles.skeletonBlock} ${styles.skeletonShimmer}`}
              style={{ width: '280px', marginTop: '8px', opacity: 0.6 }}
            />
          </div>
        </Flex>
      </div>
    </div>
  )
}

const RightPaneSkeleton = () => {
  return (
    <div className={styles.configurationPanel}>
      <div className={styles.titleContainer}>
        <div className={`${styles.skeletonIcon} ${styles.skeletonShimmer}`} style={{ width: '34px', height: '34px' }} />
        <div className={`${styles.skeletonTitle} ${styles.skeletonShimmer}`} />
      </div>

      <div className={styles.tableWrapper}>
        {[1, 2, 3, 4].map((index) => (
          <div key={index} style={{ marginBottom: '16px' }}>
            <div className={`${styles.loadingCell} ${styles.skeletonShimmer}`} />
          </div>
        ))}
      </div>
    </div>
  )
}

const ConfigLoadingScreen = () => {
  return (
    <Flex gap='24px' height='calc(100vh - 200px)' overflow='hidden'>
      <Box width='50%' overflowY='auto' p='3rem 3rem 3rem 4rem'>
        <LeftPaneSkeleton />
      </Box>
      <RightPaneSkeleton />
    </Flex>
  )
}

export default ConfigLoadingScreen
