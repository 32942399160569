// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wV0f1FZi_LfyPbiiLIdl{background:none;border:none;cursor:pointer;padding:8px;display:flex;align-items:center;transition:all .3s ease}.xwTl9hSc8JSVwmhBRsDh{display:flex;align-items:center;position:relative}.xwTl9hSc8JSVwmhBRsDh::after{content:\"\";position:absolute;width:0;height:2px;bottom:-4px;left:0;background-color:currentColor;transition:width .3s ease}.xwTl9hSc8JSVwmhBRsDh:hover::after{width:100%}", "",{"version":3,"sources":["webpack://./../src/componentsv3/BackButton/BackButton.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,uBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CAGF,6BACE,UAAA,CACA,iBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,MAAA,CACA,6BAAA,CACA,yBAAA,CAGF,mCACE,UAAA","sourcesContent":[".backButton {\n  background: none;\n  border: none;\n  cursor: pointer;\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  transition: all 0.3s ease;\n}\n\n.backArrow {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.backArrow::after {\n  content: '';\n  position: absolute;\n  width: 0;\n  height: 2px;\n  bottom: -4px;\n  left: 0;\n  background-color: currentColor;\n  transition: width 0.3s ease;\n}\n\n.backArrow:hover::after {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "wV0f1FZi_LfyPbiiLIdl",
	"backArrow": "xwTl9hSc8JSVwmhBRsDh"
};
export default ___CSS_LOADER_EXPORT___;
