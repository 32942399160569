const importAllServices = (r) => {
  const reducers = {}
  const middlewares = []

  r.keys().forEach((key) => {
    const api = r(key).default

    if (!api || !api.reducerPath) {
      return
    }

    reducers[api.reducerPath] = api.reducer
    middlewares.push(api.middleware)
  })

  return {
    reducers,
    middlewares
  }
}

export const getServices = () => {
  const allReducers = {}
  const allMiddlewares = []

  const v2Services = importAllServices(require.context('./services', true, /\.js$/))
  Object.assign(allReducers, v2Services.reducers)
  allMiddlewares.push(...v2Services.middlewares)

  const v3Services = importAllServices(require.context('./servicesV3', true, /\.js$/))
  Object.assign(allReducers, v3Services.reducers)
  allMiddlewares.push(...v3Services.middlewares)

  return {
    reducers: allReducers,
    middlewares: allMiddlewares
  }
}

export default {
  getServices
}
