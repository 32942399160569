import { Route, Routes } from 'react-router-dom'

import AnswerCreate from './AnswerCreate'
import AnswerEdit from './AnswerEdit'
import Answers from './Answers'

const AnswersRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Answers />} />
      <Route path='create' element={<AnswerCreate />} />
      <Route path=':id' index element={<AnswerEdit />} />
    </Routes>
  )
}

export default AnswersRouter
