import React from 'react'
import { components } from 'react-select'

import styles from './WorkspaceDropdown.module.css'

import WorkspaceIcon from 'Icons/workspace-inverted.svg'

import { useWorkspace } from 'Src/utils/hooks/useWorkspace'

import ReactSelect from '../ReactSelect'

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <WorkspaceIcon className={styles.Icon} />
      {children}
    </components.Control>
  )
}

const WorkspaceDropdown = (props) => {
  const { workspaces, currentWorkspaceId, setCurrentWorkspaceId } = useWorkspace()

  const handleWorkspaceChange = (selectedOption) => {
    if (selectedOption) {
      setCurrentWorkspaceId(selectedOption.id)
    }
  }

  const currentWorkspace = workspaces.find((workspace) => workspace.id === currentWorkspaceId)

  const customStyles = {
    container: (base) => ({
      ...base,
      width: '210px'
    }),
    control: (base) => ({
      ...base,
      borderColor: '#E3E3E3',
      height: '36px',
      minHeight: '36px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px'
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0 8px 0 0'
    }),
    singleValue: (base) => ({
      ...base,
      marginLeft: '22px',
      maxWidth: 'calc(100% - 22px)'
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '100%'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '0 8px'
    })
  }

  const reactSelectProps = {
    isSearchable: false,
    styles: customStyles,
    value: currentWorkspace || null,
    onChange: handleWorkspaceChange,
    options: workspaces,
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id,
    components: { Control },
    ...props
  }

  return <ReactSelect {...reactSelectProps} />
}

export default WorkspaceDropdown
