const test = (text) => {
  const doc = document.createElement('div')
  doc.innerHTML = text

  const getTextWithSpaces = (node) => {
    let text = ''
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        text += child.textContent + ' '
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        text += getTextWithSpaces(child) + ' '
      }
    })
    return text.trim()
  }

  const fullText = getTextWithSpaces(doc)
  const wordCount = fullText.split(/\s+/).length
  return wordCount >= 20
}

const message = 'Content should contain at least 20 words'

const contentMinimumLength = {
  name: 'content-minimum-length',
  message,
  test
}

export default contentMinimumLength
