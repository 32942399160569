import { Button, Flex, Grid } from '@happyfoxinc/web-components'
import cx from 'classnames'

import styles from './Dashboard.module.css'

const Dashboard = () => {
  const coverageQuestions = [
    'How do i setup my VPN?',
    'How do i free up more space in my Google Drive?',
    'How do i setup?',
    'How do i free up more space in my Google Drive?'
  ]
  return (
    <Flex direction='column' align='center' className={styles.container} width='705px'>
      <h2 className={styles.greeting}>Good Morning John!</h2>

      <div className={cx(styles.card, styles.topCard)}>
        <div className={styles.newLabel}>New</div>
        <h2 className={styles.updateTitle}>Check Out What's New in Assist AI! 🚀 - Sep 12, 2024</h2>
        <div>
          <p className={styles.updateText}>
            We are excited to announce the following updates available in the latest production release:
          </p>
          <p className={styles.updateText}>
            New Features: Lorem ipsum dolor sit amet, consectetur adipis cing elit nec tempus ligula.
          </p>
        </div>
        <Button variant='soft' className={styles.readMore}>
          Read More
        </Button>
      </div>

      <Grid width='100%' columns='300px 1fr' gap='16px'>
        <div className={styles.card}>
          <div className={styles.textContainer}>
            <h2 className={styles.cardTitle}>Overall Auto Resolution Rate</h2>
            <p className={styles.resolutionSubtitle}>Automated Resolution Rate</p>
          </div>

          <div className={styles.circleContainer}>
            <div className={styles.circleOuter}>
              <div className={styles.circleInner}>
                <span className={styles.percentage}>
                  60<span className={styles.symbol}>%</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={cx(styles.card, styles.questionCard)}>
          <h3 className={styles.cardTitle}>Coverage Opportunities</h3>
          <Flex direction='column' p='10px 0'>
            {coverageQuestions.map((question, index) => (
              <div key={index} className={styles.questionItem}>
                {question}
              </div>
            ))}
          </Flex>
        </div>

        <div className={cx(styles.card, styles.statsCard)}>
          <h3 className={styles.statsCardTitle}>Total Active Users</h3>
          <span className={styles.statsNumber}>15</span>
        </div>

        <div className={cx(styles.card, styles.statsCard)}>
          <h3 className={styles.statsCardTitle}>Total Request Resolved by AI Assistant</h3>
          <span className={styles.statsNumber}>20</span>
        </div>
      </Grid>
    </Flex>
  )
}

export default Dashboard
