import baseApi, { TAGS } from './baseApi'

export const answersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnswers: builder.query({
      query: (params) => ({
        url: 'v1/answers',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.ANSWERS]
    }),

    getAnswer: builder.query({
      query: (answerId) => ({
        url: `v1/answers/${answerId}`,
        method: 'GET'
      }),
      providesTags: [TAGS.ANSWERS]
    }),

    addAnswer: builder.mutation({
      query: (data) => ({
        url: 'v1/answers',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.ANSWERS]
    }),

    updateAnswer: builder.mutation({
      query: ({ answerId, ...data }) => ({
        url: `v1/answers/${answerId}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.ANSWERS]
    }),

    deleteAnswer: builder.mutation({
      query: (answerId) => ({
        url: `v1/answers/${answerId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.ANSWERS]
    })
  })
})

export const {
  useGetAnswersQuery,
  useGetAnswerQuery,
  useAddAnswerMutation,
  useUpdateAnswerMutation,
  useDeleteAnswerMutation
} = answersApi
