import { Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTable } from 'react-table'

const SoftwareAccessAppsList = ({ data = [] }) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Associated App',
        accessor: 'associated_app'
      },
      {
        Header: 'Access Type',
        accessor: 'access_control'
      },
      {
        Header: 'Ticket Creation',
        accessor: 'ticket_creation'
      },
      {
        Header: 'Updated On',
        accessor: 'updatedOn',
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format('MMM D, YYYY h:mm A')
        }
      }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data
  })

  return (
    <Table.Root {...getTableProps()}>
      <Table.Header>
        {headerGroups.map((headerGroup) => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
          return (
            <Table.Row key={key} {...restHeaderGroupProps}>
              {headerGroup.headers.map((column) => {
                const { key, ...restColumnProps } = column.getHeaderProps()
                return (
                  <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                    {column.render('Header')}
                  </Table.ColumnHeaderCell>
                )
              })}
              <Table.ColumnHeaderCell isOption />
            </Table.Row>
          )
        })}
      </Table.Header>

      <Table.Body {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          const { key, ...restRowProps } = row.getRowProps()
          return (
            <Table.Row key={key} {...restRowProps}>
              {row.cells.map((cell) => {
                const { key, ...restCellProps } = cell.getCellProps()
                return (
                  <Table.Cell key={key} {...restCellProps}>
                    {cell.render('Cell')}
                  </Table.Cell>
                )
              })}
              <TableOptions>
                <TableOption>Delete</TableOption>
              </TableOptions>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table.Root>
  )
}

export default SoftwareAccessAppsList
