import baseApi, { TAGS } from './baseApi'

export const helpdeskAppApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getHelpdeskApp: builder.query({
      query: (params) => ({
        url: 'v1/apps/happyfox-helpdesk',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.HELPDESK_APP]
    }),

    authorizeHelpdeskApp: builder.mutation({
      query: (data) => ({
        url: 'v1/apps/happyfox-helpdesk/authorize',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.HELPDESK_APP]
    }),

    updateHelpdeskApp: builder.mutation({
      query: (data) => ({
        url: 'v1/apps/happyfox-helpdesk',
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.HELPDESK_APP]
    }),

    uninstallHelpdeskApp: builder.mutation({
      query: (params) => ({
        url: 'v1/apps/happyfox-helpdesk',
        method: 'DELETE',
        params
      }),
      invalidatesTags: [TAGS.HELPDESK_APP]
    }),

    enableHelpdeskSync: builder.mutation({
      query: (data) => ({
        url: 'v1/apps/happyfox-helpdesk/sync/enable',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.HELPDESK_APP]
    }),

    getHelpdeskEmployeeNotifications: builder.query({
      query: (params) => ({
        url: 'v1/apps/happyfox-helpdesk/requester/notifications',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.HELPDESK_APP_EMPLOYEE_NOTIFICATIONS]
    }),

    updateHelpdeskEmployeeNotifications: builder.mutation({
      query: (data) => ({
        url: 'v1/apps/happyfox-helpdesk/requester/notifications',
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.HELPDESK_APP_EMPLOYEE_NOTIFICATIONS]
    }),

    getHelpdeskMetaInfo: builder.query({
      query: (params) => ({
        url: 'v1/apps/happyfox-helpdesk/metadata',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.HELPDESK_APP]
    })
  })
})

export const {
  useGetHelpdeskAppQuery,
  useAuthorizeHelpdeskAppMutation,
  useUpdateHelpdeskAppMutation,
  useUninstallHelpdeskAppMutation,
  useEnableHelpdeskSyncMutation,
  useGetHelpdeskEmployeeNotificationsQuery,
  useUpdateHelpdeskEmployeeNotificationsMutation,
  useGetHelpdeskMetaInfoQuery
} = helpdeskAppApi
