// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J52BLAIJCV9AVSELEi4e{margin-bottom:20px;width:75%}.JkCoS7o7egAwzva16q1Q{font-size:24px;color:#111827;margin-bottom:16px;font-weight:400}.qWhEhKXaO4gXXa6aDlbT{color:rgba(21,23,26,.82);font-size:13px;line-height:20px;margin:15px 0}.IRUV9ZQq_jaaINvpMdk7{border-radius:0px 0px 8px 8px}.SST__IwvS4hiwbFVFqbb{opacity:.5;font-size:10px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Channels/Channels.module.css"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,SAAA,CAGF,sBACE,cAAA,CACA,aAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,wBAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CAGF,sBACE,6BAAA,CAGF,sBACE,UAAA,CACA,cAAA","sourcesContent":[".header {\n  margin-bottom: 20px;\n  width: 75%;\n}\n\n.heading {\n  font-size: 24px;\n  color: #111827;\n  margin-bottom: 16px;\n  font-weight: 400;\n}\n\n.subheading {\n  color: rgba(21, 23, 26, 0.82);\n  font-size: 13px;\n  line-height: 20px;\n  margin: 15px 0;\n}\n\n.modalBody {\n  border-radius: 0px 0px 8px 8px;\n}\n\n.optionSubMenu{\n  opacity: 0.5;\n  font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "J52BLAIJCV9AVSELEi4e",
	"heading": "JkCoS7o7egAwzva16q1Q",
	"subheading": "qWhEhKXaO4gXXa6aDlbT",
	"modalBody": "IRUV9ZQq_jaaINvpMdk7",
	"optionSubMenu": "SST__IwvS4hiwbFVFqbb"
};
export default ___CSS_LOADER_EXPORT___;
