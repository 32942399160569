import { Box, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { Fragment, useMemo, useState } from 'react'
import { useTable } from 'react-table'

import styles from './Channels.module.css'

import TableLoader from 'Src/componentsv3/TableLoader'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useGetSlackChannelsQuery } from 'Src/servicesV3/channelsApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { getModeCellValue } from './channels-helper'
import EditChannel from './EditChannel'

const ChannelsList = () => {
  const { workspaces } = useWorkspace()

  const [channelToEdit, setChannelToEdit] = useState(null)

  const { data: accountApiResponse } = useGetAccountQuery()
  const { data: channelsApiResponse = {}, isLoading: isChannelApiLoading } = useGetSlackChannelsQuery()

  const isTicketingConfigured = accountApiResponse?.is_ticketing_integration_configured
  const isWorkspacesEnabled = accountApiResponse?.is_workspaces_enabled
  const { results: channels = [] } = channelsApiResponse

  const canEditChannel = isWorkspacesEnabled || isTicketingConfigured

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Channel Name',
        accessor: 'name'
      },
      {
        Header: 'Show ticket info publicly',
        accessor: 'sync_to_channel_thread',
        Cell: ({ cell: { value } }) => (value ? 'Yes' : 'No')
      },
      {
        Header: 'Mode',
        accessor: (row) => row,
        Cell: ({ cell: { value } }) => getModeCellValue(value)
      }
    ]

    if (isWorkspacesEnabled) {
      baseColumns.splice(1, 0, {
        Header: 'Workspace',
        accessor: 'workspace_id',
        Cell: ({ value }) => {
          const workspace = workspaces.find((w) => w.id === value)
          return workspace ? workspace.name : 'N/A'
        }
      })
    }

    return baseColumns
  }, [isWorkspacesEnabled, workspaces])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: channels
  })

  const handleEditClick = (channel) => {
    setChannelToEdit(channel)
  }

  if (isChannelApiLoading) {
    return <TableLoader rows={5} columns={4} showOptions={false} />
  }

  return (
    <Fragment>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...restRowProps } = row.getRowProps()
            return (
              <Table.Row key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps()
                  return (
                    <Table.Cell key={key} {...restCellProps}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  )
                })}
                {canEditChannel ? (
                  <TableOptions>
                    <TableOption onClick={() => handleEditClick(row.original)}>Edit</TableOption>
                  </TableOptions>
                ) : (
                  <Table.Cell />
                )}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
      {channelToEdit && (
        <EditChannel
          channelData={channelToEdit}
          isOpen={Boolean(channelToEdit)}
          onClose={() => setChannelToEdit(null)}
        />
      )}
    </Fragment>
  )
}

const Channels = () => {
  return (
    <Box p='24px'>
      <div className={styles.header}>
        <h1 className={styles.heading}>Channels</h1>
        <p className={styles.subheading}>
          Slack Channels to which Assist AI is added are listed below. Assist AI can now respond to the queries posted
          on these channels. Learn more about channel configuration here Quick Tip for adding Assist AI to Slack
          channels.
        </p>
      </div>
      <ChannelsList />
    </Box>
  )
}

export default Channels
