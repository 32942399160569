import React, { useState, useEffect, Fragment } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { isValid, toISOString, localDateFromUTC } from 'Utils/date';
import styles from './CampaignConfiguration.module.css'
import { ACCOUNT_TYPE } from 'Constants/account'
import { TextField } from '@happyfoxinc/web-components'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import ChevronRight from 'Src/assetsv3/icons/white-chevron-right.svg'
import { useGetSurveysQuery } from 'Src/servicesV3/surveyApi';
import { useGetAccountQuery } from 'Src/servicesV3/authApi'

import { useGetWorkspacesQuery } from 'Src/servicesV3/workspaceApi';
import { useGetUserGroupsQuery, useGetSlackUsersQuery, useGetMsTeamsUsersQuery } from 'Src/servicesV3/userGroupsApi';

const formatDateForInput = (isoString) => {
  if (!isoString) return '';
  try {
    if (!isValid(isoString)) {
      console.error('Invalid date string:', isoString);
      return '';
    }
    const date = new Date(isoString);
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      .toISOString()
      .slice(0, 16);
  } catch (error) {
    console.error('Date conversion error:', error);
    return '';
  }
};

const CampaignConfiguration = ({
  campaignData,
  setCampaignData,
  editingCampaign,
  isLoading,
  selectedCampaign
}) => {
  const [activeTab, setActiveTab] = useState('create')
  const { data: surveys } = useGetSurveysQuery()
  const { data: workspaces } = useGetWorkspacesQuery({ minimal: true })
  const { data: userGroupsData, isLoading: isUserGroupLoading } = useGetUserGroupsQuery()
  const [userGroupOptions, setUserGroupOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const { data: account } = useGetAccountQuery()
  const { data: users } = (account.account_type === ACCOUNT_TYPE.SLACK) ? useGetSlackUsersQuery(searchTerm) : useGetMsTeamsUsersQuery(searchTerm)
  const [selectedUserOptions, setSelectedUserOptions] = useState([])

  const scheduleTypeOptions = [
    { value: 'immediately', label: 'Immediately' },
    { value: 'scheduled', label: 'Scheduled' },
    { value: 'recurring', label: 'Recurring' }
  ]

  const repeatOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'yearly', label: 'Yearly' }
  ]

  const distributionTypeOptions = [
    { value: 'all_users', label: 'All Users' },
    { value: 'specific_user_groups', label: 'Specific User Group(s)' },
    { value: 'specific_user_emails', label: 'Specific User Email(s)' }
  ]

  const surveyOptions = surveys?.surveys?.map(survey => ({
    value: survey.id,
    label: survey.title
  })) || []

  const workspaceOptions = workspaces?.results || []

  useEffect(() => {
    if (userGroupsData?.results) {
      const options = userGroupsData.results.map((group) => ({
        value: group.id,
        label: group.name
      }))
      setUserGroupOptions(options)
    }
  }, [userGroupsData])

  useEffect(() => {
    if (users?.length > 0) {
      const options = users.map(user => ({
        label: `${user.name} (${user.email})`,
        value: user.email,
      }))
      
      setUserOptions(prevOptions => {
        const newOptions = options.filter(option => 
          !selectedUserOptions.some(selected => selected.value === option.value)
        )
        return [...selectedUserOptions, ...newOptions]
      })
    }
  }, [users, selectedUserOptions])

  const { control, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: campaignData?.name || '',
      workspaceId: campaignData?.workspaceId || '',
      description: campaignData?.description || '',
      type: campaignData?.type || '',
      scheduledAt: campaignData?.scheduledAt ? formatDateForInput(campaignData.scheduledAt) : '',
      repeat: campaignData?.repeat || '',
      surveyId: campaignData?.surveyId || '',
      isAnonymous: campaignData?.isAnonymous || false,
      userEmails: campaignData?.userEmails || [],
      userGroups: campaignData?.userGroups || [],
      distributionType: campaignData?.distributionType || ''
    }
  })

  const formValues = watch()
  const campaignType = watch('type')

  useEffect(() => {
    if (setCampaignData && typeof setCampaignData === 'function') {
      setCampaignData(formValues)
    }
  }, [formValues, setCampaignData])

  useEffect(() => {
    if (selectedCampaign?.campaign && editingCampaign) {
      const campaign = selectedCampaign.campaign
      const distributionDetails = campaign.properties?.distribution_details || {}
      
      const formData = {
        name: campaign.name,
        workspaceId: campaign.workspace_id,
        description: campaign.description,
        type: campaign.distribution_schedule_type,
        scheduledAt: formatDateForInput(distributionDetails.scheduled_at),
        repeat: distributionDetails.repeat,
        surveyId: campaign.survey_id,
        isAnonymous: campaign.properties?.is_anonymous,
        userEmails: distributionDetails.distribute_to?.user_email_ids || [],
        userGroups: distributionDetails.distribute_to?.user_group_ids || [],
        distributionType: distributionDetails.distribute_to?.user_email_ids?.length > 0
          ? 'specific_user_emails'
          : 'specific_user_groups'
      }
      
      reset(formData)
    }
  }, [selectedCampaign, editingCampaign, reset])

  const handleDistributionChange = (type) => {
    reset({
      ...formValues,
      distributionType: type,
      userEmails: [],
      userGroups: []
    })
  }


  const renderProperties = () => (
    <div className={styles.propertiesContainer}>
      <Fragment>
        <div className={styles.formGroup}>
          <label>Campaign Name *</label>
          <Controller
            name='name'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField.Root
                  {...field}
                  size='1'
                  radius='small'
                  placeholder='Enter campaign name'
                />
            )}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Workspace *</label>
          <Controller
            name='workspaceId'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={workspaceOptions}
                placeholder="Select a workspace"
                value={workspaceOptions.find(option => option.value === field.value)}
                onChange={(option) => field.onChange(option.value)}
              />
            )}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Description *</label>
          <Controller
            name='description'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <textarea
                {...field}
                placeholder='Enter campaign description'
                className={styles.textarea}
              />
            )}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Survey *</label>
          <Controller
            name='surveyId'
            control={control}
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={surveyOptions}
                placeholder="Select a survey"
                value={surveyOptions.find(option => option.value === field.value)}
                onChange={(option) => field.onChange(option.value)}
              />
            )}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Distribution Schedule Type *</label>
          <Controller
            name='type'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={scheduleTypeOptions}
                placeholder="Select schedule type"
                value={scheduleTypeOptions.find(option => option.value === field.value)}
                onChange={(option) => field.onChange(option.value)}
              />
            )}
          />
        </div>
        {campaignType !== 'immediately' && (
          <div className={styles.formGroup}>
            <label>Scheduled At *</label>
            <Controller
              name='scheduledAt'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <input
                  {...field}
                  type='datetime-local'
                  className={styles.dateTimePicker}
                />
              )}
            />
          </div>
        )}

        {campaignType === 'recurring' && (
          <div className={styles.formGroup}>
            <label>Repeat *</label>
            <Controller
              name='repeat'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  options={repeatOptions}
                  placeholder="Select repeat interval"
                  value={repeatOptions.find(option => option.value === field.value)}
                  onChange={(option) => field.onChange(option.value)}
                />
              )}
            />
          </div>
        )}

        <div className={styles.formGroup}>
          <label>Distribute To *</label>
          <Controller
            name="distributionType"
            control={control}
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={distributionTypeOptions}
                placeholder="Select distribution type"
                value={distributionTypeOptions.find(option => option.value === field.value)}
                onChange={(option) => handleDistributionChange(option.value)}
              />
            )}
          />
        </div>

        {formValues.distributionType === 'specific_user_emails' && (
          <div className={styles.formGroup}>
            <label>User Emails *</label>
            <Controller
              name='userEmails'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactSelect
                  value={field.value?.map((email) => 
                    userOptions.find(opt => opt.value === email) || { label: email, value: email }
                  )}
                  onChange={(selected) => {
                    const emails = selected?.map((option) => option.value) || []
                    setSelectedUserOptions(selected || [])
                    field.onChange(emails)
                  }}
                  options={userOptions}
                  onInputChange={(value) => {
                    setSearchTerm(value)
                    return value
                  }}
                  isClearable={false}
                  isMulti={true}
                  creatable={false}
                  placeholder='select user emails...'
                  noOptionsMessage={({ inputValue }) => {
                    if (inputValue.length < 2) {
                      return 'Start typing to search...'
                    }
                    return `No user emails found for input "${inputValue}"`
                  }}
                  closeMenuOnSelect={false}
                  formatCreateLabel={(inputValue) => `Add email: ${inputValue}`}
                />
              )}
            />
          </div>
        )}

        {formValues.distributionType === 'specific_user_groups' && (
          <div className={styles.formGroup}>
            <label>User Groups *</label>
            <Controller
              name='userGroups'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactSelect
                  value={userGroupOptions.filter((option) => field.value?.includes(option.value))}
                  onChange={(selected) => {
                    const ids = selected?.map((option) => option.value) || []
                    field.onChange(ids)
                  }}
                  options={userGroupOptions}
                  isMulti
                  isClearable={false}
                  isLoading={isUserGroupLoading}
                  loadingMessage={({ inputValue }) => `Searching for user groups matching "${inputValue}"...`}
                  noOptionsMessage={({ inputValue }) => {
                    if (inputValue.length < 3) {
                      return 'Type alteast 3 characters to start searching'
                    }
                    return `No user groups found for input "${inputValue}"`
                  }}
                  closeMenuOnSelect={false}
                  placeholder='Select user groups...'
                />
              )}
            />
          </div>
        )}
        <div className={styles.formGroup}>
          <label className={styles.switchLabel}>
            Anonymous Survey
              <Controller
                name='isAnonymous'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div 
                    className={styles.switchContainer}
                    onClick={() => onChange(!value)}
                  >
                    <div className={`${styles.switch} ${value ? styles.switchActive : ''}`}>
                      <div className={styles.switchHandle} />
                    </div>
                  </div>
                )}
              />
          </label>
        </div>
      </Fragment>
    </div>
  )

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loading}>Loading campaign details...</div>
      ) : (
        <Fragment>
          <div className={styles.leftSection}>
            <h2 className={styles.title}>Campaign Configuration</h2>
            <div className={styles.buttonGroup}>
              <div
                className={`${styles.configButton} ${activeTab === 'create' ? styles.activeButton : ''}`}
                onClick={() => setActiveTab('create')}
              >
                <div className={styles.buttonContent}>
                  <div className={styles.buttonText}>
                    <h3>Create & Schedule Campaign</h3>
                    <p>Set up campaign details, target audience, and scheduling options</p>
                  </div>
                  {(activeTab === 'create') && (
                    <div className={styles.arrowIcon}>
                      <ChevronRight />
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>

          <div className={styles.rightSection}>
            <h3 className={styles.propertiesTitle}>
              {editingCampaign ? 'Edit Campaign' : 'Create Campaign'}
            </h3>
            {renderProperties()}
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default CampaignConfiguration
