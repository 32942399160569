import { Modal } from '@happyfoxinc/web-components'
import toast from 'react-hot-toast'

import styles from './Workspaces.module.css'

import { useUpdateWorkspaceMutation } from 'Src/servicesV3/workspaceApi'

import WorkspaceForm from './WorkspaceForm'

const EditWorkspace = ({ isOpen, onClose, workspace }) => {
  const [updateWorkspace] = useUpdateWorkspaceMutation()

  const handleUpdateWorkspace = async (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      visibility: data.visibility,
      example_queries: data.example_queries
    }

    if (data.visibleToGroups) {
      payload.mapped_user_groups = data.visibleToGroups.map((group) => group.id)
    }

    try {
      const promise = updateWorkspace({ workspaceId: workspace.id, data: payload }).unwrap()

      toast.promise(promise, {
        loading: 'Updating workspace',
        success: 'Workspace updated successfully',
        error: (err) => {
          const errorMessage = err?.data?.error || 'Unable to update workspace'
          return errorMessage
        }
      })

      await promise
      onClose()
    } catch {}
  }

  return (
    <Modal
      size='large'
      open={isOpen}
      onOpenChange={onClose}
      onCancel={onClose}
      title='Edit Workspace'
      showFooter={false}
      bodyClassName={styles.modalBody}
    >
      <WorkspaceForm onSubmit={handleUpdateWorkspace} workspace={workspace} isEdit />
    </Modal>
  )
}

export default EditWorkspace
