// import DefaultAppIcon from 'Src/assetsv3/icons/default-app.svg'
// import AzureIcon from 'Src/assetsv3/logos/azure.png'
import DropboxIcon from 'Src/assetsv3/logos/dropbox.png'
import GitHubIcon from 'Src/assetsv3/logos/github.png'
import Microsoft365Icon from 'Src/assetsv3/logos/microsoft.png'
import NotionIcon from 'Src/assetsv3/logos/notion.png'
import OktaIcon from 'Src/assetsv3/logos/okta.png'
import SalesforceIcon from 'Src/assetsv3/logos/salesforce.png'
import ZoomIcon from 'Src/assetsv3/logos/zoom.png'

export const INITIAL_STEP = {
  ACCESS_PROVISION: {
    type: 'Access Provision',
    title: 'Access Provision',
    description: 'This step will provide access to',
    isDefault: true,
    config: {}
  }
}

export const PROVIDER_ICONS = {
  okta: OktaIcon
  // azure: AzureIcon,
  // default: DefaultAppIcon
}

export const APP_ICONS = {
  salesforce: SalesforceIcon,
  notion: NotionIcon,
  github: GitHubIcon,
  zoom: ZoomIcon,
  dropbox: DropboxIcon,
  microsoft365: Microsoft365Icon
  // default: DefaultAppIcon
}

export const APP_DESCRIPTIONS = {
  'okta admin console': 'Configure SSO and access management settings.',
  'okta browser plugin': 'Manage browser-based authentication and access.',
  microsoft360: 'Manage email, OneDrive, and role access.',
  notion: 'Enable MFA and manage team permissions.',
  salesforce: 'Set SSO and role-based access for CRM.',
  github: 'Sync repo access and assign org roles.',
  zoom: 'Configure meeting security and permissions.',
  dropbox: 'Control shared folders and compliance settings.',
  default: 'Configure access permissions for this application.'
}

export const getProviderIcon = (provider) => {
  if (!provider) return PROVIDER_ICONS.default
  return PROVIDER_ICONS[provider.name.toLowerCase()] || PROVIDER_ICONS.default
}

export const getAppIcon = (appId) => {
  if (!appId) return APP_ICONS.default
  return APP_ICONS[appId.toLowerCase()] || APP_ICONS.default
}

export const getAppDescription = (name) => {
  if (!name) return APP_DESCRIPTIONS.default
  return APP_DESCRIPTIONS[name.toLowerCase()] || APP_DESCRIPTIONS.default
}

export const getFieldOptionsFromAccessControl = (accessControlData) => {
  if (!accessControlData) return []

  const fieldIcons = {
    Location: '📍',
    Departments: '🏢',
    Roles: '👤',
    'User Groups': '👥'
  }

  return accessControlData.map((field) => ({
    id: field.id,
    name: field.name,
    icon: fieldIcons[field.name] || '⚡',
    values: field.values
  }))
}

export const getFieldValuesFromAccessControl = (fieldId, accessControlData) => {
  if (!accessControlData) return []

  const field = accessControlData.find((f) => f.id.toString() === fieldId?.toString())
  return field?.values || []
}
