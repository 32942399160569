import { Fragment } from 'react'
import { Toaster } from 'react-hot-toast'
import { Navigate, Route, Routes } from 'react-router-dom'

import AppLoader from 'Src/components/AppLoader'
import ErrorBoundary from 'Src/components/ErrorBoundary'
import Main from 'Src/layoutv3/Main'
import { useGetAccountQuery, useGetCsrfTokenQuery, useGetProfileQuery } from 'Src/servicesV3/authApi'
import { useGetWorkspacesQuery } from 'Src/servicesV3/workspaceApi'
import { SidebarToggleProvider } from 'Src/utils/hooks/useSidebarToggle'
import { WorkspaceProvider } from 'Src/utilsV3/hooks/useWorkspaceContext'

import Admins from './Admins'
import AIAssistant from './AIAssistant'
import Answers from './Answers'
import Channels from './Channels'
import Dashboard from './Dashboard'
import Modules from './Modules'
import UserGroups from './UserGroups'
import Workspaces from './Workspaces'

const Pages = () => {
  const { isLoading: isLoadingCsrfToken } = useGetCsrfTokenQuery()
  const { data: account, isLoading: isLoadingAccount } = useGetAccountQuery()
  const { isLoading: isLoadingProfile } = useGetProfileQuery()
  const { isLoading: isLoadingWorkspace } = useGetWorkspacesQuery()

  const isLoading = isLoadingCsrfToken || isLoadingAccount || isLoadingProfile || isLoadingWorkspace

  if (!isLoading && !account?.is_v3_enabled) {
    return <Navigate to='/dashboard' replace />
  }

  return (
    <Fragment>
      <Toaster
        position='top-center'
        containerStyle={{
          left: 'var(--sidebar-width)'
        }}
        toastOptions={{
          duration: 3000
        }}
      />
      <ErrorBoundary>
        <AppLoader isLoading={isLoading}>
          <WorkspaceProvider>
            <SidebarToggleProvider>
              <Routes>
                <Route index element={<Navigate to='dashboard' replace />} />
                <Route path='/' element={<Main />}>
                  <Route path='dashboard' element={<Dashboard />} />
                  <Route path='modules/*' element={<Modules />} />
                  <Route path='ai-assistant/*' element={<AIAssistant />} />
                  <Route path='answers/*' element={<Answers />} />
                  <Route path='channels/*' element={<Channels />} />
                  <Route path='user-groups/*' element={<UserGroups />} />
                  <Route path='admins/*' element={<Admins />} />
                  <Route path='workspaces' element={<Workspaces />} />
                </Route>
                <Route path='*' element={<Navigate to='dashboard' replace />} />
              </Routes>
            </SidebarToggleProvider>
          </WorkspaceProvider>
        </AppLoader>
      </ErrorBoundary>
    </Fragment>
  )
}

export default Pages
