import { Box, Button, Flex, Modal, TextField } from '@happyfoxinc/web-components'
import { Fragment, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import styles from './SoftwareAccessModule.module.css'

import ChevronRightIcon from 'Src/assetsv3/icons/chevron-right-white.svg'
import OktaIcon from 'Src/assetsv3/logos/okta.png'
import BackButton from 'Src/componentsv3/BackButton'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import Tab from 'Src/componentsv3/Tab'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useCreateSoftwareAccessMutation, useGetSoftwareAccessQuery } from 'Src/servicesV3/softwareAccessModuleApi'

import ConfigModule from './ConfigModule'
import SoftwareAccessAppsList from './SoftwareAccessAppsList'

const ConfigurationView = ({
  selectedProvider,
  onProviderChange,
  isLoading,
  providerOptions,
  onStartSetup,
  isInitialSetup
}) => (
  <Flex
    direction='column'
    width='100%'
    height='360px'
    align='center'
    justify='center'
    gap='30px'
    p='40px'
    className={styles.ConfigurationContainer}
  >
    <h2 className={styles.NoConfigTitle}>
      {isInitialSetup ? "You haven't configure this yet" : 'Add New Application'}
    </h2>
    <p className={styles.NoConfigDescription}>
      {isInitialSetup
        ? 'To get the module up and running, set up the necessary applications and tailor predefined responses. Start by integrating apps like knowledge bases and collaboration tools. Then, customize the answers by defining common queries and creating standardized, easy-to-use responses.'
        : 'Add and configure new applications to expand your software access management. Choose from our integrated app providers or manually add custom applications. You can set up access controls, automation workflows, and customize the request process for each application.'}
    </p>

    <Flex align='flex-end'>
      <Flex direction='column' align='flex-start'>
        <p className={styles.appSelectLabel}>App provider</p>
        <Box width='330px'>
          <ReactSelect
            options={providerOptions}
            value={selectedProvider}
            onChange={onProviderChange}
            isLoading={isLoading}
            placeholder='Select an app provider'
            icon={OktaIcon}
            showSeparator
          />
        </Box>
      </Flex>
      <Flex as='span' align='center' justify='center' height='35px' width='35px' className={styles.OrDivider}>
        or
      </Flex>
      <Flex direction='column' align='flex-start'>
        <p className={styles.appSelectLabel}>Add app manually</p>
        <Box width='330px'>
          <TextField.Root size='2' radius='small' placeholder='Please enter app name' />
        </Box>
      </Flex>
    </Flex>
    <Button onClick={onStartSetup} disabled={!selectedProvider}>
      Start Setup
      <ChevronRightIcon />
    </Button>
  </Flex>
)

const SoftwareAccessModule = () => {
  const [showConfig, setShowConfig] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(0)
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [configData, setConfigData] = useState(null)

  const { data: softwareAccessApiResponse = {}, isLoading } = useGetSoftwareAccessQuery()
  const [createSoftwareAccess] = useCreateSoftwareAccessMutation()

  const { app_providers: appProviders = [], apps: softwareAccessList = [] } = softwareAccessApiResponse

  const [view, setView] = useState('overview')

  useEffect(() => {
    if (showConfig) {
      setView('configuration')
    } else if (softwareAccessList.length === 0) {
      setView('configuration')
    } else {
      setView('overview')
    }
  }, [showConfig, softwareAccessList.length])

  const providerOptions = useMemo(() => {
    if (!appProviders) return []
    return appProviders?.map((provider) => {
      return {
        value: provider,
        label: provider.name.charAt(0).toUpperCase() + provider.name.slice(1).toLowerCase()
      }
    })
  }, [appProviders])

  const handleConfigUpdate = (config) => {
    setConfigData(config)
  }

  const handleTabChange = (newView) => {
    setView(newView)
    if (newView === 'overview') {
      setShowConfig(false)
    } else if (newView === 'configuration') {
      setShowConfig(true)
    }
  }

  const handleStartSetup = () => {
    setShowConfig(true)
    setView('overview')
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
    setCurrentScreen(0)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setCurrentScreen(0)
  }

  const hasConfiguredApps = (appConfig) => {
    if (!appConfig) return false
    return appConfig.some((app) => app.accessConfig?.configured === true)
  }

  const transformConfigToPayload = (appConfig) => {
    const configuredApps = appConfig.filter(
      (app) => app.accessConfig.configured === true && app.workflowConfig.configured === true
    )

    if (configuredApps.length === 0) return null

    return configuredApps.map((app) => ({
      id: app.id,
      access_flow_type: 'fully_automated',
      provision_steps: app.workflowConfig.steps
        .filter((step) => !step.isDefault)
        .map((step) => {
          const baseStep = {
            step_name: step.type,
            user_profile_ids: step.config?.recipient ? [parseInt(step.config.recipient)] : []
          }

          if (step.config?.message) {
            baseStep.message = step.config.message
          }

          if (step.type === 'send_instruction' && step.config?.requireAcknowledgement !== undefined) {
            baseStep.user_acknowledgement = step.config.requireAcknowledgement
          }

          return baseStep
        }),
      access_control: {
        is_full_access: app.accessConfig.isFullAccess,
        conditions: app.accessConfig.conditions
      },
      ticket_creation: app.ticket_creation
    }))
  }

  const handleNextScreen = async () => {
    if (currentScreen === 0) {
      if (!configData || !hasConfiguredApps(configData)) {
        toast.error('Please configure at least one application before proceeding')
        return
      }
      setCurrentScreen(1)
    } else {
      if (configData) {
        try {
          const transformedConfig = transformConfigToPayload(configData)

          if (!transformedConfig) {
            toast.error('Please configure at least one application')
            return
          }

          const payload = {
            app_provider: selectedProvider?.value?.name,
            apps: transformedConfig
          }

          const promise = createSoftwareAccess(payload).unwrap()

          toast.promise(promise, {
            loading: 'Configuring software access...',
            success: 'Software access configured successfully',
            error: (err) => err?.data?.error || 'Failed to configure software access'
          })

          await promise
          handleModalClose()
          setShowConfig(false)
        } catch (error) {
          // Toast is handled by the promise above
        }
      }
    }
  }

  const handleModalCancel = () => {
    if (currentScreen === 1) {
      setCurrentScreen(0)
    } else {
      handleModalClose()
    }
  }

  const handleProviderChange = (option) => {
    setSelectedProvider(option)
  }

  return (
    <Fragment>
      <Modal
        size='full'
        open={isModalOpen}
        onOpenChange={handleModalClose}
        showProgressBar
        currentStep={currentScreen + 1}
        confirmText={currentScreen === 0 ? 'Proceed' : 'Finish'}
        cancelText={currentScreen === 0 ? 'Cancel' : 'Back'}
        totalSteps={2}
        onCancel={handleModalCancel}
        onConfirm={handleNextScreen}
        title='Configure Software Access Request Management'
      >
        <ConfigModule
          selectedProvider={selectedProvider?.value}
          currentScreen={currentScreen}
          onConfigUpdate={handleConfigUpdate}
        />
      </Modal>

      <Box p='24px'>
        <div className={styles.header}>
          <Flex align='center' gap='4px' className={styles.headingContainer}>
            <BackButton />
            <h1 className={styles.heading}>Software Access Request Management</h1>
          </Flex>
          <p className={styles.subheading}>
            Choose from our predefined modules to quickly get started based on your specific use case. Once selected,
            you can configure and customize each module to suit your requirements, making it easy to set up and adapt
            the tool to your workflow.
          </p>
        </div>
        <Flex className={styles.tabContainer} align='center' gap='15px'>
          {softwareAccessList.length > 0 && (
            <Tab
              isLoading={isLoading}
              label='Overview'
              isActive={view === 'overview'}
              onClick={() => handleTabChange('overview')}
            />
          )}
          <Tab
            isLoading={isLoading}
            label='Configuration'
            isActive={view === 'configuration'}
            onClick={() => handleTabChange('configuration')}
          />
          <Tab
            isLoading={isLoading}
            label='Reports'
            isActive={view === 'reports'}
            onClick={() => handleTabChange('reports')}
          />
        </Flex>
        {view === 'configuration' ? (
          <ConfigurationView
            selectedProvider={selectedProvider}
            onProviderChange={handleProviderChange}
            isLoading={isLoading}
            providerOptions={providerOptions}
            onStartSetup={handleModalOpen}
            isInitialSetup={softwareAccessList.length === 0}
          />
        ) : view === 'overview' ? (
          <Fragment>
            <Flex align='center' justify='space-between'>
              <h2 className={styles.appsListTitle}>Apps</h2>
              <Button variant='solid' onClick={handleStartSetup}>
                Add App +
              </Button>
            </Flex>
            {isLoading ? (
              <TableLoader rows={5} columns={5} showOptions />
            ) : (
              <SoftwareAccessAppsList data={softwareAccessList} />
            )}
          </Fragment>
        ) : null}
      </Box>
    </Fragment>
  )
}

export default SoftwareAccessModule
