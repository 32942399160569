import { Box, Button, Flex, TextField } from '@happyfoxinc/web-components'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import styles from './AnswerForm.module.css'

import Editor from 'Src/componentsv3/Editor'
import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { ANSWER_VISIBILITY_OPTIONS, KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Src/constants/user-groups'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { userGroupsApi } from 'Src/servicesV3/userGroupsApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

const customSelectStyles = {
  dropdownIndicator: () => ({
    display: 'none'
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'linear-gradient(270deg, rgba(245, 245, 247, 1) 0%, rgba(248, 248, 248, 1) 100%);',
    border: '1px solid #C7C7C7',
    borderRadius: '6px',
    padding: '2px',
    margin: '2px'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: '0',
    color: '#000000',
    fontSize: '12px'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: 'transparent',
      color: '#dc2626'
    }
  }),
  control: (provided, state) => ({
    ...provided,
    alignItems: 'baseline',
    paddingTop: '6px',
    minHeight: '60px',
    backgroundColor: '#fff',
    border: state?.isFocused ? '#3b82f6' : state?.selectProps?.error ? '#dc2626' : '1px solid #C7C7C7',
    boxShadow: state?.isFocused ? '0 0 0 1px #3b82f6' : 'none',
    '&:hover': {
      borderColor: state?.isFocused ? '#3b82f6' : '#cbd5e1'
    }
  })
}

const AnswerForm = ({ isEdit = false, isLoading = false, onSubmit, onSaveAndAdd, showSaveAndAdd = true }) => {
  const navigate = useNavigate()
  const { currentWorkspaceId, workspaces } = useWorkspace()

  const { data: account } = useGetAccountQuery()
  const [getUserGroups, getUserGroupsResult] = userGroupsApi.useLazyGetUserGroupsQuery()

  const accountType = account?.account_type
  const showWorkspaceDropdown = account?.is_workspaces_enabled

  const formMethods = useFormContext()

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue
  } = formMethods

  const selectedVisibility = watch('visibility')

  useEffect(() => {
    if (!watch('workspace_id')) {
      setValue('workspace_id', currentWorkspaceId)
    }
  }, [currentWorkspaceId, setValue, watch])

  const loadOptions = (inputValue, cb) => {
    const query = {
      search: inputValue,
      accountType
    }
    getUserGroups(query)
      .unwrap()
      .then((data) => {
        cb(data.results)
      })
  }

  const handleFormSubmit = (e) => {
    onSubmit(e)
  }

  const handleSaveAndAdd = (e) => {
    onSaveAndAdd(e)
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <FormField>
        <FormField.Field label='Answer Title' error={errors?.name?.message}>
          <Box width='330px'>
            <TextField.Root {...register('name')} size='2' radius='small' placeholder='Enter policy type name' />
          </Box>
        </FormField.Field>
      </FormField>
      <FormField>
        <FormField.Field error={errors?.content?.message}>
          <Controller
            name='content'
            control={control}
            rules={{ required: 'Content is required' }}
            render={({ field: { value, onChange } }) => <Editor defaultValue={value} onChange={onChange} />}
          />
        </FormField.Field>
      </FormField>
      <FormField>
        <Flex gap='24px'>
          {!isEdit && showWorkspaceDropdown && (
            <FormField.Field label='Workspace' error={errors?.workspace_id?.message}>
              <Controller
                name='workspace_id'
                control={control}
                render={({ field }) => (
                  <Box width='330px'>
                    <ReactSelect
                      {...field}
                      value={workspaces.find((opt) => opt.id === field.value)}
                      onChange={(opt) => field.onChange(opt.id)}
                      isClearable={false}
                      options={workspaces}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder='Select Workspace'
                    />
                  </Box>
                )}
              />
            </FormField.Field>
          )}
          <FormField.Field label='Show answer to' error={errors?.visibility?.message}>
            <Controller
              name='visibility'
              control={control}
              render={({ field }) => (
                <Box width='330px'>
                  <ReactSelect
                    {...field}
                    placeholder='Select'
                    value={ANSWER_VISIBILITY_OPTIONS.find((opt) => opt.value === field.value)}
                    onChange={(opt) => field.onChange(opt.value)}
                    isClearable={false}
                    options={ANSWER_VISIBILITY_OPTIONS}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                </Box>
              )}
            />
          </FormField.Field>
        </Flex>
      </FormField>
      {selectedVisibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && (
        <FormField>
          <FormField.Field
            label='Select user group(s) for which the answer will be recommended'
            error={errors?.visibleToGroups?.message}
          >
            <Controller
              name='visibleToGroups'
              control={control}
              render={({ field }) => (
                <Box width='720px'>
                  <ReactSelect
                    {...field}
                    placeholder='Select Groups'
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isMulti
                    options={[]}
                    isClearable={false}
                    loadOptions={loadOptions}
                    isLoading={getUserGroupsResult.isLoading}
                    loadingMessage={({ inputValue }) => {
                      return `Searching for sites matching "${inputValue}"...`
                    }}
                    noOptionsMessage={({ inputValue }) => {
                      if (inputValue.length < 3) {
                        return 'Type atleast 3 characters to start searching'
                      }
                      return `No sites found for input "${inputValue}"`
                    }}
                    styles={customSelectStyles}
                  />
                </Box>
              )}
            />
          </FormField.Field>
        </FormField>
      )}
      <Flex gap='12px' mt='24px' className={styles.ButtonContainer}>
        <Button type='submit' disabled={isLoading}>
          Save
        </Button>
        {showSaveAndAdd && (
          <Button type='button' disabled={isLoading} onClick={handleSaveAndAdd}>
            Save and Add
          </Button>
        )}
        <Button type='button' onClick={() => navigate('/answers')}>
          Cancel
        </Button>
      </Flex>
    </form>
  )
}

export default AnswerForm
