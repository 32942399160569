// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AglIZ6brQkapOvPbg6MV{padding:24px}.UPCu7rdSWHRLpP8v0r3z{font-size:24px;color:#1e2022;font-weight:400;margin-bottom:24px}.OOP1ir0YU2MQt8pnD4YS .quill{margin-top:8px}.oMz1hXziHuEmMUmm77JA{min-height:200px;margin-bottom:24px}.oMz1hXziHuEmMUmm77JA .ql-toolbar{border-top-left-radius:4px;border-top-right-radius:4px}.oMz1hXziHuEmMUmm77JA .ql-container{border-bottom-left-radius:4px;border-bottom-right-radius:4px;background:#fff}.R5z7rg_gsm3hGkig6ILg{color:#dc2626;font-size:14px;margin-top:4px}.DEM1K81BRSH74FWUjtIT{margin-top:32px;justify-content:space-between}.NXhdQ4WSN9vz1SVKee3Z{margin-left:auto}.lZnacccEv4MGtWEVFfpA{margin-left:6px;font-size:16px;cursor:pointer}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Answers/AnswerForm/AnswerForm.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,cAAA,CACA,aAAA,CACA,eAAA,CACA,kBAAA,CAGF,6BACE,cAAA,CAGF,sBACE,gBAAA,CACA,kBAAA,CAGF,kCACE,0BAAA,CACA,2BAAA,CAGF,oCACE,6BAAA,CACA,8BAAA,CACA,eAAA,CAGF,sBACE,aAAA,CACA,cAAA,CACA,cAAA,CAGF,sBACE,eAAA,CACA,6BAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,cAAA","sourcesContent":[".container {\n  padding: 24px;\n}\n\n.heading {\n  font-size: 24px;\n  color: #1E2022;\n  font-weight: 400;\n  margin-bottom: 24px;\n}\n\n.formField :global(.quill) {\n  margin-top: 8px;\n}\n\n.editor {\n  min-height: 200px;\n  margin-bottom: 24px;\n}\n\n.editor :global(.ql-toolbar) {\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n}\n\n.editor :global(.ql-container) {\n  border-bottom-left-radius: 4px;\n  border-bottom-right-radius: 4px;\n  background: white;\n}\n\n.errorText {\n  color: #dc2626;\n  font-size: 14px;\n  margin-top: 4px;\n}\n\n.actions {\n  margin-top: 32px;\n  justify-content: space-between;\n}\n\n.cancelButton {\n  margin-left: auto;\n}\n\n.removeGroup {\n  margin-left: 6px;\n  font-size: 16px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AglIZ6brQkapOvPbg6MV",
	"heading": "UPCu7rdSWHRLpP8v0r3z",
	"formField": "OOP1ir0YU2MQt8pnD4YS",
	"editor": "oMz1hXziHuEmMUmm77JA",
	"errorText": "R5z7rg_gsm3hGkig6ILg",
	"actions": "DEM1K81BRSH74FWUjtIT",
	"cancelButton": "NXhdQ4WSN9vz1SVKee3Z",
	"removeGroup": "lZnacccEv4MGtWEVFfpA"
};
export default ___CSS_LOADER_EXPORT___;
