import { Box } from '@happyfoxinc/web-components'

import styles from './FormField.module.css'

const Field = ({ label, error, children, className = '' }) => {
  return (
    <Box className={` ${className}`}>
      {label && <p className={styles.label}>{label}</p>}
      {children}
      {error && <span className={styles.error}>{error}</span>}
    </Box>
  )
}

export default Field
