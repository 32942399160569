// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S1YPYcTyDw5KOH8nXvmv{width:100%}.CT_4XdpvMcifTYWTKQ5Z{display:flex;align-items:center;position:relative;padding:0 4px}.SRqf4V0ZRA8PFgun2SaL{width:30px;height:30px;-o-object-fit:contain;object-fit:contain}.UPlxaXHHCZpPYOEgnD7t{position:absolute;right:0;top:0;bottom:0;width:1px;background-color:#c7c7c7}", "",{"version":3,"sources":["webpack://./../src/componentsv3/ReactSelect/ReactSelect.module.css"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,aAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,qBAAA,CAAA,kBAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,KAAA,CACA,QAAA,CACA,SAAA,CACA,wBAAA","sourcesContent":[".Select {\n  width: 100%;\n}\n\n.iconContainer {\n  display: flex;\n  align-items: center;\n  position: relative;\n  padding: 0 4px;\n}\n\n.iconImg {\n  width: 30px;\n  height: 30px;\n  object-fit: contain;\n}\n\n.separator {\n  position: absolute;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  width: 1px;\n  background-color: #c7c7c7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Select": "S1YPYcTyDw5KOH8nXvmv",
	"iconContainer": "CT_4XdpvMcifTYWTKQ5Z",
	"iconImg": "SRqf4V0ZRA8PFgun2SaL",
	"separator": "UPlxaXHHCZpPYOEgnD7t"
};
export default ___CSS_LOADER_EXPORT___;
