import { Modal, Flex } from '@happyfoxinc/web-components'
import { Fragment, useState, useEffect, useCallback } from 'react'
import cx from 'classnames'
import styles from './SurveyManagementModule.module.css'
import BackButton from 'Src/componentsv3/BackButton'
import ConfigModule from './ConfigModule'
import CampaignConfiguration from './ConfigModule/CampaignConfiguration'
import ReportsModule from './ReportsModule/ReportsModule';
import CampaignsContent from './CampaignsContent';
import SurveysContent from './SurveysContent';

import {
  useGetSurveysQuery,
  useGetSurveyQuery,
  useCreateSurveyMutation,
  useUpdateSurveyMutation,
  useDeleteSurveyMutation,
  useCreateQuestionsMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation
} from 'Src/servicesV3/surveyApi';

import {
  useGetCampaignsQuery,
  useGetCampaignQuery,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  useDeleteCampaignMutation
} from 'Src/servicesV3/campaignApi';

const TABS = {
  SURVEYS: 'surveys',
  CAMPAIGNS: 'campaigns',
  REPORTS: 'reports'
}

const initialCampaignState = {
  name: '',
  workspaceId: '',
  description: '',
  type: 'immediately',
  scheduledAt: '',
  repeat: 'weekly',
  surveyId: '',
  userEmails: [],
  userGroups: [],
  isAnonymous: false,
  distributionType: 'specific_user_groups'
};

const SurveyManagementModule = () => {
  const [activeTab, setActiveTab] = useState(TABS.SURVEYS)
  const [isSetupStarted, setIsSetupStarted] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(0)
  const [surveyData, setSurveyData] = useState({
    surveyName: '',
    description: '',
    thankYouMessage: '',
    notificationMessage: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [surveyId, setSurveyId] = useState(null);
  const [questionsData, setQuestionsData] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);
  const [campaignData, setCampaignData] = useState(initialCampaignState);
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);
  const [editingSurvey, setEditingSurvey] = useState(null);
  const [editingCampaign, setEditingCampaign] = useState(null);

  const { data: surveys, isLoading: isSurveysLoading } = useGetSurveysQuery();
  const { data: selectedSurvey, isLoading: isLoadingSurvey } = useGetSurveyQuery(editingSurvey?.id, {
    skip: !editingSurvey?.id
  });
  const [createSurvey] = useCreateSurveyMutation();
  const [updateSurvey] = useUpdateSurveyMutation();
  const [deleteSurvey] = useDeleteSurveyMutation();
  const [createQuestions] = useCreateQuestionsMutation();
  const [updateQuestion] = useUpdateQuestionMutation();
  const [deleteQuestion] = useDeleteQuestionMutation();

  const { data: campaigns, isLoading: isCampaignsLoading } = useGetCampaignsQuery();
  const { data: selectedCampaign, isLoading: isLoadingCampaign } = useGetCampaignQuery(editingCampaign?.id, {
    skip: !editingCampaign?.id
  });
  const [createCampaign] = useCreateCampaignMutation();
  const [updateCampaign] = useUpdateCampaignMutation();
  const [deleteCampaign] = useDeleteCampaignMutation();

  const handleModalClose = () => {
    setIsSetupStarted(false);
    setEditingSurvey(null);
    setSurveyId(null);
    setSurveyData({
      surveyName: '',
      description: '',
      thankYouMessage: '',
      notificationMessage: '',
      isEdit: false,
      editable: null
    });
    setQuestionsData([]);
    setCurrentScreen(0);
  };

  useEffect(() => {
    if (selectedSurvey && editingSurvey) {
      setSurveyData({
        surveyName: selectedSurvey.survey.title || '',
        description: selectedSurvey.survey.description || '',
        thankYouMessage: selectedSurvey.survey.properties?.closing_message || '',
        notificationMessage: selectedSurvey.survey.properties?.cover_message || '',
        isEdit: true,
        editable: {
          surveyName: true,
          description: true,
          thankYouMessage: true,
          notificationMessage: true
        }
      });
      setSurveyId(selectedSurvey.survey.id);
      setIsSetupStarted(true);
    }
  }, [selectedSurvey, editingSurvey]);

  const handleRowClick = (survey) => {
    if (survey && survey.id) {
      setEditingSurvey(survey);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeMenu && !event.target.closest(`.${styles.menuContainer}`)) {
        setActiveMenu(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [activeMenu]);

  const handleStartSetup = () => {
    if (!isSetupStarted) {
      setIsSetupStarted(true);
    } else {
      handleModalClose();
    }
  }

  const handleNextScreen = async () => {
    if (currentScreen === 0) {
      setIsLoading(true);
      const payload = {
        title: surveyData.surveyName?.trim(),
        description: surveyData.description?.trim(),
        properties: {
          closing_message: surveyData.thankYouMessage?.trim(),
          cover_message: surveyData.notificationMessage?.trim()
        }
      };

      const missingFields = Object.entries(payload)
        .filter(([_, value]) => !value)
        .map(([key]) => key);

      if (missingFields.length > 0) {
        setIsLoading(false);
        return;
      }

      try {
        let response;
        if (surveyId) {
          response = await updateSurvey({ id: surveyId, data: payload }).unwrap();
        } else {
          response = await createSurvey(payload).unwrap();
          setSurveyId(response?.survey?.id);
        }
        setCurrentScreen(1);
        setSurveyData(prev => ({ ...prev, isEdit: true }));
      } catch (err) {
        console.error('Survey operation error:', err);
      } finally {
        setIsLoading(false);
      }
    } else if (currentScreen === 1) {
      setQuestionsData(questionsData);
      if (questionsData?.newQuestions?.length > 0) {
        await createQuestions({ 
          id: surveyId, 
          data: { 
            questions: questionsData.newQuestions 
          }
        }).unwrap();
      }

      if (questionsData?.modifiedQuestions?.length > 0) {
        await Promise.all(questionsData.modifiedQuestions.map(question => 
          updateQuestion({ 
            questionId: question.id,
            surveyId: surveyId,
            data: question 
          }).unwrap()
        ));
      }


      if (questionsData?.deletedQuestions?.length > 0) {
        await Promise.all(questionsData.deletedQuestions.map(questionId => 
          deleteQuestion({ 
            questionId,
            surveyId: surveyId 
          }).unwrap()
        ));
      }
      handleModalClose();
    }
  };

  const handleCancelButton = () => {
    if (currentScreen > 0) {
      setCurrentScreen(currentScreen - 1);
    } else {
      handleModalClose();
    }
  }

  const isNextDisabled = () => {
    if (currentScreen === 0) {
      return (
        isLoading || 
        !surveyData.surveyName?.trim() || 
        !surveyData.description?.trim() || 
        !surveyData.thankYouMessage?.trim() || 
        !surveyData.notificationMessage?.trim()
      );
    } else if (currentScreen === 1) {
      return isLoading || !questionsData || questionsData.length === 0;
    }
    return false;
  };

  const handleModifiedQuestions = (questions) => {
    setQuestionsData(questions);
  };

  const handleDeleteSurvey = useCallback((e, id) => {
    e.stopPropagation();
    deleteSurvey(id).unwrap().then(() => {
      setActiveMenu(null);
    }).catch((err) => {
      console.error('Delete survey error:', err);
    });
  }, [deleteSurvey]);

  const handleCampaignClick = (campaign) => {
    if (campaign && campaign.id) {
      setEditingCampaign(campaign);
      setCampaignData({
        name: campaign.name || '',
        workspaceId: campaign.workspace_id || '',
        description: campaign.description || '',
        type: campaign.distribution_schedule_type || 'immediately',
        scheduledAt: campaign.properties?.distribution_details?.scheduled_at || '',
        repeat: campaign.properties?.distribution_details?.repeat || 'weekly',
        surveyId: campaign.survey_id || '',
        isAnonymous: campaign.properties?.is_anonymous || false,
        userEmails: campaign.properties?.distribution_details?.distribute_to?.user_email_ids || [],
        userGroups: campaign.properties?.distribution_details?.distribute_to?.user_group_ids || [],
        distributionType: campaign.properties?.distribution_details?.distribute_to?.user_email_ids?.length > 0 
          ? 'specific_user_emails' 
          : 'specific_user_groups'
      });
      setIsCampaignModalOpen(true);
    }
  };

  const handleCampaignModalClose = () => {
    setIsCampaignModalOpen(false);
    setEditingCampaign(null);
    setCampaignData(initialCampaignState);
  };

  const handleCampaignSubmit = async () => {
    try {
      setIsLoading(true);
      const formattedData = {
        name: campaignData.name?.trim(),
        workspace_id: campaignData.workspaceId,
        description: campaignData.description?.trim(),
        survey_id: campaignData.surveyId,
        distribution_schedule_type: campaignData.type,
        properties: {
          is_anonymous: campaignData.isAnonymous || false,
          distribution_details: {
            scheduled_at: campaignData.scheduledAt ? new Date(campaignData.scheduledAt).toISOString() : null,
            repeat: campaignData.type === 'recurring' ? campaignData.repeat : null,
            distribute_to: {
              user_email_ids: campaignData.distributionType === 'specific_user_emails' ? campaignData.userEmails : [],
              user_group_ids: campaignData.distributionType === 'specific_user_groups' ? campaignData.userGroups : []
            }
          }
        }
      };

      const requiredFields = ['name', 'workspace_id', 'description', 'distribution_schedule_type'];
      const missingFields = requiredFields.filter(field => !formattedData[field]);
      
      if (missingFields.length > 0) {
        return;
      }

      let response;
      if (editingCampaign) {
        response = await updateCampaign({ 
          id: editingCampaign.id, 
          data: formattedData 
        }).unwrap();
      } else {
        response = await createCampaign(formattedData).unwrap();
      }

      handleCampaignModalClose();
    } catch (err) {
      console.error('Campaign operation error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCampaign = async (e, id) => {
    e.stopPropagation();
    try {
      await deleteCampaign(id).unwrap();
      setActiveMenu(null);
    } catch (err) {
      console.error('Delete campaign error:', err);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case TABS.SURVEYS:
        return (
          <SurveysContent 
            surveys={surveys}
            isSurveysLoading={isSurveysLoading}
            handleStartSetup={handleStartSetup}
            handleRowClick={handleRowClick}
            handleDeleteSurvey={handleDeleteSurvey}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        )
      case TABS.CAMPAIGNS:
        return (
          <CampaignsContent
            campaigns={campaigns}
            isCampaignsLoading={isCampaignsLoading}
            handleCampaignClick={handleCampaignClick}
            handleDeleteCampaign={handleDeleteCampaign}
            handleCreateCampaign={() => {
              setCampaignData(initialCampaignState);
              setIsCampaignModalOpen(true);
            }}
          />
        )
      case TABS.REPORTS:
        return <ReportsModule />
      default:
        return null
    }
  }

  return (
    <Fragment>
      <Modal
        size='full'
        open={isSetupStarted}
        onOpenChange={handleModalClose}
        confirmText={currentScreen === 1 ? 'Finish' : 'Proceed'}
        cancelText={currentScreen === 0 ? 'Cancel' : 'Back'}
        onCancel={handleCancelButton}
        onConfirm={handleNextScreen}
        showProgressBar
        currentStep={currentScreen + 1}
        totalSteps={2}
        title={currentScreen === 1 && (editingSurvey) ? 'Edit Survey' : 'Create Survey'}
        contentClassName={styles.modalContent}
        confirmDisabled={isNextDisabled()}
      >
        <ConfigModule 
          key={`${currentScreen}-${surveyId}-${editingSurvey?.id}`}
          currentScreen={currentScreen} 
          surveyData={surveyData}
          setSurveyData={setSurveyData}
          surveyId={surveyId}
          handleTransformedQuestions={handleModifiedQuestions}
          isEdit={!!editingSurvey}
          isLoading={isLoadingSurvey}
        />
      </Modal>
      <Modal
        size='full'
        open={isCampaignModalOpen}
        onOpenChange={handleCampaignModalClose}
        confirmText={editingCampaign ? "Save" : "Create Campaign"}
        cancelText="Cancel"
        onCancel={handleCampaignModalClose}
        onConfirm={handleCampaignSubmit}
        showProgressBar
        currentStep={1}
        totalSteps={1}
        title={editingCampaign ? "Edit Campaign" : "Create Campaign"}
        contentClassName={styles.modalContent}
        confirmDisabled={isLoading}
      >
        <CampaignConfiguration 
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          editingCampaign={editingCampaign}
          isLoading={isLoadingCampaign}
          selectedCampaign={selectedCampaign}
          fromSurveyPage={false}
        />
      </Modal>
      <div className={styles.container}>
        <div className={styles.header}>
          <Flex align='center' gap='4px' className={styles.headingContainer}>
            <BackButton />
            <h1 className={styles.heading}>Survey Management</h1>
          </Flex>
          <p className={styles.subheading}>
            Easily create and manage surveys to gather user feedback, enabling quick insights and informed decision-making.
          </p>
        </div>
        <div className={styles.tabContainer}>
          <div className={styles.tabList}>
            {Object.values(TABS).map((tab) => {
              const hasSurveys = surveys?.surveys?.length > 0;
              const isDisabled = tab !== TABS.SURVEYS && !hasSurveys;
              const isAvailable = !isDisabled && tab !== activeTab;
              
              return (
                <button
                  key={tab}
                  className={cx(styles.tab, {
                    [styles.active]: activeTab === tab,
                    [styles.disabled]: isDisabled,
                    [styles.available]: isAvailable
                  })}
                  onClick={() => !isDisabled && setActiveTab(tab)}
                  disabled={isDisabled}
                  title={isDisabled ? "Create a survey first" : ""}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              );
            })}
          </div>
        </div>
        {renderTabContent()}
      </div>
    </Fragment>
  )
}

export default SurveyManagementModule
