import { Box, Button, Flex } from '@happyfoxinc/web-components'
import cx from 'classnames'
import { Fragment, useState } from 'react'

import styles from './WorkflowConfig.module.css'

import ApprovalProcessIcon from 'Src/assetsv3/icons/approval-process.svg'
import CloseIcon from 'Src/assetsv3/icons/close.svg'
import CreateTicketIcon from 'Src/assetsv3/icons/create-ticket.svg'
import InfoIcon from 'Src/assetsv3/icons/info.svg'
import SendInstructionIcon from 'Src/assetsv3/icons/send-instruction.svg'
import StartFlagIcon from 'Src/assetsv3/icons/start-flag.svg'
import TickIcon from 'Src/assetsv3/icons/tick.svg'
import TrashIcon from 'Src/assetsv3/icons/trash.svg'
import ReactSelect from 'Src/componentsv3/ReactSelect'

const STEP_TYPE_ICONS = {
  send_instruction: SendInstructionIcon,
  request_approval: ApprovalProcessIcon,
  create_ticket: CreateTicketIcon
}

const StepForm = ({
  config,
  sendInstructionData,
  requestApprovalData,
  onSave,
  onDelete,
  onClose,
  isEditing,
  stepType
}) => {
  const [formData, setFormData] = useState(
    config || {
      recipient: '',
      message: '',
      requireAcknowledgement: false
    }
  )

  const getStepDetails = () => {
    switch (stepType) {
      case 'send_instruction':
        return sendInstructionData
      case 'request_approval':
        return requestApprovalData
      default:
        return null
    }
  }

  const stepDetails = getStepDetails()
  const selectedRecipient = stepDetails?.fields?.find((field) => field.id.toString() === formData.recipient.toString())

  const recipientOptions =
    stepDetails?.fields?.map((field) => ({
      value: field.id.toString(),
      label: `${field.name} (${field.email})`
    })) || []

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }))
  }

  const handleSave = () => {
    const savedData = stepType === 'request_approval' ? { recipient: formData.recipient } : formData
    onSave(savedData)
  }

  return (
    <div className={styles.stepContent}>
      <Flex justify='space-between' align='center' className={styles.stepHeader}>
        <Flex align='flex-end' wrap='wrap' gap='3px'>
          <h3 className={styles.stepTitle}>
            {stepDetails.name === 'send_instruction' ? 'Send Instruction' : 'Request Approval'}
          </h3>

          {selectedRecipient && (
            <Fragment>
              <p className={styles.stepType}>{stepDetails.name === 'send_instruction' ? 'to' : 'from'}</p>
              <h3 className={styles.stepTitle}>@{selectedRecipient.name}</h3>
            </Fragment>
          )}
        </Flex>
        <Flex align='center' gap='8px'>
          <Button size='small' radius='medium' onClick={handleSave} className={styles.saveButton}>
            Save
          </Button>
          {isEditing && (
            <button className={styles.iconButton} onClick={onDelete}>
              <TrashIcon />
            </button>
          )}
          <button className={cx(styles.iconButton, styles.closeIcon)} onClick={onClose}>
            <CloseIcon />
          </button>
        </Flex>
      </Flex>
      <Flex direction='column' gap='16px' className={styles.instructionForm}>
        <Flex direction='column' gap='6px'>
          <label className={styles.formLabel}>
            {stepDetails.name === 'send_instruction' ? 'Send Instruction to' : 'Request Approval from'}
          </label>
          <Box width='100%'>
            <ReactSelect
              value={
                formData.recipient
                  ? {
                      value: formData.recipient,
                      label: recipientOptions.find((opt) => opt.value === formData.recipient)?.label || ''
                    }
                  : null
              }
              inDialog
              modalContainerClassName='modal-content'
              menuPortalStyles={{
                marginLeft: '-70px',
                marginTop: '-10px'
              }}
              options={recipientOptions}
              onChange={(option) => handleChange('recipient', option?.value || '')}
              placeholder='Select recipient...'
            />
          </Box>
        </Flex>

        {stepType === 'send_instruction' && (
          <Fragment>
            <Flex direction='column' gap='6px'>
              <label className={styles.formLabel}>Message</label>
              <textarea
                className={styles.formTextarea}
                value={formData.message}
                onChange={(e) => handleChange('message', e.target.value)}
                placeholder='Enter instruction message...'
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            </Flex>
            <Flex align='center' gap='8px'>
              <label className={styles.switch}>
                <input
                  type='checkbox'
                  checked={formData.requireAcknowledgement}
                  onChange={(e) => handleChange('requireAcknowledgement', e.target.checked)}
                />
                <span className={styles.slider} />
              </label>
              <label className={styles.switchLabel}>User Acknowledgement</label>
            </Flex>
          </Fragment>
        )}
      </Flex>
    </div>
  )
}

const WorkflowConfig = ({
  app,
  workflowSteps,
  provisionSteps,
  sendInstructionData,
  requestApprovalData,
  onWorkflowUpdate
}) => {
  const [editingStep, setEditingStep] = useState(null)
  const [showDropdown, setShowDropdown] = useState({ top: false, bottom: false })

  const getRecipientDetails = (recipientId, stepType) => {
    const stepDetails = stepType === 'send_instruction' ? sendInstructionData : requestApprovalData

    if (!stepDetails?.fields || !recipientId) return null
    return stepDetails.fields.find((field) => field.id.toString() === recipientId.toString())
  }

  const handleAddStep = (stepData, position) => {
    const newStep = {
      type: stepData.key,
      title: stepData.label,
      config: {
        recipient: '',
        message: '',
        requireAcknowledgement: false
      }
    }

    const updatedSteps = position === 'top' ? [newStep, ...workflowSteps] : [...workflowSteps, newStep]

    onWorkflowUpdate(updatedSteps)
    setShowDropdown({ top: false, bottom: false })
    setEditingStep(position === 'top' ? 0 : workflowSteps.length)
  }

  const handleStepSave = (index, config) => {
    const updatedSteps = workflowSteps.map((step, i) => (i === index ? { ...step, config } : step))
    onWorkflowUpdate(updatedSteps)
    setEditingStep(null)
  }

  const handleStepDelete = (index) => {
    const updatedSteps = workflowSteps.filter((_, i) => i !== index)
    onWorkflowUpdate(updatedSteps)
    setEditingStep(null)
  }

  const handleStepClose = () => {
    if (editingStep !== null) {
      const currentStep = workflowSteps[editingStep]

      if (!isStepConfigured(currentStep)) {
        const updatedSteps = workflowSteps.filter((_, index) => index !== editingStep)
        onWorkflowUpdate(updatedSteps)
      }
    }
    setEditingStep(null)
  }

  const isStepConfigured = (step) => {
    return step.config && (step.config.recipient || step.config.message || step.config.requireAcknowledgement)
  }

  const handleStepClick = (index, step) => {
    if (step.isDefault) return
    setEditingStep(index)
  }

  const renderStepContent = (step, index) => {
    const recipientDetails = getRecipientDetails(step.config.recipient, step.type)
    const recipientName = recipientDetails?.name || ''

    return (
      <div
        className={cx(styles.stepContent, {
          [styles.defaultStep]: step.isDefault,
          [styles.editableStep]: !step.isDefault
        })}
        onClick={() => handleStepClick(index, step)}
      >
        <Flex justify='space-between' align='center' className={styles.stepHeader}>
          <Flex align='flex-end' wrap='wrap' gap='3px'>
            <h3 className={styles.stepTitle}>{step.title}</h3>
            {!step.isDefault && recipientName && (
              <Fragment>
                <p className={styles.stepType}>{step.type === 'send_instruction' ? 'to' : 'from'}</p>
                <h3 className={styles.stepTitle}>@{recipientName}</h3>
              </Fragment>
            )}
          </Flex>
          {step.isDefault && (
            <button className={styles.infoButton}>
              <InfoIcon />
            </button>
          )}
        </Flex>
        {step?.isDefault && (
          <p className={styles.stepDescription}>
            {step.description} {app.name} in {app.integration_platform}.
          </p>
        )}
        {step?.config?.message ||
          (step.config.requireAcknowledgement && (
            <Box width='90%'>
              <p className={styles.stepMessage}>{step.config.message}</p>
              {step.config.requireAcknowledgement && (
                <p className={styles.acknowledgement}>
                  <span className={styles.tickIconWrapper}>
                    <TickIcon />
                  </span>
                  User Acknowledgement Required
                </p>
              )}
            </Box>
          ))}
      </div>
    )
  }

  const renderDropdown = (position) => (
    <div className={styles.stepDropdown}>
      {provisionSteps.provision_steps.map((step) => {
        const Icon = STEP_TYPE_ICONS[step.key]
        return (
          <button key={step.key} className={styles.dropdownItem} onClick={() => handleAddStep(step, position)}>
            <div className={styles.dropdownItemContent}>
              <Icon className={styles.dropdownItemIcon} />
              <span className={styles.dropdownItemText}>{step.label}</span>
            </div>
            <span className={styles.dropdownItemArrow}>›</span>
          </button>
        )
      })}
    </div>
  )

  return (
    <div className={styles.workflowContainer}>
      <div className={styles.startIconWrapper}>
        <StartFlagIcon />
      </div>

      <div className={cx(styles.stepLine, styles.firstLine)} />
      <div className={styles.addStepWrapper}>
        {showDropdown.top ? (
          renderDropdown('top')
        ) : (
          <Button
            variant='outline'
            radius='full'
            className={styles.addStepButton}
            onClick={() => setShowDropdown((prev) => ({ ...prev, top: true }))}
          >
            +
          </Button>
        )}
      </div>

      <div className={cx(styles.stepLine, styles.secondLine)} />

      {workflowSteps.map((step, index) => (
        <div key={index} className={styles.stepWrapper}>
          <Flex gap='16px' align='flex-start'>
            <div className={styles.stepNumber}>{index + 1}</div>
            {editingStep === index ? (
              <StepForm
                config={step.config}
                onSave={(config) => handleStepSave(index, config)}
                onDelete={() => handleStepDelete(index)}
                onClose={handleStepClose}
                isEditing={isStepConfigured(step)}
                stepType={step.type}
                sendInstructionData={sendInstructionData}
                requestApprovalData={requestApprovalData}
              />
            ) : (
              renderStepContent(step, index)
            )}
          </Flex>
        </div>
      ))}

      <div className={styles.addStepWrapper}>
        {showDropdown.bottom ? (
          renderDropdown('bottom')
        ) : (
          <Button
            variant='outline'
            radius='full'
            className={styles.addStepButton}
            onClick={() => setShowDropdown((prev) => ({ ...prev, bottom: true }))}
          >
            +
          </Button>
        )}
      </div>
    </div>
  )
}

export default WorkflowConfig
