import { Sidebar } from '@happyfoxinc/web-components'
import { useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import styles from './Main.module.css'

import AiAssistantIcon from 'Src/assetsv3/icons/ai-assistant.svg'
import AnswersIcon from 'Src/assetsv3/icons/answers.svg'
import AppsIcon from 'Src/assetsv3/icons/apps.svg'
import ChannelsIcon from 'Src/assetsv3/icons/channels.svg'
import DashboardIcon from 'Src/assetsv3/icons/dashboard.svg'
import InboxIcon from 'Src/assetsv3/icons/inbox.svg'
import ManageAdminsIcon from 'Src/assetsv3/icons/manage-admin.svg'
import ModulesIcon from 'Src/assetsv3/icons/modules.svg'
import ReportsIcon from 'Src/assetsv3/icons/reports.svg'
import UserGroupsIcon from 'Src/assetsv3/icons/user-groups.svg'
import WorkspaceIcon from 'Src/assetsv3/icons/workspace.svg'

import DashboardImg from '../../assetsv3/images/backgrounds/dashboard-bg.png'
import SecondaryBgImg from '../../assetsv3/images/backgrounds/secondary-bg-img.png'
import Topbar from '../Topbar'

const backgroundConfig = {
  '/v3/dashboard': DashboardImg,
  '/v3/modules': SecondaryBgImg,
  '/v3/ai-assistant': SecondaryBgImg,
  default: SecondaryBgImg
}

const MenuItems = [
  {
    icon: DashboardIcon,
    label: 'Dashboard',
    path: '/v3/dashboard'
  },
  {
    icon: ModulesIcon,
    label: 'Modules',
    path: '/v3/modules'
  },
  { type: 'divider' },
  {
    icon: AnswersIcon,
    label: 'Answers',
    path: '/v3/answers'
  },
  {
    icon: AiAssistantIcon,
    label: 'AI Assistant',
    path: '/v3/ai-assistant'
  },
  { type: 'divider' },
  // {
  //   icon: AppsIcon,
  //   label: 'Apps',
  //   path: '/apps'
  // },
  {
    icon: ChannelsIcon,
    label: 'Channels',
    path: '/v3/channels'
  },
  // { type: 'divider' },
  // {
  //   icon: ReportsIcon,
  //   label: 'Reports',
  //   path: '/reports'
  // },
  { type: 'divider' },
  { icon: UserGroupsIcon, label: 'User Groups', path: '/v3/user-groups' },
  { icon: ManageAdminsIcon, label: 'Manage Admins', path: '/v3/admins' },
  { icon: WorkspaceIcon, label: 'Workspace', path: '/v3/workspaces' }
  // { icon: InboxIcon, label: 'Inbox', path: '/inbox' }
]

const Main = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isSearchActive, setIsSearchActive] = useState(false)

  const currentBackground = useMemo(() => {
    if (location.pathname.startsWith('/v3/modules')) {
      return backgroundConfig['/v3/modules']
    }

    return backgroundConfig[location.pathname] || backgroundConfig.default
  }, [location.pathname])

  return (
    <div
      className={styles.MainContainer}
      style={{
        backgroundImage: `url(${currentBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Sidebar
        menuItems={MenuItems}
        brandName='Assist AI'
        currentPath={location.pathname}
        onNavigate={(path) => navigate(path)}
      />
      <div className={styles.PageContainer}>
        <Topbar isSearchActive={isSearchActive} setIsSearchActive={setIsSearchActive} />
        <article className={styles.Content}>
          <Outlet />
        </article>
      </div>
    </div>
  )
}

export default Main
