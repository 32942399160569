import { Box, Flex } from '@happyfoxinc/web-components'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'

import styles from './ConfigModule.module.css'

import ChevronRight from 'Src/assetsv3/icons/chevron-right.svg'
import GreenTickIcon from 'Src/assetsv3/icons/green-tick.svg'
import {
  useGetModuleMetaInfoQuery,
  useGetProvisionStepsQuery,
  useGetRequestApprovalStepQuery,
  useGetSendInstructionStepQuery,
  useGetSoftwaresQuery
} from 'Src/servicesV3/softwareAccessModuleApi'

import AccessControl from '../../Components/AccessControl'
import WorkflowConfig from '../../Components/WorkflowConfig'
import ConfigLoadingScreen from './ConfigLoadingScreen'
import { getAppDescription, getAppIcon, getProviderIcon, INITIAL_STEP } from './ConfigModuleUtils'

const ConfigModule = ({ selectedProvider, currentScreen, onConfigUpdate }) => {
  const [selectedApps, setSelectedApps] = useState([])
  const [appCategories, setAppCategories] = useState([])

  const { data: softwaresData, isLoading: isSoftwaresLoading } = useGetSoftwaresQuery(selectedProvider?.name, {
    skip: !selectedProvider
  })

  const { data: modulesMetaData = {}, isLoading: isMetaLoading } = useGetModuleMetaInfoQuery('software-access')
  const { access_control_fields: accessControlData, create_ticket_configuration: createTicketData } = modulesMetaData
  const { data: provisionSteps = { provision_steps: [] }, isLoading: isProvisionStepsLoading } =
    useGetProvisionStepsQuery()
  const { data: sendInstructionDetails, isLoading: isSendInstructionLoading } = useGetSendInstructionStepQuery()
  const { data: requestApprovalDetails, isLoading: isRequestApprovalLoading } = useGetRequestApprovalStepQuery()

  const isLoading =
    isSoftwaresLoading ||
    isMetaLoading ||
    isProvisionStepsLoading ||
    isSendInstructionLoading ||
    isRequestApprovalLoading

  const selectedApp = useMemo(
    () =>
      selectedProvider
        ? {
            id: selectedProvider.id,
            name: selectedProvider.name.charAt(0).toUpperCase() + selectedProvider.name.slice(1).toLowerCase(),
            icon: getProviderIcon(selectedProvider)
          }
        : null,
    [selectedProvider]
  )

  useEffect(() => {
    if (softwaresData?.apps && accessControlData) {
      setAppCategories((prevCategories) => {
        if (prevCategories.length === 0) {
          const categories = softwaresData.apps.map((app) => ({
            id: app.id,
            icon: app.logo || getAppIcon(app.id),
            name: app.name,
            description: getAppDescription(app.name),
            accessConfig: {
              isFullAccess: false,
              conditions: [],
              configured: false
            },
            workflowConfig: {
              configured: false,
              steps: [INITIAL_STEP.ACCESS_PROVISION],
              stepDetails: {}
            },
            ticketCreation: {
              configured: false,
              allow_ticket_create: false,
              categories: [],
              default_solved_status: [],
              ticket_subject: ''
            }
          }))

          if (categories.length > 0 && selectedApps.length === 0) {
            setSelectedApps([categories[0].id])
          }

          return categories
        }
        return prevCategories
      })
    }
  }, [softwaresData, accessControlData, selectedApps])

  useEffect(() => {
    onConfigUpdate(appCategories)
  }, [appCategories, onConfigUpdate])

  const handleAppSelect = useCallback((appId) => {
    setSelectedApps((prev) => (prev.includes(appId) ? [] : [appId]))
  }, [])

  const handleAccessConfigChange = useCallback((appId, accessConfig) => {
    setAppCategories((prevApps) => {
      if (!prevApps.some((app) => app.id === appId)) return prevApps

      return prevApps.map((app) =>
        app.id === appId
          ? {
              ...app,
              accessConfig: {
                ...app.accessConfig,
                isFullAccess: accessConfig.isFullAccess,
                configured: accessConfig.configured,
                conditions: accessConfig.conditions
              }
            }
          : app
      )
    })
  }, [])

  const handleWorkflowConfigUpdate = useCallback((appId, newSteps) => {
    setAppCategories((prevApps) =>
      prevApps.map((app) => {
        if (app.id === appId) {
          return {
            ...app,
            workflowConfig: {
              steps: newSteps,
              configured: true,
              stepDetails: app.workflowConfig.stepDetails
            }
          }
        }
        return app
      })
    )
  }, [])

  const handleTicketConfigChange = useCallback((appId, ticketConfig) => {
    setAppCategories((prevApps) => {
      if (!prevApps.some((app) => app.id === appId)) return prevApps

      return prevApps.map((app) =>
        app.id === appId
          ? {
              ...app,
              ticket_creation: {
                allow_ticket_create: ticketConfig.allow_ticket_create,
                categories: ticketConfig.categories,
                default_solved_status: ticketConfig.default_solved_status,
                ticket_subject: ticketConfig.ticket_subject
              }
            }
          : app
      )
    })
  }, [])

  const getConfiguredApps = useCallback(() => {
    return appCategories.filter((app) => app.accessConfig.configured || app.workflowConfig.configured)
  }, [appCategories])

  useEffect(() => {
    if (currentScreen === 1) {
      const configuredApps = appCategories.filter((app) => app.accessConfig.configured || app.workflowConfig.configured)

      if (configuredApps.length > 0 && selectedApps.length === 0) {
        setSelectedApps([configuredApps[0].id])
      } else if (configuredApps.length > 0 && !configuredApps.some((app) => app.id === selectedApps[0])) {
        setSelectedApps([configuredApps[0].id])
      }
    }
  }, [currentScreen, appCategories, selectedApps])

  if (!selectedApp) return null

  if (isLoading) {
    return <ConfigLoadingScreen />
  }

  return (
    <Flex gap='24px' height='calc(100vh - 200px)' overflow='hidden'>
      <Box width='50%' overflowY='auto' p='3rem 3rem 3rem 4rem'>
        <Flex direction='column' gap='20px' width='85%' className={styles.sectionHeadContainer}>
          <h2 className={styles.sectionTitle}>Software Access Request Management</h2>
          <p className={styles.sectionDescription}>
            Manage and streamline employee requests for software access, ensuring quick approvals and proper
            authorization.
          </p>
        </Flex>
        <div className={styles.appSelectionHeadingContainer}>
          <h4 className={styles.appSelectionHeading}>Apps</h4>
          <p className={styles.appSelectionDescription}>Select apps you want to configure</p>
        </div>

        <div className={styles.categoryContainer}>
          <div className={styles.categoryHeader}>
            <div className={styles.titleContainer}>
              <img src={selectedApp.icon} alt={`${selectedApp.name} icon`} className={styles.icon} />
              <h3 className={styles.categoryName}>{selectedApp.name}</h3>
            </div>
            <span className={styles.appsCount}>
              {currentScreen === 1 ? `${getConfiguredApps().length} Apps Configured` : `${appCategories.length} Apps`}
            </span>
          </div>

          <Flex direction='column'>
            {(currentScreen === 0 ? appCategories : getConfiguredApps()).map((app) => {
              const showFullAccess = app.accessConfig.configured && app.accessConfig.isFullAccess
              const showConditionalAccess =
                app.accessConfig.configured && !app.accessConfig.isFullAccess && app.accessConfig.conditions.length > 0
              const showArrow = selectedApps.includes(app.id)

              return (
                <div
                  key={app.id}
                  className={`${styles.appCard} ${selectedApps.includes(app.id) ? styles.selected : ''}`}
                  onClick={() => handleAppSelect(app.id)}
                >
                  <div className={styles.appInfo}>
                    <img src={app.icon} alt={`${app.name} icon`} className={styles.icon} />
                    <h3 className={styles.appName}>{app.name}</h3>
                    <p className={styles.appDescription}>{app.description}</p>
                  </div>
                  <div className={styles.appInfo}>
                    {currentScreen === 0 && (
                      <Fragment>
                        {(showFullAccess || showConditionalAccess) && (
                          <Fragment>
                            <span className={styles.cardStatus}>
                              {showFullAccess ? 'Full Access' : 'Conditional Access'}
                            </span>
                            <GreenTickIcon />
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                    {showArrow && <ChevronRight />}
                  </div>
                </div>
              )
            })}
          </Flex>
        </div>
      </Box>

      {selectedApps.map((appId) => {
        const app = appCategories.find((a) => a.id === appId)
        if (!app) return null

        return (
          <div key={appId} className={styles.configurationPanel}>
            <div className={styles.configTitleContainer}>
              <img src={app.icon} alt={`${app.name} icon`} className={styles.configTitleIcon} />
              <h2 className={styles.configTitle}>Who can Request?</h2>
            </div>
            {currentScreen === 0 ? (
              <AccessControl
                showTicketCreation
                app={app}
                onAccessConfigChange={(config) => handleAccessConfigChange(app.id, config)}
                onTicketConfigChange={(config) => handleTicketConfigChange(app.id, config)}
                accessControlData={accessControlData}
                ticketCreationData={createTicketData}
              />
            ) : (
              <WorkflowConfig
                app={app}
                workflowSteps={app.workflowConfig.steps}
                provisionSteps={provisionSteps}
                stepDetails={app.workflowConfig.stepDetails}
                sendInstructionData={sendInstructionDetails}
                requestApprovalData={requestApprovalDetails}
                onWorkflowUpdate={(newSteps) => handleWorkflowConfigUpdate(app.id, newSteps)}
              />
            )}
          </div>
        )
      })}
    </Flex>
  )
}

export default ConfigModule
