import { Box, Button, Flex, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { useMemo } from 'react'
import { useTable } from 'react-table'

import styles from './AIAssistant.module.css'

import PlusIcon from 'Src/assetsv3/icons/plus.svg'

const AIAssistantsMockData = [
  {
    name: 'Onboarding Assistant',
    description: 'Automate the onboarding process by providing new hires with step-by-step guidance...',
    configuredBy: 'Dontae Little',
    updatedOn: 'July 3 2024'
  },
  {
    name: 'Offboarding Assistant',
    description: 'Streamline the offboarding process with AI assistance to ensure a smooth transition...',
    configuredBy: 'Jushawn McDowell',
    updatedOn: 'July 3 2024'
  },
  {
    name: 'Employee Benefits Inquiries',
    description: 'AI can provide quick answers to frequently asked questions about health insurance...',
    configuredBy: 'Jushawn McDowell',
    updatedOn: 'July 3 2024'
  },
  {
    name: 'Leave Management',
    description: 'Assist employees with requests for time off, vacation balances, and leave policies...',
    configuredBy: 'Dontae Little',
    updatedOn: 'July 3 2024'
  },
  {
    name: 'Performance Review Support',
    description: 'Provide employees and managers with information on the performance review process...',
    configuredBy: 'Dontae Little',
    updatedOn: 'July 3 2024'
  },
  {
    name: 'HR Policy Guidance',
    description: 'Offer employees instant access to company policies and procedures. AI can answer...',
    configuredBy: 'Dontae Little',
    updatedOn: 'July 3 2024'
  },
  {
    name: 'Employee Data Update Requests',
    description: 'AI can assist employees in updating their personal information, such as address changes..',
    configuredBy: 'Jushawn McDowell',
    updatedOn: 'July 3 2024'
  },
  {
    name: 'Training and Development',
    description: 'Help employees navigate available training programs and development opportunities...',
    configuredBy: 'Dontae Little',
    updatedOn: 'July 3 2024'
  }
]

const AIAssistantList = () => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Configured',
        accessor: 'configuredBy'
      },
      {
        Header: 'Updated On',
        accessor: 'updatedOn'
      }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: AIAssistantsMockData
  })

  return (
    <Table.Root {...getTableProps()}>
      <Table.Header>
        {headerGroups.map((headerGroup) => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
          return (
            <Table.Row key={key} {...restHeaderGroupProps}>
              {headerGroup.headers.map((column) => {
                const { key, ...restColumnProps } = column.getHeaderProps()
                return (
                  <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                    {column.render('Header')}
                  </Table.ColumnHeaderCell>
                )
              })}
              <Table.ColumnHeaderCell isOption />
            </Table.Row>
          )
        })}
      </Table.Header>

      <Table.Body {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          const { key, ...restRowProps } = row.getRowProps()
          return (
            <Table.Row key={key} {...restRowProps}>
              {row.cells.map((cell) => {
                const { key, ...restCellProps } = cell.getCellProps()
                return (
                  <Table.Cell key={key} {...restCellProps}>
                    {cell.render('Cell')}
                  </Table.Cell>
                )
              })}
              <TableOptions>
                <TableOption>Edit</TableOption>
              </TableOptions>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table.Root>
  )
}

const AIAssistant = () => {
  return (
    <Box>
      <Flex align='center' gap='12px'>
        <h1 className={styles.heading}>AI Assistant</h1>
        <Button variant='solid' size='small' radius='full' className={styles.addButton}>
          <PlusIcon />
        </Button>
      </Flex>
      <div className={styles.listContainer}>
        <AIAssistantList />
      </div>
    </Box>
  )
}

export default AIAssistant
