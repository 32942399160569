import { useEffect } from 'react'
import { unstable_HistoryRouter as HistoryRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { RedirectConfirmationModal } from 'Components/ConfirmationModal/RedirectConfirmationContext'

import history from './history'
import { configureReactModal } from './Modal'
import Protected from './pages/protected'
import Login from './pages/public/Login'
import Pages from './pagesv3'
import assistAI, { assistAIv3 } from './react-ui'

const App = () => {
  useEffect(() => {
    /*
      NOTE: delete the previously used CSRF cookie as it is no longer valid
      This logic can be removed after sometime
    */
    const isCsrfCookiePresent = document.cookie.split(';').some((item) => item.trim().startsWith('csrftoken='))
    if (isCsrfCookiePresent) {
      document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }
  }, [])

  useEffect(() => {
    configureReactModal()
  }, [])

  useEffect(() => {
    document.body.classList.add(assistAI)
  }, [])

  const ThemeWrapper = ({ children }) => {
    const location = useLocation()

    useEffect(() => {
      const isV3Route = location.pathname.startsWith('/v3')

      // Remove both themes first
      document.body.classList.remove(assistAI)
      document.body.classList.remove(assistAIv3)

      // Apply appropriate theme based on route
      if (isV3Route) {
        document.body.classList.add(assistAIv3)
      } else {
        document.body.classList.add(assistAI)
      }
    }, [location.pathname])

    return children
  }

  return (
    <HistoryRouter history={history} basename='app'>
      <ThemeWrapper>
        <div id='__page-root'>
          <RedirectConfirmationModal>
            <Routes>
              <Route path='login' element={<Login />} />
              <Route path='/*' element={<Protected />} />
              <Route path='v3/*' element={<Pages />} />
              <Route path='*' element={<Navigate to='/login' replace />} />
            </Routes>
          </RedirectConfirmationModal>
        </div>
        <div id='__modal-root' />
      </ThemeWrapper>
    </HistoryRouter>
  )
}

export default App
