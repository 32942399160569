import { Box } from '@happyfoxinc/web-components'
import { Fragment, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { AUTOMATIC, COMMON_TEXT } from 'Constants/channels'
import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { useGetHelpdeskAppQuery } from 'Src/servicesV3/helpdeskAppApi'
import { useGetServicedeskAppQuery } from 'Src/servicesV3/servicedeskAppApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

const commonFieldAttributes = {
  allowedCategoriesOrTeams: {
    isMulti: true,
    isSearchable: false,
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id,
    styles: {
      control: (styles) => ({
        ...styles,
        minHeight: '5rem',
        alignItems: 'flex-start'
      })
    },
    components: {
      DropdownIndicator: null
    },
    isClearable: false
  },
  defaultCategoryOrTeam: {
    isSearchable: false,
    getOptionLabel: (option) => option.name,
    getOptionValue: (option) => option.id
  }
}

const HelpDeskFields = () => {
  const {
    formState: { errors },
    control,
    watch,
    setValue
  } = useFormContext()

  const { currentWorkspaceId } = useWorkspace()

  const showModeSelection = watch('syncToChannelThread')
  const selectedAllowedCategories = watch('allowedCategories')
  const selectedMode = watch('mode')

  const isManualModeSelected = selectedMode && selectedMode !== AUTOMATIC
  const isAutomaticModeSelected = selectedMode === AUTOMATIC

  const showAllowedCategorySelection = showModeSelection && isManualModeSelected
  const showDefaultCategorySelectionForAutomaticMode = showModeSelection && isAutomaticModeSelected
  const showDefaultCategorySelectionForManualMode =
    showAllowedCategorySelection && selectedAllowedCategories?.length > 0

  useEffect(() => {
    const subscription = watch((formValue, { name, type }) => {
      const { defaultCategoryForManualMode, allowedCategories = [] } = formValue

      if (name === 'allowedCategories' && type === 'change') {
        const shouldResetDefaultCategoryForManualMode =
          defaultCategoryForManualMode && !allowedCategories.some(({ id }) => id === defaultCategoryForManualMode.id)

        if (shouldResetDefaultCategoryForManualMode) {
          setValue('defaultCategoryForManualMode', null)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue])

  const { data: helpdeskData = {}, isLoading: isHelpdeskDataLoading } = useGetHelpdeskAppQuery({
    workspace_id: currentWorkspaceId
  })

  const allCategoryOptions = useMemo(() => {
    const { all_categories = [] } = helpdeskData
    return all_categories
  }, [helpdeskData])

  const isSelectedDefaultCategoryForManualModePrivate = watch('defaultCategoryForManualMode')?.public === false

  return (
    <Fragment>
      {showAllowedCategorySelection && (
        <FormField>
          <FormField.Field label={COMMON_TEXT.ALLOWED_CATEGORIES} error={errors?.allowedCategories?.message}>
            <Controller
              name='allowedCategories'
              control={control}
              render={({ field }) => (
                <Box width='330px'>
                  <ReactSelect
                    {...field}
                    isLoading={isHelpdeskDataLoading}
                    options={allCategoryOptions}
                    {...commonFieldAttributes.allowedCategoriesOrTeams}
                  />
                </Box>
              )}
            />
          </FormField.Field>
        </FormField>
      )}

      {showDefaultCategorySelectionForAutomaticMode && (
        <FormField>
          <FormField.Field
            label={COMMON_TEXT.DEFAULT_CATEGORY_FOR_AUTO_MODE}
            error={errors?.defaultCategoryForAutoMode?.message}
          >
            <Controller
              name='defaultCategoryForAutoMode'
              control={control}
              render={({ field }) => (
                <Box width='330px'>
                  <ReactSelect
                    {...field}
                    isLoading={isHelpdeskDataLoading}
                    options={allCategoryOptions}
                    {...commonFieldAttributes.defaultCategoryOrTeam}
                  />
                </Box>
              )}
            />
          </FormField.Field>
        </FormField>
      )}

      {showDefaultCategorySelectionForManualMode && (
        <FormField>
          <FormField.Field
            label={COMMON_TEXT.DEFAULT_CATEGORY_FOR_MANUAL_MODE}
            error={
              errors?.defaultCategoryForManualMode?.message ||
              (isSelectedDefaultCategoryForManualModePrivate ? COMMON_TEXT.SELECTED_CATEGORY_IS_PRIVATE : undefined)
            }
          >
            <Controller
              name='defaultCategoryForManualMode'
              control={control}
              render={({ field }) => (
                <Box width='330px'>
                  <ReactSelect
                    {...field}
                    isLoading={isHelpdeskDataLoading}
                    options={allCategoryOptions}
                    {...commonFieldAttributes.defaultCategoryOrTeam}
                  />
                </Box>
              )}
            />
          </FormField.Field>
        </FormField>
      )}
    </Fragment>
  )
}

const ServiceDeskFields = () => {
  const {
    formState: { errors },
    control,
    watch,
    setValue
  } = useFormContext()

  const { currentWorkspaceId } = useWorkspace()

  const showModeSelection = watch('syncToChannelThread')
  const selectedAllowedTeams = watch('allowedTeams')
  const selectedMode = watch('mode')

  const isManualModeSelected = selectedMode && selectedMode !== AUTOMATIC
  const isAutomaticModeSelected = selectedMode === AUTOMATIC

  const showAllowedTeamSelection = showModeSelection && isManualModeSelected
  const showDefaultTeamSelectionForAutomaticMode = showModeSelection && isAutomaticModeSelected
  const showDefaultTeamSelectionForManualMode = showAllowedTeamSelection && selectedAllowedTeams?.length > 0

  useEffect(() => {
    const subscription = watch((formValue, { name, type }) => {
      const { defaultTeamForManualMode, allowedTeams = [] } = formValue

      if (name === 'allowedTeams' && type === 'change') {
        const shouldResetDefaultTeamForManualMode =
          defaultTeamForManualMode && !allowedTeams.some(({ id }) => id === defaultTeamForManualMode.id)

        if (shouldResetDefaultTeamForManualMode) {
          setValue('defaultTeamForManualMode', null)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue])

  const { data: servicedeskData = {}, isLoading: isServicedeskDataLoading } = useGetServicedeskAppQuery({
    workspace_id: currentWorkspaceId
  })

  const allTeamOptions = useMemo(() => {
    const { all_teams = [] } = servicedeskData
    return all_teams
  }, [servicedeskData])

  const isSelectedDefaultTeamForManualModePrivate = watch('defaultTeamForManualMode')?.public === false

  return (
    <Fragment>
      {showAllowedTeamSelection && (
        <FormField>
          <FormField.Field label={COMMON_TEXT.ALLOWED_TEAMS} error={errors?.allowedTeams?.message}>
            <Controller
              name='allowedTeams'
              control={control}
              render={({ field }) => (
                <Box width='330px'>
                  <ReactSelect
                    {...field}
                    isLoading={isServicedeskDataLoading}
                    options={allTeamOptions}
                    {...commonFieldAttributes.allowedCategoriesOrTeams}
                  />
                </Box>
              )}
            />
          </FormField.Field>
        </FormField>
      )}

      {showDefaultTeamSelectionForAutomaticMode && (
        <FormField>
          <FormField.Field
            label={COMMON_TEXT.DEFAULT_TEAM_FOR_AUTO_MODE}
            error={errors?.defaultTeamForAutoMode?.message}
          >
            <Controller
              name='defaultTeamForAutoMode'
              control={control}
              render={({ field }) => (
                <Box width='330px'>
                  <ReactSelect
                    {...field}
                    isLoading={isServicedeskDataLoading}
                    options={allTeamOptions}
                    {...commonFieldAttributes.defaultCategoryOrTeam}
                  />
                </Box>
              )}
            />
          </FormField.Field>
        </FormField>
      )}

      {showDefaultTeamSelectionForManualMode && (
        <FormField>
          <FormField.Field
            label={COMMON_TEXT.DEFAULT_TEAM_FOR_MANUAL_MODE}
            error={
              errors?.defaultTeamForManualMode?.message ||
              (isSelectedDefaultTeamForManualModePrivate ? COMMON_TEXT.SELECTED_TEAM_IS_PRIVATE : undefined)
            }
          >
            <Controller
              name='defaultTeamForManualMode'
              control={control}
              render={({ field }) => (
                <Box width='330px'>
                  <ReactSelect
                    {...field}
                    isClearable
                    options={allTeamOptions}
                    {...commonFieldAttributes.defaultCategoryOrTeam}
                  />
                </Box>
              )}
            />
          </FormField.Field>
        </FormField>
      )}
    </Fragment>
  )
}

export { HelpDeskFields, ServiceDeskFields }
