
export const QUESTION_TYPES = [
  {
    label: 'Multi Choice',
    key: 'multiple_choice'
  },
  {
    label: 'Single Choice',
    key: 'single_choice'
  },
  {
    label: 'Open Ended',
    key: 'open_ended'
  }
]

export const getQuestionTypeLabel = (type) => {
  switch(type) {
    case 'multiple_choice':
      return 'Multi Choice';
    case 'single_choice':
      return 'Single Choice';
    case 'open_ended':
      return 'Open Ended';
    default:
      return '';
  }
}