import { Box, Button, Flex, Modal, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import dayjs from 'dayjs'
import { Fragment, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'

import styles from './Answers.module.css'

import PlusIcon from 'Src/assetsv3/icons/plus.svg'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useDeleteAnswerMutation, useGetAnswersQuery } from 'Src/servicesV3/answersApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

const DeleteModal = (props) => {
  const { id, data, ...restProps } = props
  const [deleteAnswer, deleteAnswerApiResults] = useDeleteAnswerMutation()

  const handleDelete = useCallback(() => {
    const promise = deleteAnswer(id).unwrap()
    toast.promise(promise, {
      loading: 'Deleting answer',
      success: `Deleted answer '${data.name}'. Successfully...`,
      error: `Error while deleting answer '${data.name}'. Try again.`
    })
    props.onClose()
  }, [id, data, deleteAnswer, props])

  return (
    <Modal size='small' {...restProps}>
      <Flex direction='column' gap='12px' align='center'>
        Are you sure to delete this answer?
        <Flex gap='15px'>
          <Button variant='solid' disabled={deleteAnswerApiResults.isLoading} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='outline' disabled={deleteAnswerApiResults.isLoading} onClick={props.onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

const AnswersList = () => {
  const { currentWorkspaceId } = useWorkspace()
  const navigate = useNavigate()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState(null)

  const { data: answersApiResponse = {}, isLoading: answerApiLoading } = useGetAnswersQuery({
    workspace_id: currentWorkspaceId
  })
  const { results: answersList = [] } = answersApiResponse

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format('MMM D, YYYY h:mm A')
        }
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
        Cell: ({ cell: { value } }) => {
          return value.name
        }
      },
      {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format('MMM D, YYYY h:mm A')
        }
      }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: answersList
  })

  const handleDeleteClick = (answer) => {
    setSelectedAnswer(answer)
    setIsDeleteModalOpen(true)
  }

  if (answerApiLoading) {
    return <TableLoader rows={5} columns={4} showOptions />
  }

  return (
    <Fragment>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...restRowProps } = row.getRowProps()
            const answer = row.original
            return (
              <Table.Row key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps()
                  return (
                    <Table.Cell key={key} {...restCellProps}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  )
                })}
                <TableOptions>
                  <TableOption onClick={() => navigate(`${row.original.id}`)}>Edit</TableOption>
                  <TableOption onClick={() => handleDeleteClick(answer)}>Delete</TableOption>
                </TableOptions>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false)
          setSelectedAnswer(null)
        }}
        id={selectedAnswer?.id}
        data={selectedAnswer}
        title='Delete Answer'
        showFooter={false}
        bodyClassName={styles.modalBody}
      />
    </Fragment>
  )
}

const Answers = () => {
  const navigate = useNavigate()

  return (
    <Box>
      <Flex align='center' gap='12px'>
        <h1 className={styles.heading}>Answers</h1>
        <Button
          variant='solid'
          size='small'
          radius='full'
          className={styles.addButton}
          onClick={() => navigate('create')}
        >
          <PlusIcon />
        </Button>
      </Flex>
      <div className={styles.listContainer}>
        <AnswersList />
      </div>
    </Box>
  )
}

export default Answers
